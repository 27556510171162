import { defineStore } from 'pinia'
import { CustomerModel } from '@simploshop-models/customer.model'
import { MeModel } from '@simploshop-models/me.model'
import type { UpdateCustomerPayload } from '@simploshop-services/Customers.service'

export const useAuthStore = defineStore('auth', () => {
    const appConfig = useAppConfig()
    const nuxtApp = useNuxtApp()
    const { notifyError } = useNotifications()
    const _meModel = ref<InstanceType<typeof MeModel> | null | undefined>(undefined)

    // public properties
    const isLoggedIn = computed(() => !!(_meModel.value?.customer))
    const customer = computed(() => _meModel.value?.customer ?? null)

    /**
     * Set the customer or me model.
     * If the visitor is a guest (not logged-in), the customer will be set to `null`
     *
     * @param val the `MeModel` or `CustomerModel` to set
     */
    function setCustomer(val: MeModel | CustomerModel | null) {
        // if a MeModel is passed, set it directly
        if (val instanceof MeModel || val === null) {
            _meModel.value = val
            return
        }

        // if a CustomerModel is passed, update it in the existing MeModel
        if (!_meModel.value) return
        _meModel.value.customer = val
    }

    function removeCustomer() {
        setCustomer(null)
    }

    const customerDataToUpdate = ref<UpdateCustomerPayload>({})

    const { error, execute } = useCustomersApiService()
        .embed([
            // @ts-ignore - TODO: fix app config type
            ...(appConfig.embeds?.customer ?? []),
        ])
        .forId(() => customer.value?.id)
        .usePatch(customerDataToUpdate, {
            immediate: false,
            watch: false,
            onResponse: (response) => {
                const customer = response.getItem()
                if (!customer) {
                    notifyError(nuxtApp.$i18n.t('_core_theme.errors.customer_update_error'))
                    return
                }
                setCustomer(customer)
            },
        })

    async function updateCustomer(data: UpdateCustomerPayload) {
        if (!isLoggedIn.value) return

        customerDataToUpdate.value = data

        await execute()

        customerDataToUpdate.value = {}

        if (error.value) {
            throw error.value
        }
    }

    return {
        _meModel, // Needs to be returned to be sent in the payload
        customer,
        isLoggedIn,
        updateCustomer,
        _setCustomer: setCustomer,
        removeCustomer,
    }
})
