import type { FormattedCurrency, Monetary } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { FormattedCurrencyModel } from './custom/formatted-currency.model'
import { OrderModel } from './order.model'
import { CartShippingMethodModel } from './cart-shipping-method.model'

interface Attributes {
    [OrderShippingMethodModel.ATTR_ID]: number
    [OrderShippingMethodModel.ATTR_NAME]: string | null
    [OrderShippingMethodModel.ATTR_PRICE]: FormattedCurrency
    [OrderShippingMethodModel.ATTR_TAXED_PRICE]: FormattedCurrency
    [OrderShippingMethodModel.ATTR_SHIPPING_METHOD_ID]: number
    [OrderShippingMethodModel.ATTR_ORDER_ID]: number
    [OrderShippingMethodModel.ATTR_SHIPPING_OPTION_ID]: string | null
    [OrderShippingMethodModel.ATTR_SHIPPING_OPTION_NAME]: string | null
    [OrderShippingMethodModel.ATTR_SHIPPING_SERVICE]: number
    [OrderShippingMethodModel.ATTR_TRACKING_NUMBERS]: string[]
    [OrderShippingMethodModel.ATTR_TAX_RATE]: Monetary
    [OrderShippingMethodModel.ATTR_ORIGINAL_PRICE]: FormattedCurrency
    [OrderShippingMethodModel.ATTR_ORIGINAL_TAXED_PRICE]: FormattedCurrency
}

interface Embeds {
    [OrderShippingMethodModel.EMBED_ORDER]: OrderModel
    [OrderShippingMethodModel.EMBED_SHIPPING_METHOD]: CartShippingMethodModel
}

export class OrderShippingMethodModel extends ApiModel<Attributes, Embeds> {
    static key = 'OrderShippingMethodModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_SHIPPING_METHOD_ID = 'shipping_method_id'
    static readonly ATTR_ORDER_ID = 'order_id'
    static readonly ATTR_SHIPPING_OPTION_ID = 'shipping_option_id'
    static readonly ATTR_SHIPPING_OPTION_NAME = 'shipping_option_name'
    static readonly ATTR_SHIPPING_SERVICE = 'shipping_service'
    static readonly ATTR_TRACKING_NUMBERS = 'tracking_numbers'
    static readonly ATTR_TAX_RATE = 'tax_rate'
    static readonly ATTR_ORIGINAL_PRICE = 'original_price'
    static readonly ATTR_ORIGINAL_TAXED_PRICE = 'original_taxed_price'

    static readonly EMBED_ORDER = 'order'
    static readonly EMBED_SHIPPING_METHOD = 'shipping_method'

    get id() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_NAME)
    }

    get price() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_PRICE, FormattedCurrencyModel)
    }

    get taxedPrice() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_TAXED_PRICE, FormattedCurrencyModel)
    }

    get shippingMethodId() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_SHIPPING_METHOD_ID)
    }

    get orderId() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_ORDER_ID)
    }

    get shippingOptionId() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_SHIPPING_OPTION_ID)
    }

    get shippingOptionName() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_SHIPPING_OPTION_NAME)
    }

    get shippingService() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_SHIPPING_SERVICE)
    }

    get trackingNumbers() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_TRACKING_NUMBERS)
    }

    get taxRate() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_TAX_RATE)
    }

    get originalPrice() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_ORIGINAL_PRICE, FormattedCurrencyModel)
    }

    get originalTaxedPrice() {
        return this._getAttribute(OrderShippingMethodModel.ATTR_ORIGINAL_TAXED_PRICE, FormattedCurrencyModel)
    }

    get order() {
        return this._getEmbed(OrderShippingMethodModel.EMBED_ORDER, OrderModel)
    }

    get shippingMethod() {
        return this._getEmbed(OrderShippingMethodModel.EMBED_SHIPPING_METHOD, CartShippingMethodModel)
    }
}
