import validate from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/simploshop/shared/core-theme/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  cart: () => import("/opt/simploshop/shared/core-theme/app/middleware/cart.ts")
}