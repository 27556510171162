import { defineStore } from 'pinia'
import type { PhaseManagerRequirements } from '@core/types/general'
import type { PhaseManagerCartPhase } from '@core-theme/types/cart'
import { CartModel } from '@simploshop-models/cart.model'
import { CartItemModel } from '@simploshop-models/cart-item.model'
import { AddressType } from '@sim-api-enums/address'
import { useEvents } from '@core/app/composables/events'
import { ApiResponseError } from '@composable-api/api.response-error'
import type { UpdateCartAttributes } from '@simploshop-services/Carts.service'
import { PaymentServiceType } from '@sim-api-enums/payment'
import type { MaybePromise } from 'rollup'
import type { Ref } from 'vue'
import { useCartItemsApiService } from '@simploshop-services/CartItems.service'
import { CART_EMBEDS, CART_ITEMS_EMBEDS, ORDER_EMBEDS } from '../assets/ts/constants/cart'
import { getCsobPaymentApiService } from '@simploshop-services/CsobPayment.service'
import { joinURL } from 'ufo'
import {
    CART_PAYMENT_RETURN_QUERY_ORDER_ID,
    PaymentService
} from '../../shared/constants'

interface SubmitOrderOptions {
    /**
     * A callback function which will be called after the order is created.
     * This is only the case when the customer is NOT to be redirected to the payment provider.
     * In that case, this function is never called.
     */
    onSuccess: () => MaybePromise<any>
}

interface FetchCartOptions {
    _setOnNuxtReady: boolean
    _isOnCartRoute: boolean
    transferItemsSourceCartId: string
}

interface FastCheckoutOptions {
    onSuccess: () => MaybePromise<any>
}

export const useCartStore = defineStore('cart', () => {
    const appConfig = useAppConfig()
    const runtimeConfig = useRuntimeConfig()

    const { $i18n } = useNuxtApp()
    const events = useEvents()
    const propertiesStore = usePropertiesStore()
    const authStore = useAuthStore()
    const { getLocalizedValue } = useLocalization()

    const goPayClient = useClientGoPay()

    const { notifyError, notifyInfo, notifySuccess } = useNotifications()

    // CART
    const cart = ref<InstanceType<typeof CartModel> | null>(null)
    const _isCartLoading = ref<boolean>(false)
    const cartPromise = ref<Promise<InstanceType<typeof CartModel> | null> | null>(null)

    /**
     * The order used for the success page.
     */
    const latestOrder: Ref<InstanceType<typeof OrderModel> | null> = ref(null)

    const cartItems: Ref<InstanceType<typeof CartItemModel>[] | null> = ref(null)
    const areItemsLoading = computed<boolean>(() => !cartItems.value && areItemsRefreshing.value)
    const isDifferentShippingAddressAllowed = computed<boolean>(() => cart.value?.shippingMethod?.allowsDifferentBillingAddress() ?? false)
    const areItemsRefreshing = ref<boolean>(false)
    const _isShippingMethodLoading = ref<boolean>(false)
    const isShippingMethodLoading = computed<boolean>(() => _isShippingMethodLoading.value || isCartLoading.value)
    const _isPaymentMethodLoading = ref<boolean>(false)
    const isPaymentMethodLoading = computed<boolean>(() => _isPaymentMethodLoading.value || isCartLoading.value)
    const isShippingCountryUpdating = ref<boolean>(false)

    const hasShippingAddress = ref<boolean>(false)
    const forcedAddressCountries = computed(() => ({
        billing: cart.value?.shippingCountryId && !hasShippingAddress.value && isDifferentShippingAddressAllowed.value ? cart.value?.shippingCountryId : null,
        shipping: cart.value?.shippingCountryId && hasShippingAddress.value && isDifferentShippingAddressAllowed.value ? cart.value?.shippingCountryId : null,
    }))

    watch(cart, (newCart) => {
        hasShippingAddress.value = newCart?.hasShippingAddress ?? false
    }, { immediate: true })

    const orderFormFieldErrors = ref<Partial<{
        note: string | null
        password: string | null
        password_confirmation: string | null
    }>>({})

    const orderData = reactive<{
        note: string
        gender_oriented_product_preference: GenderTypeEnum | null
        create_new_account: boolean
        password: string
        password_confirmation: string
        newsletter: boolean
    }>({
        note: '',
        gender_oriented_product_preference: null,
        create_new_account: false,
        password: '',
        password_confirmation: '',
        newsletter: false,
    })
    function resetOrderData() {
        orderData.note = ''
        orderData.gender_oriented_product_preference = null
        orderData.create_new_account = false
        orderData.password = ''
        orderData.password_confirmation = ''
        orderData.newsletter = false
    }

    const localePath = useLocalePath()
    // @ts-ignore AppConfig type in theme is not correct TODO: fix
    const phaseManager = usePhaseManager(() => (appConfig.cart?.phases as PhaseManagerCartPhase[] ?? [])
        .filter(p => typeof p.isPresent === 'undefined' || p.isPresent({
            me: (authStore._meModel ?? null) as InstanceType<typeof MeModel> | null,
        })),   {
        // @ts-ignore AppConfig type in theme is not correct TODO: fix
        requirements: () => appConfig.cart?.getPhaseRequirements?.({ cart: cart.value, latestOrder: latestOrder.value }) as PhaseManagerRequirements<PhaseManagerCartPhase> ?? {
            [CartPhase.SHIPPING_AND_PAYMENT]: [
                !!cart.value?.hasItems(),
            ],
            [CartPhase.DELIVERY]: [
                !!cart.value?.hasItems(),
                !!cart.value?.shippingMethod,
                !!cart.value?.paymentMethod,
                // TODO: re-enable
                // !!cart.value?.shippingCountryId,
            ],
            [CartPhase.SUMMARY]: [
                !!latestOrder.value,
            ],
        },
        onPhaseChange: (newPhase, oldPhase, additionalDetails) => {
            // clear latest order when navigating away from the summary page
            if (oldPhase?.id === CartPhase.SUMMARY) {
                latestOrder.value = null
            }

            if (additionalDetails.wasForced) {
                // TODO: refactor later
                // if no parent route has the cart middleware, we're not in the cart, do not navigate
                if (!useRoute().matched.some(route => route.meta.middleware === 'cart')) return

                navigateTo(localePath({ name: newPhase.routeName }))
            }
        },
    }) as ReturnType<typeof usePhaseManager<PhaseManagerCartPhase>>

    const currentPhase = phaseManager.phase
    const isLastCartPhase = computed(() => currentPhase.value?.id === CartPhase.DELIVERY)

    function isPhaseSwitchingDisabled(newPhase: PhaseManagerCartPhase | null | undefined) {
        return !phaseManager.canSwitchTo(newPhase)
        || isCartLoading.value
        || isPaymentMethodLoading.value
        || isShippingMethodLoading.value
    }

    const cartsApiService = useCartsApiService()
    const { refresh: _refreshCart, error: cartError, status: cartStatus } = cartsApiService
        .embed([
            ...CART_EMBEDS,
            // @ts-ignore - TODO: fix app config type
            ...(appConfig.embeds?.cart ?? []),
        ])
        .forId(() => cart.value?.id)
        .useGet({
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cart.value = response.getItem()
            },
        })
    const isCartLoading = computed<boolean>(() => _isCartLoading.value || cartStatus.value === 'pending')

    // TODO: temporary variable to only refresh cart items when all items have been deleted (replace with reactive
    // useFetch refresher like the one above when api service is refactored & support reactive endpoints)
    let deletionPendingForItemCount = 0

    /**
     * A function that will fetch and set the cart.
     *
     * In case of a logged-in user, it will try to fetch the cart by the known cart ID.
     * If there is no known cart ID for the customer (not logged-in or doesn't have a cart),
     * it will try to use the cart ID from the cookie.
     * If there is no known cart ID, it will create a new cart.
     *
     * Error cases are automatically handled (e.g. when the cart is expired).
     *
     * In case the `cartId` parameter is set to `false`, a new cart will be created
     * regardless of the state of the auth store or cookies. This is useful when we
     * want to create a new cart when the user logs out.
     *
     * !! IMPORTANT !!
     * This function should never be used in a try-catch block, because it is
     * throws an error to display in a nuxt error page and that would prevent it from
     * being able to do so.
     *
     * @param cartId the cart id to use as the primary known cart id OR, if set to `false`,
     *              the function will be forced to create a new cart
     * @param options additional options for the function
     */
    async function fetchCart(cartId: string | false | null | undefined = undefined, options?: Partial<FetchCartOptions>) {
        const cartIdCookie = useCartIdCookie()

        // ------------------- KNOWN CART ID --------------------
        const knownCartId = typeof cartId === 'string' ? cartId : (authStore.isLoggedIn ? authStore._meModel?.latestCartId : cartIdCookie.value)

        let shouldCreateNewCart = cartId === false || !knownCartId
        let _localCart: InstanceType<typeof CartModel> | null = null

        _isCartLoading.value = true

        // ======================= FETCH THE CART =======================
        let resolveFn: (value: InstanceType<typeof CartModel> | null) => void = () => {}

        cartPromise.value = new Promise(resolve => {
            resolveFn = resolve
        })

        if (knownCartId && !shouldCreateNewCart) {
            try {
                const response = await cartsApiService
                    .embed([
                        ...CART_EMBEDS,
                        // @ts-ignore - TODO: fix app config type
                        ...(appConfig.embeds?.cart ?? []),
                    ])
                    .exceptAttrs('_links' as any)
                    .forId(knownCartId)
                    .get()

                _localCart = response.getItem()
            } catch (e) {
                let skipErrorLog = false

                // EXPIRED CART
                if (e instanceof ApiResponseError) {

                    if (e.isStatus(403)) {
                        // owner of the cart is different

                        // remove cookie
                        cartIdCookie.value = null
                        shouldCreateNewCart = true
                        skipErrorLog = true
                    } else if (e.isStatus(404)) {
                        // TODO: find out when this happens
                        // cart not found
                        cartIdCookie.value = null
                        shouldCreateNewCart = true
                        skipErrorLog = true
                    }
                }

                if (!skipErrorLog) console.error('[ERROR] cart.ts:', e)
            }
        }

        // ---------------------- GUEST CUSTOMER ----------------------
        if (shouldCreateNewCart) {
            try {
                const response = await cartsApiService
                    .embed([
                        ...CART_EMBEDS,
                        // @ts-ignore - TODO: fix app config type
                        ...(appConfig.embeds?.cart ?? []),
                    ])
                    .post()
                _localCart = response.getItem()
            } catch (e) {
                // TODO
                console.error('[ERROR] cart.ts:', e)
            }
        }

        // do not serialize the promise if we're on the server to prevent NON-POJO serialization error
        if (import.meta.server) {
            cartPromise.value = null
        }

        // ======================= SET THE CART =======================

        _isCartLoading.value = false

        if (!_localCart) {
            console.error('[ERROR] cart.ts: Cart could not be fetched.')
            // TODO: add retry logic to error.vue
            throw createError({
                statusCode: 500,
                message: $i18n.t('_core_theme.errors.general'),
                fatal: true,
            })
        }

        let _localItems: InstanceType<typeof CartItemModel>[] | null = null

        async function refreshCartItems() {
            if (!_localCart?.id) {
                console.error('[fetchCart/refreshCartItems]: Cart ID is not set')
                return
            }

            try {
                const response = await getCartItemsApiService({ cartId: _localCart.id })
                    .embed([
                        ...CART_ITEMS_EMBEDS,
                    ])
                    .get()

                _localItems = response.getItems()
            } catch (e) {
                console.error('[fetchCart/refreshCartItems]: Could not refresh cart items', e)
            }
        }

        // Transfer items from an old cart if needed
        if (options?.transferItemsSourceCartId) {
            const cartAfterTransfer = await transferItemsFromCart(options.transferItemsSourceCartId, _localCart.id) ?? null
            if (cartAfterTransfer) {
                _localCart = cartAfterTransfer

                // refresh the cart items if we previously had items loaded
                if (cartItems.value) {
                    await refreshCartItems()
                }
            }
        }

        // make sure to fetch cart items when we are on a cart route
        // and the new cart is not empty, and we haven't already fetched the items
        if (_localCart?.hasItems() && !cartItems.value && options?._isOnCartRoute) {
            await refreshCartItems()
        }

        function setCart() {
            if (!_localCart) return

            cart.value = _localCart

            resetOrderData()

            cartIdCookie.value = _localCart.id
            cartItems.value = _localItems

            // connect to the cart socket
            // (when fetching the cart on the client side & when the cart changes)
            refreshListeningToCartItemSoldOut()
            refreshListeningToCartItemStockDecreased()

            resolveFn(_localCart)
        }

        if (options?._setOnNuxtReady) {
            onNuxtReady(() => setCart())
        } else {
            setCart()
        }

    } // end of fetchCart

    /**
     * Fetches the cart if it is not already loaded and
     * automatically transfers the items from the old cart to the new one.
     * (when a temporary cart transfer cookie is present)
     * @param options
     */
    async function fetchCartIfNotLoaded(options?: Partial<Omit<FetchCartOptions, 'transferItemsSourceCartId'>>) {
        if (cart.value) {
            // connect to the cart socket on the client side, when the cart was fetched on the server
            listenToCartItemSoldOut()
            listenToCartItemStockDecreased()
            return
        }

        // get the cart transfer source id from the cookie
        const cartTransferSourceIdCookie = useCartTransferSourceIdCookie()
        const cartIdToFetch = cartTransferSourceIdCookie.value ?? undefined
        // clear the cart transfer source id cookie
        cartTransferSourceIdCookie.value = null

        // TODO: refactor later
        await fetchCart(undefined, {
            ...options,
            transferItemsSourceCartId: cartIdToFetch,
        })
    }

    /**
     * A function that transfers items from a source cart to the current cart.
     * @param sourceCartId the cart ID to transfer the items from (OLD CART)
     * @param targetCartId the cart ID to transfer the items to (NEW CART)
     */
    async function transferItemsFromCart(sourceCartId: string | null | undefined, targetCartId: string | null | undefined) {
        if (!sourceCartId || !targetCartId) return
        try {
            const response = await getCartItemsTransferApiService({ targetCartId: targetCartId })
                .embed([
                    ...CART_EMBEDS,
                    // @ts-ignore - TODO: fix app config type
                    ...(appConfig.embeds?.cart ?? []),
                ])
                .post({ source_cart_id: sourceCartId })

            return response.getItem()
        } catch (e) {
            let errorMessage = $i18n.t('_core_theme.errors.cart_transfer_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        }
    }

    const patchCartShippingData = ref<Partial<UpdateCartAttributes>>({})
    const { execute: patchCartShipping, apiError: patchCartShippingError } = cartsApiService
        .embed([
            ...CART_EMBEDS,
            // @ts-ignore - TODO: fix app config type
            ...(appConfig.embeds?.cart ?? []),
        ])
        .forId(() => cart.value?.id)
        .usePatch(patchCartShippingData, {
            _key: 'cart-shipping-update',
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cart.value = response.getItem()
            },
        })

    const patchCartPaymentData = ref<Partial<UpdateCartAttributes>>({})
    const { execute: patchCartPayment, apiError: patchCartPaymentError } = cartsApiService
        .embed([
            ...CART_EMBEDS,
            // @ts-ignore - TODO: fix app config type
            ...(appConfig.embeds?.cart ?? []),
        ])
        .forId(() => cart.value?.id)
        .usePatch(patchCartPaymentData, {
            _key: 'cart-payment-update',
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cart.value = response.getItem()
            },
        })

    const patchCartHasDifferentShippingData = ref<Partial<UpdateCartAttributes>>({})
    const { execute: patchCartHasDifferentShipping, apiError: patchCartHasDifferentShippingError } = cartsApiService
        .embed([
            ...CART_EMBEDS,
            // @ts-ignore - TODO: fix app config type
            ...(appConfig.embeds?.cart ?? []),
        ])
        .forId(() => cart.value?.id)
        .usePatch(patchCartHasDifferentShippingData, {
            _key: 'cart-different-shipping-update',
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cart.value = response.getItem()
            },
        })

    const patchCartEmailData = ref<Partial<UpdateCartAttributes>>({})
    const { execute: patchCartEmail, apiError: patchCartEmailError } = cartsApiService
        .embed([
            ...CART_EMBEDS,
            // @ts-ignore - TODO: fix app config type
            ...(appConfig.embeds?.cart ?? []),
        ])
        .forId(() => cart.value?.id)
        .usePatch(patchCartEmailData, {
            _key: 'cart-email-update',
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cart.value = response.getItem()
            },
        })

    async function updateEmail(email: string, options?: { throw: boolean }) {
        await cartPromise.value
        if (!cart.value) return

        try {
            patchCartEmailData.value = {
                email: email,
            }
            await patchCartEmail()
            if (patchCartEmailError.value) throw patchCartEmailError.value
        } catch (e) {
            if (options?.throw) {
                throw e
            }

            if (e instanceof ApiResponseError) {
                notifyError(e.getErrorMessage())
            } else {
                throw e
            }
        }
    }

    async function updateHasDifferentShippingAddress(hasDifferentShippingAddress: boolean) {
        await cartPromise.value
        if (!cart.value) return

        const originalValue = cart.value.hasShippingAddress ?? false

        try {
            hasShippingAddress.value = hasDifferentShippingAddress
            patchCartHasDifferentShippingData.value = {
                has_shipping_address: hasDifferentShippingAddress,
            }
            await patchCartHasDifferentShipping()
            if (patchCartHasDifferentShippingError.value) throw patchCartHasDifferentShippingError.value
        } catch (e) {
            hasShippingAddress.value = originalValue

            if (e instanceof ApiResponseError) {
                notifyError(e.getErrorMessage())
            } else {
                throw e
            }
        } finally {
            try {
                // TODO: handle differently, currently doesn't consider logged-in users
                if (!authStore.isLoggedIn) {
                    if (cart.value.hasShippingAddress) {
                        if (forcedAddressCountries.value.shipping) {
                            await updateCartAddress({
                                [CartAddressModel.ATTR_COUNTRY_ID]: forcedAddressCountries.value.shipping,
                            }, AddressType.SHIPPING)
                        }
                    } else if (forcedAddressCountries.value.billing) {
                        await updateCartAddress({
                            [CartAddressModel.ATTR_COUNTRY_ID]: forcedAddressCountries.value.billing,
                        }, AddressType.BILLING)
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    async function updateShipping(data: Partial<Pick<UpdateCartAttributes, 'shipping_method_id' | 'shipping_option_id'>>) {
        await cartPromise.value
        if (!cart.value) return

        _isShippingMethodLoading.value = true
        const oldPaymentMethod = cart.value.paymentMethod
        try {
            patchCartShippingData.value = {
                shipping_method_id: data.shipping_method_id,
                shipping_option_id: data.shipping_option_id ?? undefined,
            }
            await patchCartShipping()
            if (patchCartShippingError.value) throw patchCartShippingError.value

            await fetchPaymentMethods()
        } catch (e) {
            if (e instanceof ApiResponseError) {
                notifyError(e.getErrorMessage())
            } else {
                throw e
            }
        } finally {
            _isShippingMethodLoading.value = false

            if (oldPaymentMethod?.id && !cart.value?.paymentMethod && cart.value?.shippingMethod) {
                notifyError($i18n.t('_core_theme.errors.payment_method_not_available_for_shipping_method', [oldPaymentMethod.name]))
            }
        }
    }

    async function updatePayment(data: Partial<UpdateCartAttributes>) {
        await cartPromise.value
        if (!cart.value) return

        _isPaymentMethodLoading.value = true
        try {
            patchCartPaymentData.value = data
            await patchCartPayment()
            if (patchCartPaymentError.value) throw patchCartPaymentError.value
        } catch (e) {
            if (e instanceof ApiResponseError) {
                notifyError(e.getErrorMessage())
            } else {
                throw e
            }
        } finally {
            _isPaymentMethodLoading.value = false
        }
    }

    const cartAddressesApiService = useCartAddressesApiService({ cartId: () => cart.value?.id })

    /**
     * A function that will update the cart address.
     * The cart address can be either billing or shipping, and they are both on an endpoint separate from the cart.
     * There are 2 possibilities of updating the address:
     * 1. By providing the whole address data (when the user is not signed in)
     * 2. By providing the ID of the address (when the user is signed in)
     * @throws { ApiResponseError } On API error (validation, etc.)
     */
    async function updateCartAddress(
        data: Parameters<ReturnType<typeof useCartAddressesApiService>['patchShipping']>[0] | number,
        type: AddressType,
        /**
         * Internal options for the function.
         */
        options?: Partial<{
            /**
             * The mode of the function.
             * - `return` will return the updated address
             * - `assign` will assign the updated address to the store
             * @default 'assign'
             */
            mode: 'return' | 'assign'
        }>
    ) {
        await cartPromise.value
        if (!cart.value) return

        const updated = typeof data === 'number' ? { customer_address_id: data } : data

        // TODO: replace with usePatch later
        if (type === AddressType.BILLING) {
            const response = await cartAddressesApiService.patchBilling(updated)
            if (options?.mode === 'return') return response.getItem()
            _billingAddress.value = response.getItem()
        } else if (type === AddressType.SHIPPING) {
            const response = await cartAddressesApiService.patchShipping(updated)
            if (options?.mode === 'return') return response.getItem()
            _shippingAddress.value = response.getItem()
        }
    }
    async function updateCartAddresses(data: Partial<{ shipping: Parameters<ReturnType<typeof useCartAddressesApiService>['patchShipping']>[0] | number, billing: Parameters<ReturnType<typeof useCartAddressesApiService>['patchShipping']>[0] | number }>) {
        /**
         * Check whether the address should be updated.
         * To keep the check simple, we only ignore the update if changing the address by ID and the ID is the same as the current one.
         */
        function shouldUpdateBasedOnId(address: typeof data.shipping, type: AddressType) {
            return typeof address !== 'number' || address !== (type === AddressType.BILLING ? cartAddresses.value.billing?.getAddressId() : type === AddressType.SHIPPING ? cartAddresses.value.shipping?.getAddressId() : null)
        }

        const [
            shippingAddress,
            billingAddress,
        ] = await Promise.all([
            data.shipping && shouldUpdateBasedOnId(data.shipping, AddressType.SHIPPING) ? updateCartAddress(data.shipping, AddressType.SHIPPING, { mode: 'return' }) : undefined,
            data.billing && shouldUpdateBasedOnId(data.billing, AddressType.BILLING) ? updateCartAddress(data.billing, AddressType.BILLING, { mode: 'return' }) : undefined,
        ])

        let shouldUpdateAddresses = false
        // the `!== undefined` check here is important, because the address can be `null` and in that case, we want to reset the
        // locally stored address to `null` as well; in case the request wasn't made, we do not want to update the cached address
        if (data.shipping && shippingAddress !== undefined) {
            const newAddress = shippingAddress ?? null

            if (!newAddress) {
                shouldUpdateAddresses = true
            } else {
                _shippingAddress.value = newAddress
            }
        }
        if (data.billing && billingAddress !== undefined) {
            const newAddress = billingAddress ?? null

            if (!newAddress) {
                shouldUpdateAddresses = true
            } else {
                _billingAddress.value = newAddress
            }
        }

        if (shouldUpdateAddresses) {
            await _fetchCartAddresses()
        }
    }

    // DEPRECATED! - will have a separate endpoint
    // /**
    //  * TODO
    //  * @throws { ApiResponseError } On API error (validation, etc.)
    //  * @param countryId
    //  */
    // async function updateShippingCountry(countryId: string | null) {
    //     await cartPromise.value
    //     if (!cart.value) return
    //
    //     _isCartLoading.value = true
    //     isShippingCountryUpdating.value = true
    //     const oldShippingMethod = cart.value.shippingMethod
    //
    //     const data = {
    //         country_id: countryId!,
    //         ...(authStore.isLoggedIn ? { customer_address_id: null } : {}),
    //     }
    //
    //     try {
    //         if (!cart.value.hasShippingAddress) {
    //             await cartAddressesApiService.patchBilling(data)
    //         } else {
    //             await cartAddressesApiService.patchShipping(data)
    //         }
    //
    //         await Promise.all([
    //             fetchShippingAndPaymentMethods(),
    //             _refreshCart(),
    //         ])
    //     } catch (e) {
    //         throw e
    //     } finally {
    //         isShippingCountryUpdating.value = false
    //         _isCartLoading.value = false
    //
    //         if (oldShippingMethod?.id && !cart.value.shippingMethod) {
    //             notifyError($i18n.t('_core_theme.errors.shipping_method_not_available_for_country', [oldShippingMethod.name]))
    //         }
    //     }
    // }

    const { execute: _fetchCartItems, apiError: cartItemsError } = useCartItemsApiService({ cartId: () => cart.value?.id })
        .embed([
            ...CART_ITEMS_EMBEDS,
        ])
        .useGet({
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cartItems.value = response.getItems()
            },
        })

    /**
     * Fetch the cart items.
     * If there are items already, it will not fetch them again unless the `strategy` parameter is changed.
     * @param strategy The strategy to use when fetching the items
     */
    async function fetchItems(strategy: 'if-missing' | 'if-present' | 'always' = 'if-missing') {
        if (cartItems.value && strategy !== 'always' && strategy !== 'if-present') return
        if (!cartItems.value && strategy === 'if-present') return
        await cartPromise.value
        if (!cart.value) return

        try {
            areItemsRefreshing.value = true
            await _fetchCartItems()
            if (cartItemsError.value) throw cartItemsError.value
        } catch (e) {
            cartItems.value = null

            let errorMessage = $i18n.t('_core_theme.errors.cart_items_load_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        } finally {
            areItemsRefreshing.value = false
        }
    }

    async function addToCart(data: { product_id?: number | null | undefined, amount: number, variation_id?: number | null | undefined, customer_account_id?: number | null | undefined, gift_id?: number | null | undefined }) {
        await cartPromise.value
        if (!cart.value) return

        try {
            const response = await getCartItemsApiService({ cartId: cart.value.id! })
                .embed([
                    ...CART_ITEMS_EMBEDS,
                ])
                .post({
                    ...(data.gift_id ? {
                        [CartItemModel.ATTR_GIFT_ID]: data.gift_id ?? null,
                    } : {
                        [CartItemModel.ATTR_PRODUCT_ID]: data.product_id,
                    }),
                    [CartItemModel.ATTR_AMOUNT]: data.amount,
                    [CartItemModel.ATTR_PRODUCT_VARIATION_ID]: data.variation_id ?? undefined,
                    [CartItemModel.ATTR_CUSTOMER_ACCOUNT_ID]: data.customer_account_id ?? undefined,
                } as CartItemPayload)
            await Promise.all([
                fetchItems('always'),
                _refreshCart(),
            ])

            // emit the cart item added event
            events.emit('cart:item-added', { item: response.getItem()! })   // do not await

        } catch (e) {
            let shouldCreateNewCart = false
            let errorMessage = $i18n.t('_core_theme.errors.add_cart_item_fail')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage

                if (e.isStatus(404)) {
                    shouldCreateNewCart = true
                }
            }
            notifyError(errorMessage)

            console.error(e)

            if (shouldCreateNewCart) {
                await fetchCart(false)
                notifyError($i18n.t('_core_theme.errors.cart_missing'))
            }
        }
    }

    async function changeItemQuantity(item: CartItemModel | number, quantity: number) {
        await cartPromise.value
        if (!cart.value) return

        const itemId: number = typeof item === 'number' ? item : item.id!

        try {
            const response = await getCartItemsApiService({ cartId: cart.value.id! })
                .embed([
                    ...CART_ITEMS_EMBEDS,
                ])
                .forId(itemId)
                .patch({ amount: quantity })

            cartItems.value = cartItems.value?.map((item) => {
                if (item.id === itemId) {
                    // replace with the updated item - or, if the response doesn't contain the item, keep the old one
                    item = response.getItem() ?? item
                }
                return item
            }) ?? null

            await _refreshCart()

        } catch (e) {
            let errorMessage = $i18n.t('_core_theme.errors.cart_item_quantity_change_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        }
    }

    /**
     * @throws { ApiResponseError } On API error (validation, etc.)
     * @param code
     */
    async function applyVoucher(code: string) {
        await cartPromise.value
        if (!cart.value) return

        const response = await getCartDiscountsApiService({ cartId: cart.value.id! })
            .post(code)

        // update the cart with the new data
        cart.value = response.getItem()
        notifySuccess($i18n.t('_core_theme.notifications.cart_voucher_applied'))
    }

    /**
     * @param id The ID of the voucher to remove
     */
    async function removeVoucher(id: number) {
        await cartPromise.value
        if (!cart.value) return

        try {
            await getCartDiscountsApiService({ cartId: cart.value.id! }).forId(id).delete()
            await _refreshCart()
            notifySuccess($i18n.t('_core_theme.notifications.cart_voucher_removed'))
        } catch (e) {
            // TODO
            console.error('[ERROR] cart.ts:', e)
            notifyError($i18n.t('_core_theme.errors.cart_voucher_remove_error'))
        }
    }

    /**
     * Remove an item from the cart.
     * This function will optimistically remove the item from the cart and then try to remove it from the BE.
     * If the BE deletion fails, the cart items will be reset to the old state.
     */
    async function removeItem(item: CartItemModel | ProductModel | ProductVariationModel | number | null | undefined, options?: Partial<{ optimistic: boolean }>) {
        await cartPromise.value
        if (!cart.value || !cartItems.value?.length || item === null || item === undefined) return

        let itemId: number | undefined
        if (typeof item === 'number') {
            itemId = item
        } else if (item instanceof CartItemModel) {
            itemId = item.id ?? undefined
        } else if (item instanceof ProductModel) {
            itemId = cartItems.value?.find(i => i.productId === item.id)?.id ?? undefined
        } else if (item instanceof ProductVariationModel) {
            itemId = cartItems.value?.find(i => i.productVariationId === item.id && i.productId === item.productId)?.id ?? undefined
        }

        if (itemId === undefined) {
            if (import.meta.dev) errorLog('[useCart/removeItem]: No cart item ID found for the provided item.', item)
            notifyError($i18n.t('_core_theme.errors.cart_item_remove_error'))
            return
        }

        // store a copy of the current cart items to reset to it if the BE deletion fails
        const oldCartItems = cartItems.value

        if (options?.optimistic !== false) {
            // optimistically remove the item from the cart
            cartItems.value = cartItems.value?.filter(item => item.id !== itemId)
            // optimistically reset the cart items count to 0 if the last item is removed
            if (cartItems.value?.length === 0) {
                cart.value.setItemsToZero()
            }
        }

        try {
            deletionPendingForItemCount++   // TODO: temporary

            // remove the item on BE
            await getCartItemsApiService({ cartId: cart.value.id! }).forId(itemId).delete()
        } catch (e) {
            if (options?.optimistic !== false) {
                // if the BE deletion fails, reset the cart items to the old state
                cartItems.value = oldCartItems
            }

            let errorMessage = $i18n.t('_core_theme.errors.cart_item_remove_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        } finally {
            deletionPendingForItemCount--   // TODO: temporary
        }

        // if all items have been deleted, refresh the cart and cart items  // TODO: temporary
        if (deletionPendingForItemCount === 0) {
            // get new cart & cart items from BE to make sure the cart is in sync
            await Promise.all([
                fetchItems('always'),
                _refreshCart(),
            ])
        }
    }

    async function destroyCart() {
        await cartPromise.value
        if (!cart.value) return

        try {
            await cartsApiService.forId(cart.value.id!).delete()

            cart.value = null
            cartItems.value = []
            resetOrderData()
        } catch (e) {
            let errorMessage = $i18n.t('_core_theme.errors.cart_clear_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)
            console.error(e)
            return
        }

        await fetchCart(false)

        notifyInfo($i18n.t('_core_theme.notifications.cart_cleared'))
    }

    /**
     * Get the cart item for the provided product (or its specific variation).
     * @todo Optimize me (use a map for cart items)
     */
    function getCartItem(product: ProductModel | ProductVariationModel | null | undefined) {
        if (!product) return null

        if (product instanceof ProductModel) {
            return cartItems.value?.find(i => i.productId === product.id && i.productVariationId === null) ?? null
        }

        if (product instanceof ProductVariationModel) {
            return cartItems.value?.find(i => i.productId === product.productId && i.productVariationId === product.id) ?? null
        }

        return null
    }

    /**
     * Checks if the provided product (or its specific variation) is in the cart.
     */
    function isInCart(product: ProductModel | ProductVariationModel | null | undefined): boolean
    function isInCart(productId: number, variationId?: number): boolean
    function isInCart(arg1: ProductModel | ProductVariationModel | number | null | undefined, arg2?: number): boolean {
        const productId = typeof arg1 === 'number' ? arg1 : arg1 instanceof ProductModel ? arg1.id : arg1?.productId ?? null
        const variationId = typeof arg1 === 'number' ? arg2 ?? null : arg1 instanceof ProductVariationModel ? arg1.id : null

        if (productId === null) return false

        return cartItems.value?.some(item => item.productId === productId && (!variationId || item.productVariationId === variationId)) ?? false
    }

    const { items: shippingMethods, execute: fetchShippingMethods, status: shippingMethodsStatus } =
        useCartShippingMethodsApiService({ cartId: () => cart.value?.id })
            .whereEquals(CartShippingMethodModel.ATTR_CAN_USE, true)
            .embed([CartShippingMethodModel.EMBED_IMAGE_URL])
            .useGet({
                immediate: false,
                watch: false,
            })
    const areShippingMethodsLoading = computed<boolean>(() =>
        shippingMethodsStatus.value === 'pending'
        // show visual loading immediately when a prerequisite is loading
        || isShippingCountryUpdating.value
    )


    const { items: paymentMethods, execute: fetchPaymentMethods, status: paymentMethodsStatus } =
        useCartPaymentMethodsApiService({ cartId: () => cart.value?.id })
            .whereEquals(CartPaymentMethodModel.ATTR_CAN_USE, true)
            .embed([CartPaymentMethodModel.EMBED_IMAGE_URL])
            .useGet({
                immediate: false,
                watch: false,
            })
    const arePaymentMethodsLoading = computed<boolean>(() =>
        paymentMethodsStatus.value === 'pending'
        // show visual loading immediately when a prerequisite is loading
        || _isShippingMethodLoading.value
        || isShippingCountryUpdating.value
    )

    async function fetchShippingAndPaymentMethods() {
        await  Promise.all([
            fetchShippingMethods(),
            fetchPaymentMethods(),
        ])
    }

    const _billingAddress = ref<InstanceType<typeof CartAddressModel> | null>(null)
    const _shippingAddress = ref<InstanceType<typeof CartAddressModel> | null>(null)
    const { items: _cartAddresses, execute: _fetchCartAddresses } = useCartAddressesApiService({ cartId: () => cart.value?.id })
        .useGet({
            immediate: false,
            watch: false,
            onResponse: () => {
                _billingAddress.value = null
                _shippingAddress.value = null
            },
        })
    const cartAddresses = computed(() => {
        let billingAddress: InstanceType<typeof CartAddressModel> | null = null
        let shippingAddress: InstanceType<typeof CartAddressModel> | null = null

        for (const address of _cartAddresses.value ?? []) {
            if (address.isShipping()) {
                shippingAddress = address
            } else if (address.isBilling()) {
                billingAddress = address
            }
        }

        const addresses =  {
            billing: _billingAddress.value ?? billingAddress,
            shipping: _shippingAddress.value ?? shippingAddress,
        }

        // TODO: handle the case for logged-in customers
        // TODO: make SSR safe
        // TODO: do not cause side-effects in computed
        if (forcedAddressCountries.value.billing && addresses.billing && !cart.value?.hasShippingAddress) {
            addresses.billing.countryId = forcedAddressCountries.value.billing
        }

        if (forcedAddressCountries.value.shipping && addresses.shipping) {
            addresses.shipping.countryId = forcedAddressCountries.value.shipping
        }

        return addresses
    }) as ComputedRef<{
        billing: InstanceType<typeof CartAddressModel> | null
        shipping: InstanceType<typeof CartAddressModel> | null
    }>

    async function fetchCartAddresses() {
        await cartPromise.value
        if (!cart.value) return

        await _fetchCartAddresses()
    }

    const paymentReturnUrlBase = useFullUrl('/api/payment-return')

    /**
     * TODO
     * @throws ApiResponseError On API error (validation, etc.)
     * @throws Error When the order could not be created correctly
     */
    async function submitOrder(options?: Partial<SubmitOrderOptions>) {
        orderFormFieldErrors.value = {}

        await cartPromise.value
        if (!cart.value) return

        const eventValues = await events.emit('cart:submitting', {})
        if (eventValues.some(result => result === false)) return

        // ============ ORDER CREATION =============
        const orderResponse = await getOrdersApiService()
            .embed(ORDER_EMBEDS)
            .post({
                cart_id: cart.value.id!,
                note: orderData.note || null,
                ...(orderData.gender_oriented_product_preference ? {
                    gender_oriented_product_preference: orderData.gender_oriented_product_preference,
                } : {}),
                ...(orderData.create_new_account ? {
                    create_new_account: true,
                    password: orderData.password,
                    password_confirmation: orderData.password_confirmation || orderData.password,
                } : {}),
                newsletter: orderData.newsletter || undefined,
            })
            .catch(e => {
                if (e instanceof ApiResponseError) {
                    orderFormFieldErrors.value = e.getValidationErrorsByField()
                }

                throw e
            })

        latestOrder.value = orderResponse.getItem()

        // TODO: throw a custom error with translated message
        if (!latestOrder.value?.id) {
            throw new Error('Order could not be created.')
        }

        // By this point, the order has already been created successfully, thus
        // we need to refresh the cart in case of any further errors

        try {
            // ================ PAYMENT ================
            // check payment service type & handle payment
            const paymentServiceType = cart.value.paymentMethod?.paymentService ?? null
            if (paymentServiceType === PaymentServiceType.GOPAY) {
                // GOPAY ---------------------------------------------------------------------------------------------------
                const goPayResponsePromise = getGoPayApiService().post({
                    order_id: latestOrder.value?.id,
                    payload: null,
                    return_url: joinURL(paymentReturnUrlBase, PaymentService.GoPay),
                })

                await goPayClient.checkout(
                    goPayResponsePromise.then(r => r.getItem()?.gateUrl ?? null)
                )

                await new Promise(() => {})
                // ---------------------------------------------------------------------------------------------------------
            } else if (paymentServiceType === PaymentServiceType.CSOB) {
                // CSOB ----------------------------------------------------------------------------------------------------
                const csobResponse = await getCsobPaymentApiService().post({
                    order_id: latestOrder.value?.id,
                    return_uri: joinURL(paymentReturnUrlBase, PaymentService.CSOB),
                    return_method: 'POST',
                })

                const gateUrl = csobResponse?.getItem()?.gatewayUri ?? null
                if (!gateUrl) {
                    // TODO: throw a custom error with translated message
                    throw new Error('CSOB payment gateway URL is missing.')
                }

                await navigateTo(gateUrl, {
                    external: true,
                })

                await new Promise(() => {})
                // ---------------------------------------------------------------------------------------------------------
            }

            await options?.onSuccess?.()
        } catch (e) {
            console.error('[cart]: Failed to get payment gateway url from BE.', e)
            await navigateTo(localePath({
                name: 'cart-summary', query: {
                    [CART_PAYMENT_RETURN_QUERY_ORDER_ID]: latestOrder.value?.id,
                },
            }))
        } finally {
            // ============= CART REFRESH ==============
            // fetch new cart & reset cart items
            const cartResponse = await cartsApiService
                .embed([
                    ...CART_EMBEDS,
                    // @ts-ignore - TODO: fix app config type
                    ...(appConfig.embeds?.cart ?? []),
                ])
                .post()
            cart.value = cartResponse.getItem()
            cartItems.value = []
        }
    }

    /**
     * A function that will retry the GoPay payment for the provided order id.
     * @param orderId The order ID to retry the payment for
     * @todo unify with other payment providers if needed in the future
     */
    async function retryGoPayPayment(orderId: number) {
        const goPayResponsePromise = getGoPayApiService().post({
            order_id: orderId,
            payload: null,
            return_url: joinURL(paymentReturnUrlBase, PaymentService.GoPay),
        })

        await goPayClient.checkout(
            goPayResponsePromise.then(r => r.getItem()?.gateUrl ?? null)
        )
    }

    // TODO: replace with useFetch when https://github.com/simplo-sro/simploshop-fe/issues/544 is implemented
    const customerLatestOrder: Ref<InstanceType<typeof OrderModel> | null> = ref(null)
    const customerLatestOrderPromise: Ref<Promise<void> | null> = ref(null)
    function fetchCustomerOrder() {
        if (!authStore.customer?.id) return
        const customersService = getCustomersApiService()
        customerLatestOrderPromise.value = new Promise(async (resolve) => {
            try {
                const response = await customersService
                    .forId(authStore.customer?.id)
                    .onlyAttrs([
                        CustomerModel.ATTR_ID,
                    ])
                    .embed([
                        [CustomerModel.EMBED_CUSTOMER_LATEST_ORDER, {
                            embed: [
                                OrderModel.EMBED_SHIPPING,
                                OrderModel.EMBED_PAYMENT,
                                OrderModel.EMBED_ORDER_ADDRESSES,
                            ],
                        }],
                    ])
                    .get()

                customerLatestOrder.value = response.getItem()?.latestOrder ?? null
            } catch (e) {
                console.error(e)
            } finally {
                resolve()
            }
        })

        return customerLatestOrderPromise.value
    }

    watch(() => authStore.isLoggedIn, (val) => {
        customerLatestOrder.value = null
    })

    async function fastForwardCheckout(referenceOrder: InstanceType<typeof OrderModel>, options?: Partial<FastCheckoutOptions>): Promise<void>
    async function fastForwardCheckout(options?: Partial<FastCheckoutOptions>): Promise<void>
    async function fastForwardCheckout(arg1?: InstanceType<typeof OrderModel> | Partial<FastCheckoutOptions>, arg2?: Partial<FastCheckoutOptions>) {
        await cartPromise.value
        if (!cart.value) return

        await customerLatestOrderPromise.value

        const referenceOrder = arg1 instanceof OrderModel ? arg1 : customerLatestOrder.value
        const options = arg1 instanceof OrderModel ? arg2 : arg1

        if (!referenceOrder) {
            notifyError($i18n.t('_core_theme.errors.fast_checkout_missing_reference'))
            return
        }

        const billingAddressId = referenceOrder.orderAddresses.find(a => a.type === AddressType.BILLING)?.customerAddressId ?? null
        const shippingAddressId = referenceOrder.orderAddresses.find(a => a.type === AddressType.SHIPPING)?.customerAddressId ?? null
        const shippingMethodId = referenceOrder.shipping?.shippingMethodId
        const shippingOptionId = referenceOrder.shipping?.shippingOptionId
        const paymentMethodId = referenceOrder.payment?.paymentMethodId

        // TODO: handle missing shipping option id for shipping methods that have options:
        // (!shippingOptionId && referenceOrder.shipping?.shippingMethod?.hasOptions)
        if (!billingAddressId || !shippingMethodId || !paymentMethodId) {
            notifyError($i18n.t('_core_theme.errors.fast_checkout_error'))
            return
        }

        try {
            const [response] = await Promise.all([
                cartsApiService
                    .embed([
                        ...CART_EMBEDS,
                        // @ts-ignore - TODO: fix app config type
                        ...(appConfig.embeds?.cart ?? []),
                    ])
                    .forId(() => cart.value?.id)
                    .patch({
                        shipping_method_id: shippingMethodId,
                        shipping_option_id: shippingOptionId ?? undefined,
                        payment_method_id: paymentMethodId,
                    }),
                updateCartAddresses({
                    billing: billingAddressId,
                    shipping: shippingAddressId ?? undefined,
                }),
            ])

            cart.value = response.getItem()
            await options?.onSuccess?.()
        } catch (e) {
            // TODO: show validation errors from BE, i.e. when the used payment method is not available any more
            notifyError($i18n.t('_core_theme.errors.fast_checkout_error'))
            console.error(e)
        }
    }

    // CART SOCKET
    const { listen } = useWs({
        broadcaster: 'pusher',
        connection: {
            host: runtimeConfig.public.pusher.url,
            key: runtimeConfig.public.pusher.appKey,
        },
        onError: () => {
            notifyError($i18n.t('_core_theme.errors.cant_connect_to_socket'))
        },
        // @ts-ignore TODO: remove when AppConfig type is fixed
        immediate: appConfig.features?.websocket?.cart === true,
    })

    const CART_WS_CHANNEL = () => `shop.${propertiesStore.shopInfo?.id ?? 0}.cart.${cart.value?.id}` as const

    const { reconnect: refreshListeningToCartItemSoldOut, connect: listenToCartItemSoldOut } = listen(
        CART_WS_CHANNEL,
        'cart-item-sold-out-message',
        (data) => {
            notifyError($i18n.t('_core_theme.errors.cart_item_sold_out', { name: getLocalizedValue(data.name) }))
            _refreshCart()
            fetchItems('if-present')
        }, {
            immediate: false,
        }
    )

    const { reconnect: refreshListeningToCartItemStockDecreased, connect: listenToCartItemStockDecreased } = listen(
        CART_WS_CHANNEL,
        'cart-item-amount-changed-message',
        (data) => {
            notifyInfo($i18n.t('_core_theme.errors.cart_item_stock_decreased', { name: getLocalizedValue(data.name), amount: data.amount }))
            _refreshCart()
            fetchItems('if-present')
        }, {
            immediate: false,
        }
    )


    return {
        cart: cart,
        cartPromise: cartPromise,
        isCartLoading: isCartLoading,
        latestOrder: latestOrder,
        orderData: orderData,
        orderFormFieldErrors: orderFormFieldErrors,
        items: cartItems,
        areItemsLoading: areItemsLoading,
        areItemsRefreshing: areItemsRefreshing,
        isShippingMethodLoading: isShippingMethodLoading,
        isPaymentMethodLoading: isPaymentMethodLoading,
        phase: currentPhase,
        isLastCartPhase: isLastCartPhase,
        phases: phaseManager.phases,
        visiblePhases: computed(() => phaseManager.phases.value.filter(phase => !phase.hidden)),
        isPhaseActive: phaseManager.isPhaseActive,
        isPhaseBeforeActive: phaseManager.isPhaseBeforeActive,
        useIsPhaseActive: phaseManager.useIsPhaseActive,
        isPhaseCompleted: phaseManager.isPhaseCompleted,
        useIsPhaseCompleted: phaseManager.useIsPhaseCompleted,
        switchTo: phaseManager.switchTo,
        canSwitchTo: phaseManager.canSwitchTo,
        isPhaseSwitchingDisabled: isPhaseSwitchingDisabled,
        useCanSwitchTo: phaseManager.useCanSwitchTo,
        nextPhase: phaseManager.nextPhase,
        previousPhase: phaseManager.previousPhase,
        fetchCart: fetchCart,
        fetchCartIfNotLoaded: fetchCartIfNotLoaded,
        updateEmail: updateEmail,
        updateHasDifferentShippingAddress: updateHasDifferentShippingAddress,
        updateShipping: updateShipping,
        updatePayment: updatePayment,
        updateCartAddress: updateCartAddress as (data: Parameters<ReturnType<typeof useCartAddressesApiService>['patchShipping']>[0] | number, type: AddressType) => Promise<void>,
        updateCartAddresses: updateCartAddresses,
        fetchItems: fetchItems,
        changeItemQuantity: changeItemQuantity,
        removeItem: removeItem,
        destroyCart: destroyCart,
        submitOrder: submitOrder,
        addToCart: addToCart,
        applyVoucher: applyVoucher,
        removeVoucher: removeVoucher,
        retryGoPayPayment: retryGoPayPayment,
        requirements: phaseManager.requirements,
        shippingMethods: shippingMethods,
        areShippingMethodsLoading: areShippingMethodsLoading,
        fetchShippingMethods: fetchShippingMethods,
        paymentMethods: paymentMethods,
        arePaymentMethodsLoading: arePaymentMethodsLoading,
        fetchPaymentMethods: fetchPaymentMethods,
        fetchShippingAndPaymentMethods: fetchShippingAndPaymentMethods,
        isDifferentShippingAddressAllowed: isDifferentShippingAddressAllowed,
        forcedAddressCountries: forcedAddressCountries,
        cartAddresses: cartAddresses,
        fetchCartAddresses: fetchCartAddresses,
        getCartItem: getCartItem,
        isInCart: isInCart,
        customerLatestOrder: customerLatestOrder,
        fetchCustomerOrder: fetchCustomerOrder,
        fastForwardCheckout: fastForwardCheckout,
    }
})

export function useCartIdCookie() {
    return useCookie<string | null>(useRuntimeConfig().public.cartIdCookieName, {
        maxAge: 2147483647,
        default: () => null,
    })
}
