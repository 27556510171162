import { ApiModel } from '@composable-api/api.model'
import { ProductListProductModel } from './product-list-product.model'

interface Attributes {
    [ProductListModel.ATTR_ID]: number
    [ProductListModel.ATTR_NAME]: string
    [ProductListModel.ATTR_DESCRIPTION]: string | null
    [ProductListModel.ATTR_POSITION]: number | null
}

interface Embeds {
    [ProductListModel.EMBED_PRODUCT_LIST_PRODUCTS]?: ProductListProductModel[]
}

export class ProductListModel extends ApiModel<Attributes, Embeds> {
    static key = 'ProductListModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_POSITION = 'position'

    static readonly EMBED_PRODUCT_LIST_PRODUCTS = 'product_list_products'

    get id() {
        return this._getAttribute(ProductListModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductListModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(ProductListModel.ATTR_DESCRIPTION)
    }

    get position() {
        return this._getAttribute(ProductListModel.ATTR_POSITION)
    }

    get productListProducts() {
        return this._getEmbed(ProductListModel.EMBED_PRODUCT_LIST_PRODUCTS, ProductListProductModel)
    }
}
