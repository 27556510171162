import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [QrCodeModel.ATTR_WIDTH]: number
    [QrCodeModel.ATTR_HEIGHT]: number
    [QrCodeModel.ATTR_BASE64_PATH]: string
}

export class QrCodeModel extends ApiModel<Attributes> {
    static key = 'QrCodeModel'

    static readonly ATTR_WIDTH = 'width'
    static readonly ATTR_HEIGHT = 'height'
    static readonly ATTR_BASE64_PATH = 'base64_path'

    get width() {
        return this._getAttribute(QrCodeModel.ATTR_WIDTH)
    }

    get height() {
        return this._getAttribute(QrCodeModel.ATTR_HEIGHT)
    }

    get base64Path() {
        return this._getAttribute(QrCodeModel.ATTR_BASE64_PATH)
    }
}
