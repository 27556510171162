import type { Monetary } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { GiftModel } from './gift.model'
import type { GiftRuleConditionEnum, GiftRuleTypeEnum } from '../enums/product'

interface Attributes {
    [GiftRuleModel.ATTR_ID]: number
    [GiftRuleModel.ATTR_GIFT_ID]: number
    [GiftRuleModel.ATTR_IS_ACTIVE]: boolean
    [GiftRuleModel.ATTR_TYPE]: GiftRuleTypeEnum
    [GiftRuleModel.ATTR_CONDITION]: GiftRuleConditionEnum
    [GiftRuleModel.ATTR_AMOUNT]: Monetary | null
}

interface Embeds {
    [GiftRuleModel.EMBED_GIFT]: GiftModel
}

export class GiftRuleModel extends ApiModel<Attributes, Embeds> {
    static key = 'GiftRuleModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_GIFT_ID = 'gift_id'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_CONDITION = 'condition'
    static readonly ATTR_AMOUNT = 'amount'

    static readonly EMBED_GIFT = 'gift'

    get id() {
        return this._getAttribute(GiftRuleModel.ATTR_ID)
    }

    get giftId() {
        return this._getAttribute(GiftRuleModel.ATTR_GIFT_ID)
    }

    get isActive() {
        return this._getAttribute(GiftRuleModel.ATTR_IS_ACTIVE)
    }

    get type() {
        return this._getAttribute(GiftRuleModel.ATTR_TYPE)
    }

    get condition() {
        return this._getAttribute(GiftRuleModel.ATTR_CONDITION)
    }

    get amount() {
        return this._getAttribute(GiftRuleModel.ATTR_AMOUNT)
    }

    get gift() {
        return this._getEmbed(GiftRuleModel.EMBED_GIFT, GiftModel)
    }
}
