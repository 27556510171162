import { MeModel } from '../models/me.model'
import { CustomerAddressModel } from '../models/customer-address.model'
import { CustomerModel } from '../models/customer.model'
import { CustomerSessionModel } from '../models/customer-session.model'
import { OrderModel } from '../models/order.model'
import { AddressModel } from '../models/address.model'
import { BannerModel } from '../models/banner.model'
import { NewsletterModel } from '../models/newsletter.model'
import { ProductModel } from '../models/product.model'
import { UserSessionModel } from '../models/user-session.model'
import { ProductReviewModel } from '../models/product-review.model'
import { ProductVariationModel } from '../models/product-variation.model'
import { AttachmentModel } from '../models/attachment.model'
import { ProductAttachmentModel } from '../models/product-attachment.model'
import { ProductAvailabilityModel } from '../models/product-availability.model'
import { ProductImageModel } from '../models/product-image.model'
import { ProductRelatedProductModel } from '../models/product-related-product.model'
import { ProductVideoModel } from '../models/product-video.model'
import { StaticPageModel } from '../models/static-page.model'
import { CategoryModel } from '../models/category.model'
import { CategoryTreeModel } from '../models/category-tree.model'
import { CompanyInfoModel } from '../models/company-info.model'
import { CustomerProductListModel } from '../models/customer-product-list.model'
import { CustomerProductListItemModel } from '../models/customer-product-list-item.model'
import { PageModel } from '../models/page.model'
import { CartModel } from '../models/cart.model'
import { CartItemModel } from '../models/cart-item.model'
import { CartDiscountModel } from '../models/cart-discount.model'
import { CartShippingMethodModel } from '../models/cart-shipping-method.model'
import { CartPaymentMethodModel } from '../models/cart-payment-method.model'
import { CartAddressModel } from '../models/cart-address.model'
import { FormattedCurrencyModel } from '../models/custom/formatted-currency.model'
import { ProductFiltersResponseModel } from '../models/product-filters-response.model'
import { ProductFilterModel } from '../models/product-filter.model'
import { ProductFilterItemModel } from '../models/product-filter-item.model'
import { ProductFiltersPaginationModel } from '../models/product-filters-pagination.model'
import { ProductListModel } from '../models/product-list.model'
import { ProductListProductModel } from '../models/product-list-product.model'
import { OrderItemModel } from '../models/order-item.model'
import { OrderAddressModel } from '../models/order-address.model'
import { CsobPaymentModel } from '../models/csob-payment.model'
import { CurrencyModel } from '../models/currency.model'
import { CountryModel } from '../models/country.model'
import { ProductVariationPropertyModel } from '../models/custom/product-variation-property.model'
import { ProductVariationPropertyAttributeModel } from '../models/custom/product-variation-property-attribute.model'
import { ArticleModel } from '../models/article.model'
import { ArticleAuthorModel } from '../models/custom/article-author.model'
import { ArticleCategoryModel } from '../models/article-category.model'
import { UserModel } from '../models/user.model'
import { CustomerPasswordModel } from '../models/customer-password.model'
import { FreeShippingModel } from '../models/free-shipping.model'
import { ShopInfoModel } from '../models/shop-info.model'
import { GoPayModel } from '../models/go-pay.model'
import { BrandModel } from '../models/brand.model'
import { OrderInvoiceModel } from '../models/order-invoice.model'
import { ProductPackageItemModel } from '../models/product-package-item.model'
import { OrderPaymentMethodModel } from '../models/order-payment-method.model'
import { OrderShippingMethodModel } from '../models/order-shipping-method.model'
import { QrCodeModel } from '../models/qr-code.model'
import { PaymentMethodModel } from '../models/payment-method.model'
import { DiscountModel } from '../models/discount.model'
import { BrandTreeModel } from '../models/brand-tree.model'
import { ShippingMethodModel } from '@simploshop-models/shipping-method.model'
import { CustomerAccountModel } from '../models/customer-account.model'
import { CustomerAccountUsageModel } from '../models/customer-account-usage.model'
import { LoyaltyProgramModel } from '../models/loyalty-program.model'
import { GiftModel } from '../models/gift.model'
import { GiftRuleModel } from '../models/gift-rule.model'

import { createClassesMap, definePayloadPlugin, definePayloadReducer, definePayloadReviver } from '#imports'
import { getApiModelPayloadReducer, getApiModelPayloadReviver } from '@composable-api-utils/serialization'

export const models = createClassesMap([
    MeModel,
    CustomerAddressModel,
    CustomerModel,
    CustomerSessionModel,
    OrderModel,
    AddressModel,
    BannerModel,
    NewsletterModel,
    ProductModel,
    UserSessionModel,
    ProductReviewModel,
    ProductVariationModel,
    AttachmentModel,
    ProductAttachmentModel,
    ProductAvailabilityModel,
    ProductImageModel,
    ProductRelatedProductModel,
    ProductVideoModel,
    StaticPageModel,
    CategoryModel,
    CategoryTreeModel,
    CompanyInfoModel,
    CustomerProductListModel,
    CustomerProductListItemModel,
    PageModel,
    CartModel,
    CartItemModel,
    CartDiscountModel,
    CartShippingMethodModel,
    CartPaymentMethodModel,
    CartAddressModel,
    ShippingMethodModel,
    FormattedCurrencyModel,
    ProductFiltersResponseModel,
    ProductFilterModel,
    ProductFilterItemModel,
    ProductFiltersPaginationModel,
    ProductListModel,
    ProductListProductModel,
    OrderItemModel,
    OrderAddressModel,
    CsobPaymentModel,
    CurrencyModel,
    CountryModel,
    ProductVariationPropertyModel,
    ProductVariationPropertyAttributeModel,
    ArticleModel,
    ArticleAuthorModel,
    ArticleCategoryModel,
    UserModel,
    CustomerPasswordModel,
    FreeShippingModel,
    ShopInfoModel,
    GoPayModel,
    BrandModel,
    OrderInvoiceModel,
    ProductPackageItemModel,
    OrderPaymentMethodModel,
    OrderShippingMethodModel,
    QrCodeModel,
    PaymentMethodModel,
    DiscountModel,
    BrandTreeModel,
    CustomerAccountModel,
    CustomerAccountUsageModel,
    LoyaltyProgramModel,
    GiftModel,
    GiftRuleModel,
])

export default definePayloadPlugin(() => {
    definePayloadReducer('model', getApiModelPayloadReducer())
    definePayloadReviver('model', getApiModelPayloadReviver(models))
})
