import Echo from 'laravel-echo'
import { withoutTrailingSlash, withoutProtocol, parseURL } from 'ufo'
import type { MaybePromise } from 'rollup'
import type { WsChannels, WsMessages, WsPrivateChannels, WsPrivateMessages } from '../../types/ws'
import type Pusher from 'pusher-js'

export type CreateWsClientOptions = {
    connection: {
        host: string
    }
    auth?: {
        handler: (info: { socketId: string, channelName: string }) => MaybePromise<{
            auth: string,
            channel_data?: string
            shared_secret?: string
        } | null>
    }
    onConnect?: () => MaybePromise<void>
    onConnecting?: () => MaybePromise<void>
    onDisconnect?: () => MaybePromise<void>
    onReconnecting?: () => MaybePromise<void>
    onError?: () => MaybePromise<void>
} & (
    | {
    /**
     * The broadcaster to use for the websocket connection.
     */
        broadcaster: 'socket.io'
        connection: {
            key?: never
        }
    }
    | {
        broadcaster: 'pusher'
        connection: {
            key: string
        }
    }
)

export interface WsListenOptions<Private extends boolean> {
    private: Private
    onConnecting: () => void
    onSubscribe: () => void
    onSubscribeError: () => void
    onDisconnect?: () => MaybePromise<void>
}

export async function createWsClient(options: CreateWsClientOptions) {
    if (!import.meta.client) {
        return {
            listen: () => { return (channel?: boolean) => {} },
        }
    }

    const host = withoutTrailingSlash(withoutProtocol(options.connection.host))
    const isHttps = parseURL(options.connection.host).protocol === 'https:'

    // create the ws client
    let client: Pusher | undefined // typeof import('socket.io-client')['io']
    if (options.broadcaster === 'socket.io') {
        // const { io } = await import('socket.io-client')
        // client = io
        client = undefined
        errorLog('[createWsClient]: Socket.io is not installed.')
    } else if (options.broadcaster === 'pusher') {
        const { default: Pusher } = await import('pusher-js')
        client = new Pusher(options.connection.key!, {
            cluster: 'eu',
            wsHost: host,
            forceTLS: isHttps,
            enabledTransports: ['ws', 'wss'],
            disableStats: true,
            // @ts-expect-error - pusher-js types are not correct (they expect `endpoint` & `transport` even when a custom handler is provided)
            channelAuthorization: options.auth ? {
                customHandler: async (info, setResult) => {
                    if (!options.auth) {
                        errorLog('[createWsClient]: Auth handler fatal error.')
                        return
                    }

                    const result = await options.auth?.handler(info)
                    setResult(result ? null : Error('[createWsClient]: Could not authenticate user.'), result)
                },
            } : undefined,
        })
    }

    if (!client) {
        throw new Error('[createWsClient]: No broadcaster client was provided.')
    }

    // create echo
    const echo = new Echo({
        broadcaster: options.broadcaster as 'pusher',
        client: client,
        host: host,
    })

    // a flag for pusher to differentiate the reconnecting event from the initial connection
    let wasConnected = false

    if (options.broadcaster === 'pusher') {
        echo.connector.pusher.connection.bind('connecting', () => {
            if (wasConnected) {
                options.onReconnecting?.()
            } else {
                options.onConnecting?.()
            }
        })
    } else {
        options.onConnecting?.()
    }

    if (options.onConnect) {
        if (options.broadcaster === 'pusher') {
            echo.connector.pusher.connection.bind('connected', () => {
                wasConnected = true
                options.onConnect?.()
            })
        } else if (options.broadcaster === 'socket.io') {
            // echo.connector.socket.on('connect', options.onConnect)
        }
    }

    if (options.onDisconnect) {
        if (options.broadcaster === 'pusher') {
            echo.connector.pusher.connection.bind('disconnected', options.onDisconnect)
        } else if (options.broadcaster === 'socket.io') {
            // echo.connector.socket.on('disconnect', options.onDisconnect)
        }
    }

    if (options.onReconnecting) {
        // pusher is handled above in the connecting event
        if (options.broadcaster === 'socket.io') {
            // echo.connector.socket.on('reconnecting', options.onReconnecting)
        }
    }

    if (options.onError) {
        if (options.broadcaster === 'pusher') {
            echo.connector.pusher.connection.bind('failed', options.onError)
            echo.connector.pusher.connection.bind('unavailable', options.onError)
        }
    }

    function listen<
        C extends keyof WsChannels,
        E extends keyof WsMessages[C] & string,
        PC extends keyof WsPrivateChannels,
        PE extends keyof WsPrivateMessages[PC] & string,
        IsChannelPrivate extends boolean = false,
    >(
        channel: IsChannelPrivate extends true ? PC : C,
        event: IsChannelPrivate extends true ? PE : E,
        callback: (data: IsChannelPrivate extends true ? WsPrivateMessages[PC][PE] : WsMessages[C][E]) => void,
        opts?: Partial<WsListenOptions<IsChannelPrivate>>
    ): ((leaveChannel?: boolean) => void) {
        let stop: () => void = (leaveChannel?: boolean) => {
            throw new Error('[createWsClient:listen]: No listener found to stop')
        }

        const _buildStopCallback = (callback: () => void) => (leaveChannel?: boolean) => {
            callback()
            if (leaveChannel) {
                echo.leave(channel)
            }
            opts?.onDisconnect?.()
        }

        const eventKey = `.${event}`

        opts?.onConnecting?.()

        if (opts?.private) {
            echo.private(channel).listen(eventKey, callback)

            if (options.broadcaster === 'pusher') {
                stop = _buildStopCallback(() => echo.private(channel).stopListening(eventKey, callback))
            } else if (options.broadcaster === 'socket.io') {
                // stop = _buildStopCallback(() => echo.connector.socket.removeListener(eventKey, callback))
            }

            return stop
        }

        if (options.broadcaster === 'pusher') {
            stop = _buildStopCallback(() => echo.channel(channel).stopListening(eventKey, callback))
        } else if (options.broadcaster === 'socket.io') {
            // stop = _buildStopCallback(() => echo.connector.socket.removeListener(eventKey, callback))
        }

        const ch = echo.channel(channel).listen(eventKey, callback)

        if (opts?.onSubscribe) {
            ch.subscribed(opts.onSubscribe)
        }
        if (opts?.onSubscribeError) {
            ch.error(opts.onSubscribeError)
        }


        return stop
    }

    return {
        listen,
    }
}
