import { ApiModel } from '@composable-api/api.model'
import { ProductModel } from './product.model'
import { ProductListModel } from './product-list.model'
interface Attributes {
    [ProductListProductModel.ATTR_ID]: number
    [ProductListProductModel.ATTR_NAME]: string | null
    [ProductListProductModel.ATTR_POSITION]: number
    [ProductListProductModel.ATTR_PRODUCT_ID]: number
    [ProductListProductModel.ATTR_PRODUCT_LIST_ID]: number
}

interface Embeds {
    [ProductListProductModel.EMBED_PRODUCT]: ProductModel,
    [ProductListProductModel.EMBED_PRODUCT_LIST]: ProductListModel,
}

export class ProductListProductModel extends ApiModel<Attributes, Embeds> {
    static key = 'ProductListProductModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PRODUCT_LIST_ID = 'product_list_id'

    static readonly EMBED_PRODUCT = 'product'
    static readonly EMBED_PRODUCT_LIST = 'product_list'

    get id() {
        return this._getAttribute(ProductListProductModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductListProductModel.ATTR_NAME)
    }

    get position() {
        return this._getAttribute(ProductListProductModel.ATTR_POSITION)
    }

    get productId() {
        return this._getAttribute(ProductListProductModel.ATTR_PRODUCT_ID)
    }

    get productListId() {
        return this._getAttribute(ProductListProductModel.ATTR_PRODUCT_LIST_ID)
    }

    get product() {
        return this._getEmbed(ProductListProductModel.EMBED_PRODUCT, ProductModel)
    }

    get productList() {
        return this._getEmbed(ProductListProductModel.EMBED_PRODUCT_LIST, ProductListModel)
    }
}
