import { useAuthCookies } from '../../server/utils/auth'
import type { RouteLocationNormalized } from 'vue-router'
import { parseCookies } from 'h3'
import { ApiResponse } from '@composable-api/api.response'
import { PageModel } from '@simploshop-models/page.model'
import { FetchError } from 'ofetch'
import { ME_MODEL_EMBEDS } from '../assets/ts/constants/customer'

export function useInit() {
    const nuxtApp = useNuxtApp()
    const authStore = useAuthStore()
    const event = useRequestEvent()
    const runtimeConfig = useRuntimeConfig()
    const appConfig = useAppConfig()
    const { setAuthCookies, deleteAuthCookies } = useAuthCookies(event)
    const pages = useStatePage()

    const pageApiService = getPageApiService()

    const cookies = event ? parseCookies(event) : {}

    const { setData, getData, isCachingEnabled } = useServerCache()

    /**
     * Fetches the current user and sets it in the auth store.
     * @returns `true` if the user is a guest, `false` otherwise
     */
    async function _fetchMe() {
        const response = await getMeApiService()
            .embed([
                ...ME_MODEL_EMBEDS,
                [MeModel.EMBED_CUSTOMER, {
                    // @ts-ignore - TODO: fix app config type
                    embed: appConfig.embeds?.customer ?? [],
                }],
            ])
            .exceptAttrs([MeModel.ATTR_CUSTOMER])
            .get()

        const meModel = response.getItem()
        authStore._setCustomer(meModel)
        return !!(meModel?.isGuest)
    }

    /**
     * Checks if the refresh token is present in the request.
     */
    function isRefreshTokenPresent(): boolean {
        return !!cookies[runtimeConfig.public.refreshTokenCookieName]
    }

    /**
     * Checks if the auth cookies are present in the request.
     * If the access token is present, it is considered enough;
     * if not, the refresh token is also checked.
     */
    function areAuthCookiesPresent(): boolean {
        return !!cookies[runtimeConfig.public.authTokenCookieName]
        || isRefreshTokenPresent()
    }

    async function initAuth() {
        if (authStore._meModel !== undefined) return

        // if calling on the server & the user doesn't have an access or refresh token, return
        if (import.meta.server && !areAuthCookiesPresent()) {
            authStore._meModel = null   // set the MeModel as `null` not to fetch it on the client side
            return
        }

        if (import.meta.server) {
            performanceCheckpoint(event!, 'auth')
        }

        try {
            const isGuest = await _fetchMe()

            await nuxtApp.runWithContext(async () => {
                if (isGuest && isRefreshTokenPresent()) {
                    await refreshAuthSession(event)
                    await _fetchMe()
                }
            })
        } catch (e) {
            if (e instanceof ApiResponseError) {
                if (e.getStatus() !== 401) {
                    errorLog('[useInit:initAuth] API error', e)
                }
                deleteAuthCookies()
            } else {
                errorLog('[useInit:initAuth]', e)
            }
        }

        if (import.meta.server) {
            performanceCheckpoint(event!, 'auth')
        }
    }

    async function fetchPage(to: RouteLocationNormalized) {
        const page = pages.value.get(to.path)
        if (page === null) return null
        if (page !== undefined) return page

        // if we don't have the page data, fetch it
        try {
            if (import.meta.server) {
                performanceCheckpoint(event!, 'page-api')
            }

            let pageResponse: ApiResponse<PageModel> | undefined
            if (import.meta.server && isCachingEnabled.value) {
                const cachedData = await getData(`p-${to.path.replaceAll('/', '-')}` as any) // TODO: add key type
                if (cachedData) {
                    pageResponse = new ApiResponse(cachedData as any, PageModel)
                }
            }

            if (!pageResponse) {
                pageResponse = await pageApiService.forId(to.path).get()
                if (import.meta.server && isCachingEnabled.value) {
                    await setData(`p-${to.path.replaceAll('/', '-')}` as any,
                        // @ts-expect-error accessing private method of ApiResponse
                        pageResponse.getReducedData()[0], {
                            ttl: 60 * 15,
                        })
                }
            }
            const page = pageResponse.getItem()
            pages.value.set(to.path, page)

            if (import.meta.server) {
                performanceCheckpoint(event!, 'page-api')
            }

            return page
        } catch (e) {
            if (!(e instanceof FetchError) && !(e instanceof ApiResponseError)) {
                console.error('[useInit:fetchPage]', e)
            }

            // if the page is not a backend page, set it to `null`
            pages.value.set(to.path, null)
        }

        return null
    }

    async function initAll(to: RouteLocationNormalized) {
        return await Promise.all([
            fetchPage(to),
            initAuth(),
        ])
    }

    return {
        initAll,
        fetchPage,
    }
}
