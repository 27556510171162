export enum ShippingServiceType {
    NONE = 0,
    STOCK = 1,
    PACKETA = 2,
    CZECH_POST = 3,
    PPL = 4,
    CESKA_POSTA_NAPOSTU = 5,
    PENGUIN_BOX = 6,
    PENGUIN_BOX_DIRECT = 7,
    CESKA_POSTA_BALIK_DO_RUKY = 8,
    GLS = 9,
    PENGUIN_LOGISTIC = 10,
    DPD = 11,
    PENGUIN_MINI = 12,
    PACKETA_ADDRESS_DELIVERY = 13,
    WEDO = 14,
    WEDO_ADDRESS_DELIVERY = 15,
    PPL_ADDRESS_DELIVERY = 16,
    PARTNER_SALONS = 17,
    GLS_PARCEL_SHOP_DELIVERY = 18,
}

export enum ShippingMethodAddressRequirement {
    ONLY_BILLING_FULL = 0,
    ONLY_BILLING_BASIC = 1,
    BOTH = 2,
}
