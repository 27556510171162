
// @ts-nocheck


export const localeCodes =  [
  "cs"
]

export const localeLoaders = {
  cs: [
    {
      key: "locale_cs_45CZ_46json5_21a4ad2f",
      load: () => import("#nuxt-i18n/21a4ad2f" /* webpackChunkName: "locale_cs_45CZ_46json5_21a4ad2f" */),
      cache: true
    },
    {
      key: "locale_cs_45CZ_46json5_f6686371",
      load: () => import("#nuxt-i18n/f6686371" /* webpackChunkName: "locale_cs_45CZ_46json5_f6686371" */),
      cache: true
    },
    {
      key: "locale_cs_45CZ_46json5_614a5695",
      load: () => import("#nuxt-i18n/614a5695" /* webpackChunkName: "locale_cs_45CZ_46json5_614a5695" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/765f8d89" /* webpackChunkName: "config_i18n_46config_46ts_765f8d89" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: "default",
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: false,
    onlyLocales: [
      "cs"
    ]
  },
  compilation: {
    jit: true,
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "cs",
      language: "cs-CZ",
      name: "Česky",
      flag: "/images/flags/flag-cz.webp",
      flagAlt: "Česká vlajka",
      domainDefault: true,
      domain: "https://vut.dev.simploshop.com",
      files: [
        "/opt/simploshop/shared/core-simploshop/i18n/locales/cs-CZ.json5",
        "/opt/simploshop/shared/core-theme/i18n/locales/cs-CZ.json5",
        "/opt/simploshop/themes/vut/i18n/locales/cs-CZ.json5"
      ]
    }
  ],
  defaultLocale: "cs",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "https://vut.dev.simploshop.com",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "cs",
    language: "cs-CZ",
    name: "Česky",
    flag: "/images/flags/flag-cz.webp",
    flagAlt: "Česká vlajka",
    domainDefault: true,
    domain: "https://vut.dev.simploshop.com",
    files: [
      {
        path: "/opt/simploshop/shared/core-simploshop/i18n/locales/cs-CZ.json5",
        cache: undefined
      },
      {
        path: "/opt/simploshop/shared/core-theme/i18n/locales/cs-CZ.json5",
        cache: undefined
      },
      {
        path: "/opt/simploshop/themes/vut/i18n/locales/cs-CZ.json5",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/