import { CartModel } from '@simploshop-models/cart.model'
import { CartItemModel } from '@simploshop-models/cart-item.model'
import type { EmbedBuilderFields } from '@composable-api/url-builder/api.base-url-builder'
import { OrderPaymentMethodModel } from '@simploshop-models/order-payment-method.model'

export const CART_EMBEDS = [
    CartModel.EMBED_SHIPPING_OPTION,
    CartModel.EMBED_SHIPPING_COUNTRY_ID,
] as const

export const CART_ITEMS_EMBEDS = [
    CartItemModel.EMBED_PRODUCT_AVAILABILITY,
    CartItemModel.EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS,
    CartItemModel.EMBED_IMAGE_URL,
    CartItemModel.EMBED_VARIATION_PROPERTIES_NAMES, // selected attributes for properties (product variations)
    CartItemModel.EMBED_DISCOUNT_PERCENTS,
] as const

export const ORDER_EMBEDS: EmbedBuilderFields<OrderModel> = [
    OrderModel.EMBED_SHIPPING,
    [OrderModel.EMBED_PAYMENT, {
        embed: [
            OrderPaymentMethodModel.EMBED_PAYMENT_METHOD,
        ],
    }],
]
