import type { Monetary, ApiDateTime } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { type GetFormattedStockCountOptions, type GetPriceOptions, ProductModel } from './product.model'
import { CartItemType } from '@sim-api-enums/cart'
import { getPriceValue } from '../utils/localization'
import { getFormattedPrice } from '../utils/localization'
import { ProductAvailabilityModel } from '@simploshop-models/product-availability.model'
import { CustomerAccountModel } from './customer-account.model'

interface Attributes {
    [CartItemModel.ATTR_ID]: number
    [CartItemModel.ATTR_NAME]: string | null
    [CartItemModel.ATTR_IMAGE_ID]: number | null
    [CartItemModel.ATTR_PRODUCT_ID]: number
    [CartItemModel.ATTR_PRODUCT_VARIATION_ID]: number | null
    [CartItemModel.ATTR_GIFT_ID]: number | null
    [CartItemModel.ATTR_AMOUNT]: number
    [CartItemModel.ATTR_AMOUNT_WITHOUT_CUSTOMER_ACCOUNT]: number
    [CartItemModel.ATTR_ITEM_TYPE]: CartItemType
    [CartItemModel.ATTR_PARENT_ID]: number | null
    [CartItemModel.ATTR_PAYLOAD]: Payload | null
    [CartItemModel.ATTR_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_TAXED_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_ORIGINAL_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_TAXED_ORIGINAL_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_TOTAL_PRICE]: Monetary
    [CartItemModel.ATTR_TAXED_TOTAL_PRICE]: Monetary
    [CartItemModel.ATTR_TAX_AMOUNT]: number
    [CartItemModel.ATTR_PRODUCT_URLS]: string | null
    [CartItemModel.ATTR_UPDATE_STOCK]: boolean
    [CartItemModel.ATTR_STOCK_STATE]: number | null
    [CartItemModel.ATTR_WEIGHT]: Weight | null
    [CartItemModel.ATTR_CUSTOMER_ACCOUNT_ID]: number | null
    [CartItemModel.ATTR_CUSTOMER_ACCOUNT_AMOUNTS]: number
    [CartItemModel.ATTR_CUSTOMER_ACCOUNT_AMOUNTS_PER_UNIT]: number
    [CartItemModel.ATTR_TOTAL_PRICE_WITHOUT_CUSTOMER_ACCOUNT]: Monetary
    [CartItemModel.ATTR_TAXED_TOTAL_PRICE_WITHOUT_CUSTOMER_ACCOUNT]: Monetary
    [CartItemModel.ATTR_TOTAL_PRICE_ONLY_WITHOUT_CUSTOMER_ACCOUNT]: Monetary
    [CartItemModel.ATTR_TAXED_TOTAL_PRICE_ONLY_WITHOUT_CUSTOMER_ACCOUNT]: Monetary
    [CartItemModel.ATTR_TOTAL_PRICE_ONLY_WITH_CUSTOMER_ACCOUNT]: Monetary
    [CartItemModel.ATTR_TAXED_TOTAL_PRICE_ONLY_WITH_CUSTOMER_ACCOUNT]: Monetary
}

interface Embeds {
    [CartItemModel.EMBED_VARIATION_PROPERTIES_NAMES]?: Record<string, string> | null
    [CartItemModel.EMBED_BRAND]?: Brand
    [CartItemModel.EMBED_DEFAULT_CATEGORY]?: Category
    [CartItemModel.EMBED_PRODUCT_AVAILABILITY]?: ProductAvailabilityModel
    [CartItemModel.EMBED_STOCK_STATES]?: StockState[]
    [CartItemModel.EMBED_PRODUCT]?: ProductModel
    [CartItemModel.EMBED_DISCOUNT_PERCENTS]?: number | null
    [CartItemModel.EMBED_PRODUCT_VARIATION]?: ProductModel
    [CartItemModel.EMBED_IMAGE_URL]?: string | null
    [CartItemModel.EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS]?: string | null
    [CartItemModel.EMBED_PRODUCT_ADDITIONAL_DATA]?: ProductAdditionalData
    [CartItemModel.EMBED_EXPORT_PRODUCT_IDS]?: ExportProductIds
    [CartItemModel.EMBED_CUSTOMER_ACCOUNT]?: CustomerAccountModel
}

export class CartItemModel extends ApiModel<Attributes, Embeds> {
    static key = 'CartItemModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PRODUCT_VARIATION_ID = 'product_variation_id'
    static readonly ATTR_GIFT_ID = 'gift_id'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_AMOUNT_WITHOUT_CUSTOMER_ACCOUNT = 'amount_without_customer_account'
    static readonly ATTR_ITEM_TYPE = 'item_type'
    static readonly ATTR_PARENT_ID = 'parent_id'
    static readonly ATTR_PAYLOAD = 'payload'
    static readonly ATTR_UNIT_PRICE = 'unit_price'
    static readonly ATTR_TAXED_UNIT_PRICE = 'taxed_unit_price'
    static readonly ATTR_ORIGINAL_UNIT_PRICE = 'original_unit_price'
    static readonly ATTR_TAXED_ORIGINAL_UNIT_PRICE = 'taxed_original_unit_price'
    static readonly ATTR_TOTAL_PRICE = 'total_price'
    static readonly ATTR_TAXED_TOTAL_PRICE = 'taxed_total_price'
    static readonly ATTR_TAX_AMOUNT = 'tax_amount'
    static readonly ATTR_PRODUCT_URLS = 'product_urls'
    static readonly ATTR_UPDATE_STOCK = 'update_stock'
    static readonly ATTR_STOCK_STATE = 'stock_state'
    static readonly ATTR_WEIGHT = 'weight'
    static readonly ATTR_CUSTOMER_ACCOUNT_ID = 'customer_account_id'
    static readonly ATTR_CUSTOMER_ACCOUNT_AMOUNTS = 'customer_account_amounts'
    static readonly ATTR_CUSTOMER_ACCOUNT_AMOUNTS_PER_UNIT = 'customer_account_amounts_per_unit'
    static readonly ATTR_TOTAL_PRICE_WITHOUT_CUSTOMER_ACCOUNT = 'total_price_without_customer_account'
    static readonly ATTR_TAXED_TOTAL_PRICE_WITHOUT_CUSTOMER_ACCOUNT = 'taxed_total_price_without_customer_account'
    static readonly ATTR_TOTAL_PRICE_ONLY_WITHOUT_CUSTOMER_ACCOUNT = 'total_price_only_without_customer_account'
    static readonly ATTR_TAXED_TOTAL_PRICE_ONLY_WITHOUT_CUSTOMER_ACCOUNT = 'taxed_total_price_only_without_customer_account'
    static readonly ATTR_TOTAL_PRICE_ONLY_WITH_CUSTOMER_ACCOUNT = 'total_price_only_with_customer_account'
    static readonly ATTR_TAXED_TOTAL_PRICE_ONLY_WITH_CUSTOMER_ACCOUNT = 'taxed_total_price_only_with_customer_account'

    static readonly EMBED_VARIATION_PROPERTIES_NAMES = 'variation_properties_names'
    static readonly EMBED_BRAND = 'brand'
    static readonly EMBED_DEFAULT_CATEGORY = 'default_category'
    static readonly EMBED_PRODUCT_AVAILABILITY = 'product_availability'
    static readonly EMBED_STOCK_STATES = 'stock_states'
    static readonly EMBED_PRODUCT = 'product'
    static readonly EMBED_DISCOUNT_PERCENTS = 'discount_percents'
    static readonly EMBED_PRODUCT_VARIATION = 'product_variation'
    static readonly EMBED_IMAGE_URL = 'image_url'
    static readonly EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS = 'product_amount_unit_translations'
    static readonly EMBED_PRODUCT_ADDITIONAL_DATA = 'product_additional_data'
    static readonly EMBED_EXPORT_PRODUCT_IDS = 'export_product_ids'
    static readonly EMBED_CUSTOMER_ACCOUNT = 'customer_account'

    get id() {
        return this._getAttribute(CartItemModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CartItemModel.ATTR_NAME)
    }

    get imageId() {
        return this._getAttribute(CartItemModel.ATTR_IMAGE_ID)
    }

    get productId() {
        return this._getAttribute(CartItemModel.ATTR_PRODUCT_ID)
    }

    get productVariationId() {
        return this._getAttribute(CartItemModel.ATTR_PRODUCT_VARIATION_ID)
    }

    get giftId() {
        return this._getAttribute(CartItemModel.ATTR_GIFT_ID)
    }

    get amount() {
        return this._getAttribute(CartItemModel.ATTR_AMOUNT)
    }

    get amountWithoutCustomerAccount() {
        return this._getAttribute(CartItemModel.ATTR_AMOUNT_WITHOUT_CUSTOMER_ACCOUNT)
    }

    get itemType() {
        return this._getAttribute(CartItemModel.ATTR_ITEM_TYPE)
    }

    get parentId() {
        return this._getAttribute(CartItemModel.ATTR_PARENT_ID)
    }

    get payload() {
        return this._getAttribute(CartItemModel.ATTR_PAYLOAD)
    }

    get unitPrice() {
        return this._getAttribute(CartItemModel.ATTR_UNIT_PRICE)
    }

    get taxedUnitPrice() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_UNIT_PRICE)
    }

    get originalUnitPrice() {
        return this._getAttribute(CartItemModel.ATTR_ORIGINAL_UNIT_PRICE)
    }

    get taxedOriginalUnitPrice() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_ORIGINAL_UNIT_PRICE)
    }

    /**
     * DO NOT USE DIRECTLY.
     * @todo create a getPrice method for this
     * @private
     */
    get totalPrice() {
        return this._getAttribute(CartItemModel.ATTR_TOTAL_PRICE)
    }

    /**
     * DO NOT USE DIRECTLY.
     * @todo create a getPrice method for this
     * @private
     */
    get taxedTotalPrice() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_TOTAL_PRICE)
    }

    get taxAmount() {
        return this._getAttribute(CartItemModel.ATTR_TAX_AMOUNT)
    }

    get productUrls() {
        return this._getAttribute(CartItemModel.ATTR_PRODUCT_URLS)
    }

    get updateStock() {
        return this._getAttribute(CartItemModel.ATTR_UPDATE_STOCK)
    }

    get stockState() {
        return this._getAttribute(CartItemModel.ATTR_STOCK_STATE)
    }

    get weight() {
        return this._getAttribute(CartItemModel.ATTR_WEIGHT)
    }

    get customerAccountId() {
        return this._getAttribute(CartItemModel.ATTR_CUSTOMER_ACCOUNT_ID)
    }

    get customerAccountAmounts() {
        return this._getAttribute(CartItemModel.ATTR_CUSTOMER_ACCOUNT_AMOUNTS)
    }

    private get customerAccountAmountsPerUnit() {
        return this._getAttribute(CartItemModel.ATTR_CUSTOMER_ACCOUNT_AMOUNTS_PER_UNIT)
    }

    private get totalPriceWithoutCustomerAccount() {
        return this._getAttribute(CartItemModel.ATTR_TOTAL_PRICE_WITHOUT_CUSTOMER_ACCOUNT)
    }

    private get taxedTotalPriceWithoutCustomerAccount() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_TOTAL_PRICE_WITHOUT_CUSTOMER_ACCOUNT)
    }

    private get totalPriceOnlyWithoutCustomerAccount() {
        return this._getAttribute(CartItemModel.ATTR_TOTAL_PRICE_ONLY_WITHOUT_CUSTOMER_ACCOUNT)
    }

    private get taxedTotalPriceOnlyWithoutCustomerAccount() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_TOTAL_PRICE_ONLY_WITHOUT_CUSTOMER_ACCOUNT)
    }

    private get totalPriceOnlyWithCustomerAccount() {
        return this._getAttribute(CartItemModel.ATTR_TOTAL_PRICE_ONLY_WITH_CUSTOMER_ACCOUNT)
    }

    private get taxedTotalPriceOnlyWithCustomerAccount() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_TOTAL_PRICE_ONLY_WITH_CUSTOMER_ACCOUNT)
    }

    private get variationPropertiesNames() {
        return this._getEmbed(CartItemModel.EMBED_VARIATION_PROPERTIES_NAMES)
    }

    get brand() {
        return this._getEmbed(CartItemModel.EMBED_BRAND)
    }

    get defaultCategory() {
        return this._getEmbed(CartItemModel.EMBED_DEFAULT_CATEGORY)
    }

    private get productAvailability() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_AVAILABILITY, ProductAvailabilityModel)
    }

    get stockStates() {
        return this._getEmbed(CartItemModel.EMBED_STOCK_STATES)
    }

    get product() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT, ProductModel)
    }

    get discountPercents() {
        return this._getEmbed(CartItemModel.EMBED_DISCOUNT_PERCENTS)
    }

    get productVariation() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_VARIATION)
    }

    get imageUrl() {
        return this._getEmbed(CartItemModel.EMBED_IMAGE_URL)
    }

    get productAmountUnitTranslations() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS)
    }

    get productAdditionalData() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_ADDITIONAL_DATA)
    }

    get exportProductIds() {
        return this._getEmbed(CartItemModel.EMBED_EXPORT_PRODUCT_IDS)
    }

    get customerAccount() {
        return this._getEmbed(CartItemModel.EMBED_CUSTOMER_ACCOUNT, CustomerAccountModel)
    }

    // ---------------------------------------------------------------------------------------------------------------------

    getTotalPrice(options?: Partial<GetCartItemPriceOptions>): Monetary | null {
        if (options?.type === 'only-normal') {
            return (options?.tax !== false
                ? this.taxedTotalPriceOnlyWithoutCustomerAccount
                : this.totalPriceOnlyWithoutCustomerAccount) ?? null
        }

        if (options?.type === 'only-credits') {
            return (options?.tax !== false
                ? this.taxedTotalPriceOnlyWithCustomerAccount
                : this.totalPriceOnlyWithCustomerAccount) ?? null
        }

        if (options?.type === 'without-credits') {
            return (options?.tax !== false
                ? this.taxedTotalPriceWithoutCustomerAccount
                : this.totalPriceWithoutCustomerAccount) ?? null
        }

        return (options?.tax !== false
            ? this.taxedTotalPrice
            : this.totalPrice) ?? null
    }

    getTotalFormattedPrice(options?: Partial<GetCartItemPriceOptions>): string | null {
        return getFormattedPrice(this.getTotalPrice(options))
    }

    isDiscounted() {
        return getPriceValue(this.taxedOriginalUnitPrice)! > getPriceValue(this.taxedUnitPrice)!
    }

    hasVariationProperties() {
        return this.productVariationId && this.variationPropertiesNames
    }

    /**
     * Whether the product is available or not.
     * Being available doesn't necessarily mean that the product can be added to the cart & purchased.
     * To determine that, use `canBePurchased()`.
     *
     * This doesn't take into account the stock state.
     * @see canBePurchased
     */
    isAvailable() {
        return this.productAvailability?.canPurchase ?? true
    }

    /**
     * Whether the product can be **purchased**.
     *
     * !! Note !!
     * This method is different from `isAvailable()` which only
     * returns the state of the product availability without checking the stock state.
     */
    canBePurchased() {
        const hasStock = this.stockState === null || this.stockState > 0
        return this.isAvailable() && (hasStock || !this.updateStock)
    }

    getFormattedStockCount(): string | null
    getFormattedStockCount(options: Partial<GetFormattedStockCountOptions>): string | null
    getFormattedStockCount(arg1?: Partial<GetFormattedStockCountOptions>): string | null {
        const options = arg1 ?? {}

        const stockCount = this.stockState
        const updateStock = this.updateStock

        if (!updateStock) return null
        if (stockCount === null || options.min !== undefined && options.outsideRangeFormat === 'hide-less' && stockCount < options.min) {
            return null
        }

        const stockCountToRender = options.max
            ? `${Math.min(stockCount, options.max)}${options.outsideRangeFormat === undefined || options.outsideRangeFormat !== 'hide' && options.max < stockCount ? '+' : ''}`
            : stockCount

        return (options?.min === undefined || stockCount >= options?.min) && (options?.max === undefined || stockCount <= options.max) || options?.outsideRangeFormat !== 'hide'
            ? `${stockCountToRender} ${this.productAmountUnitTranslations}`
            : null
    }

    /**
     * Get an objet with the variation **property names** as keys and their **selected attribute names** as values.
     * @example
     * {
     *     'Color': 'red',
     * }
     */
    getValuesOfVariationProperties() {
        return this.variationPropertiesNames ?? {}
    }

    isGift() {
        return this.itemType === CartItemType.GIFT && this.parentId !== null
    }

    isOrderGift() {
        return this.itemType === CartItemType.GIFT && !this.parentId
    }

    /**
     * A wrapper method for compatibility with `ProductModel`.
     */
    getDiscountPercents() {
        return this.discountPercents
    }

    getFormattedDiscountPercents() {
        return `${this.discountPercents} %`
    }

    getFormattedPrice() {
        return getFormattedPrice(this.taxedOriginalUnitPrice)
    }

    getFormattedDiscountedPrice() {
        return getFormattedPrice(this.taxedUnitPrice)
    }

    /**
     * Get the URL of the product.
     * @todo Implement handling for the case when the embed is a translation object
     */
    getUrl() {
        return typeof this.productUrls === 'string' ? this.productUrls : ''
    }

    getAvailabilityLabel() {
        return this.productAvailability?.name ?? null
    }

    /**
     * Whether the product has its availability specified.
     */
    hasAvailability(): boolean {
        return !!this.productAvailability
    }

}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

interface Weight {
    unit: string
    value: number
}

interface Payload {
    packaging?: string
}

interface Brand {
    id: number
    name: string | null
    description: string | null
    image_id: number | null
    meta_title: string | null
    meta_description: string | null
    slug: string | null
    is_active: boolean
    has_automatic_slug: boolean
    meta_index: boolean
    meta_follow: boolean

    show_on_sitemap: boolean
    created_at: ApiDateTime
}

interface Category {
    id: number
    name: string | null
    description: string | null
    parent_id: number | null
    image_id: number | null
    thumbnail_image_id: number | null
    meta_title: string | null
    meta_description: string | null
    slug: string | null
    position: number
    available_for_languages: string[]
    is_active: boolean
    has_automatic_slug: boolean
    meta_index: boolean
    meta_follow: boolean
    show_on_sitemap: boolean
    customer_groups_restriction: boolean
    created_ad: ApiDateTime
}

interface StockState {
    stock_id: number
    state: number
}

interface ProductAdditionalData {} // TODO: add props

interface ExportProductIds {
    'zbozi'?: string
    'google-merchant'?: string
    'heureka'?: string
    'glami'?: string
}

type GetCartItemPriceOptions = {
    /**
     * - `without-credits`: The original price of the item without applying any credits to it.
     * - `final`: The final price of the item that the customer will pay. This includes credits applied to it.
     * - `only-normal`: The price of only the items that do not have any credits applied to them.
     * - `only-credits`: The price of only the items that have credits applied to them.
     *
     * @default 'final'
     */
    type: 'final' | 'without-credits' | 'only-normal' | 'only-credits'
} & GetPriceOptions
