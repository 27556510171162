import { ApiModel } from '@composable-api/api.model'
import type { Monetary } from '../types/general-data'

interface Attributes {
    [CustomerAccountModel.ATTR_CUSTOMER_ID]: number
    [CustomerAccountModel.ATTR_CUSTOMER_ACCOUNT_ID]: number
    [CustomerAccountModel.ATTR_AMOUNT]: number
    [CustomerAccountModel.ATTR_CONVERSION_VALUE_WHOLE_ACCOUNT]: Monetary
}

interface Embeds {
}

export class CustomerAccountModel extends ApiModel<Attributes, Embeds> {
    static key = 'CustomerAccountModel'

    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_CUSTOMER_ACCOUNT_ID = 'customer_account_id'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_CONVERSION_VALUE_WHOLE_ACCOUNT = 'conversion_value_whole_account'

    get customerId() {
        return this._getAttribute(CustomerAccountModel.ATTR_CUSTOMER_ID)
    }

    get customerAccountId() {
        return this._getAttribute(CustomerAccountModel.ATTR_CUSTOMER_ACCOUNT_ID)
    }

    get amount() {
        return this._getAttribute(CustomerAccountModel.ATTR_AMOUNT)
    }

    get conversionValueWholeAccount() {
        return this._getAttribute(CustomerAccountModel.ATTR_CONVERSION_VALUE_WHOLE_ACCOUNT)
    }
}
