import payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA from "/opt/simploshop/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import model_serialization_nhiuarAbukdlNH4lCqD_fcx2_H_SQRVQZQte_RzZkAg from "/opt/simploshop/shared/modules/simploshop-api/src/runtime/plugins/model-serialization.ts";
import response_serialization_nGyAdtlrxzfWgoY2CxU4lBaJAJDDLuFR7c74oAGzLCM from "/opt/simploshop/shared/modules/simploshop-api/src/runtime/plugins/response-serialization.ts";
import api_response_error_serialization_SF19VCefYSjC_yBQm7XaFPF9w2zT7yJe5v1MxOcnaR8 from "/opt/simploshop/shared/modules/composable-api/src/runtime/plugins/api-response-error-serialization.ts";
import model_serialization_V4iO2vXIqbKUkcSzxK4owXY7n9MC1syv_YzFCMzJ5L4 from "/opt/simploshop/themes/vut/app/plugins/model-serialization.ts";
import response_serialization_g1SHAk3P1W5BhzOb15nAbClEOMJsUIEALL0GAknTqH0 from "/opt/simploshop/themes/vut/app/plugins/response-serialization.ts";
import revive_payload_client_5ydUw2BrGNT9TcBmVqBObLxqICOXOxv46lnwNoeQFpA from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WEt_alGUO0JAdQybvlR7liNVZZ4Wp16PqovadaPBH7s from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_y9_lMP1PK2G_QQQuSy7C0y4LB6tGDm2jYaspDG9T8fE from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import lite_youtube_client_O1hALQJMnzF4NlTsO55Fo8bvlr4Vw6h8j6sJLC9iEzM from "/opt/simploshop/shared/core-simploshop/app/plugins/lite-youtube.client.ts";
import v_visibility_YmMa_YJrRkX_0HoF_ccd_w5SfwkArbJpBMKAKA0S_7E from "/opt/simploshop/shared/core-simploshop/app/plugins/v-visibility.ts";
import payload_client_8oRfmG3Qxm09LjXogn3NPGs6axpYJbT1tEMDLX_XzEo from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_AAvS0wkmROPXQo8vlxzSM5mfzB4VcgzlBVlEWqW9Ba4 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_li3hIweHKToQpsxb8UFYVHm98oO444bRWpfVJe0YKJY from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_T5WcFDad1_IicJtAtOWxuGp7GurE4oEa2PG_oakOeWg from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8 from "/opt/simploshop/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/simploshop/themes/vut/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_mwOpqGyoOZeaJj9HueMszusRPh_y4pEOAR06k_iCwMs from "/opt/simploshop/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_c5bef4f22b34b7a19e17769563c6420c/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_S5GDQ34zbv5sWBO_RS4He38FgsBHxhSN4Qp_eLmd5Mg from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_eeEQbkdi8npz662YlCNsOKQ_QuLxCB94qbu9rdHcxsU from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_XN8PDVGmb_QUaTSDdCgmsHQPgtGbGNcGx6ySn6vg2dI from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_Y_F7yfs1SNVL8PMTCRJ0dBo7cscd4LStO3p4KB0cJdk from "/opt/simploshop/shared/core-theme/app/plugins/prod/sentry.client.ts";
import auth_client_mb94YNhPqi2gG7V_5MK0at6y7a43QGO5vlwameK2xKQ from "/opt/simploshop/shared/core-theme/app/plugins/auth.client.ts";
import cart_client_9YC3t_nnqr3Ig_orAw1Lb3dHig6igEDdt5EFziPlE24 from "/opt/simploshop/shared/core-theme/app/plugins/cart.client.ts";
import global_events_client_mYGM3eHYuCVkMeluCaLmVAhAGtkHfGT9HY8pzWRV0Bk from "/opt/simploshop/shared/core-theme/app/plugins/global-events.client.ts";
import model_customization__4VBVqpOCSyuUXl8yWI5zc_KCeOqmiJuZR9xIVFyPPY from "/opt/simploshop/shared/core-theme/app/plugins/model-customization.ts";
import properties_4hTXtoPWexv5r7_EKv2nEmrTm2gp7kzPYqLM2ZaDcjk from "/opt/simploshop/shared/core-theme/app/plugins/properties.ts";
import routing_MFJmZ0kMUAJHQAi5UJtJ7d3_14dMxNHCosG1gFbDnmw from "/opt/simploshop/shared/core-theme/app/plugins/routing.ts";
import setup_JUNNXbBaq4dj5oAsq91klWhMcXK62VNsEy8P6rbdBxg from "/opt/simploshop/shared/core-theme/app/plugins/setup.ts";
import ssg_detect_KqnQ5ki9TFSnk2uIdTZMIUB_n9AttgIKSbc_wVpNmFQ from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA,
  model_serialization_nhiuarAbukdlNH4lCqD_fcx2_H_SQRVQZQte_RzZkAg,
  response_serialization_nGyAdtlrxzfWgoY2CxU4lBaJAJDDLuFR7c74oAGzLCM,
  api_response_error_serialization_SF19VCefYSjC_yBQm7XaFPF9w2zT7yJe5v1MxOcnaR8,
  model_serialization_V4iO2vXIqbKUkcSzxK4owXY7n9MC1syv_YzFCMzJ5L4,
  response_serialization_g1SHAk3P1W5BhzOb15nAbClEOMJsUIEALL0GAknTqH0,
  revive_payload_client_5ydUw2BrGNT9TcBmVqBObLxqICOXOxv46lnwNoeQFpA,
  unhead_WEt_alGUO0JAdQybvlR7liNVZZ4Wp16PqovadaPBH7s,
  router_y9_lMP1PK2G_QQQuSy7C0y4LB6tGDm2jYaspDG9T8fE,
  lite_youtube_client_O1hALQJMnzF4NlTsO55Fo8bvlr4Vw6h8j6sJLC9iEzM,
  v_visibility_YmMa_YJrRkX_0HoF_ccd_w5SfwkArbJpBMKAKA0S_7E,
  payload_client_8oRfmG3Qxm09LjXogn3NPGs6axpYJbT1tEMDLX_XzEo,
  navigation_repaint_client_AAvS0wkmROPXQo8vlxzSM5mfzB4VcgzlBVlEWqW9Ba4,
  check_outdated_build_client_li3hIweHKToQpsxb8UFYVHm98oO444bRWpfVJe0YKJY,
  chunk_reload_client_T5WcFDad1_IicJtAtOWxuGp7GurE4oEa2PG_oakOeWg,
  plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_mwOpqGyoOZeaJj9HueMszusRPh_y4pEOAR06k_iCwMs,
  switch_locale_path_ssr_S5GDQ34zbv5sWBO_RS4He38FgsBHxhSN4Qp_eLmd5Mg,
  route_locale_detect_eeEQbkdi8npz662YlCNsOKQ_QuLxCB94qbu9rdHcxsU,
  i18n_XN8PDVGmb_QUaTSDdCgmsHQPgtGbGNcGx6ySn6vg2dI,
  sentry_client_Y_F7yfs1SNVL8PMTCRJ0dBo7cscd4LStO3p4KB0cJdk,
  auth_client_mb94YNhPqi2gG7V_5MK0at6y7a43QGO5vlwameK2xKQ,
  cart_client_9YC3t_nnqr3Ig_orAw1Lb3dHig6igEDdt5EFziPlE24,
  global_events_client_mYGM3eHYuCVkMeluCaLmVAhAGtkHfGT9HY8pzWRV0Bk,
  model_customization__4VBVqpOCSyuUXl8yWI5zc_KCeOqmiJuZR9xIVFyPPY,
  properties_4hTXtoPWexv5r7_EKv2nEmrTm2gp7kzPYqLM2ZaDcjk,
  routing_MFJmZ0kMUAJHQAi5UJtJ7d3_14dMxNHCosG1gFbDnmw,
  setup_JUNNXbBaq4dj5oAsq91klWhMcXK62VNsEy8P6rbdBxg,
  ssg_detect_KqnQ5ki9TFSnk2uIdTZMIUB_n9AttgIKSbc_wVpNmFQ
]