<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 17 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.0037 7.532C17.0037 8.396 16.9197 9.128 16.7517 9.728C16.6077 10.328 16.3917 10.844 16.1037 11.276C15.8397 11.684 15.5277 12.044 15.1677 12.356C14.8317 12.644 14.4837 12.92 14.1237 13.184C13.4517 13.64 12.9117 14.06 12.5037 14.444C12.0957 14.804 11.7717 15.152 11.5317 15.488C11.3157 15.8 11.1597 16.112 11.0637 16.424C10.9917 16.736 10.9557 17.072 10.9557 17.432V19.232H6.63567V17.468C6.63567 16.748 6.68367 16.112 6.77967 15.56C6.89967 15.008 7.09167 14.504 7.35567 14.048C7.64367 13.568 8.01567 13.112 8.47167 12.68C8.95167 12.224 9.53967 11.732 10.2357 11.204C10.5957 10.94 10.9197 10.7 11.2077 10.484C11.5197 10.268 11.7837 10.04 11.9997 9.8C12.2157 9.536 12.3837 9.236 12.5037 8.9C12.6237 8.564 12.6837 8.144 12.6837 7.64V6.704C12.6837 6.056 12.4797 5.54 12.0717 5.156C11.6877 4.748 11.1717 4.544 10.5237 4.544H7.10367C6.45567 4.544 5.92767 4.748 5.51967 5.156C5.13567 5.54 4.94367 6.056 4.94367 6.704V8.468H0.623672V6.92C0.623672 6.008 0.767672 5.18 1.05567 4.436C1.36767 3.692 1.78767 3.056 2.31567 2.528C2.86767 1.976 3.51567 1.556 4.25967 1.268C5.00367 0.955998 5.83167 0.799998 6.74367 0.799998H10.8837C11.7957 0.799998 12.6237 0.955998 13.3677 1.268C14.1117 1.556 14.7477 1.976 15.2757 2.528C15.8277 3.056 16.2477 3.692 16.5357 4.436C16.8477 5.18 17.0037 6.008 17.0037 6.92V7.532ZM6.63567 21.68H10.9557V26H6.63567V21.68Z"
        />
    </svg>
)
</script>
