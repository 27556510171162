import { BrandModel } from './brand.model'

interface Attributes {
    [BrandTreeModel.ATTR_CHILDREN]: BrandTreeModel[]
}

export class BrandTreeModel extends BrandModel<Attributes> {
    static override key = 'BrandTreeModel'

    static readonly ATTR_CHILDREN = '_children'

    get children() {
        return this._getAttribute(BrandTreeModel.ATTR_CHILDREN, BrandTreeModel)
    }
}
