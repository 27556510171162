/**
 * Call a function after all the top-level async data in a page has been resolved.
 * This includes child components and nested routes.
 *
 * By default, the callback is called only once and then the hook is unhooked.
 *
 * ### Options
 * - `once` - if set to `false`, the callback will be called on every page navigation.
 *            Default is `true`.
 *
 * !!! WARNING !!!
 * The hook is not unhooked automatically when not used in the top level of
 * a component's script setup block and the `once` option is set to `false`. Make sure to unhook it manually.
 *
 * @param callback the function to call after the page is loaded
 * @param options the options for the composable
 */
export default function useOnPageLoaded(callback: () => any, options?: { once?: boolean, server?: boolean }) {
    if (import.meta.server && options?.server) {
        callback()
        return
    }

    const nuxtApp = useNuxtApp()

    const unhook = nuxtApp.hook('page:finish', () => {
        callback()

        // automatically unhook if the `once` option is not specifically disabled or if it's a server render (SSR)
        // to prevent memory leaks
        if (options?.once === undefined || options.once || import.meta.server) {
            unhook()
        }
    })

    // check if it was used in component context
    const componentInstance = getCurrentInstance()

    // if called in component context, unhook on unmount
    if (componentInstance) {
        onUnmounted(() => {
            unhook()
        }, componentInstance)
    }

    return unhook
}

/**
 * This composable returns a function that can be used to call a function
 * after all the top-level async data in a page has been resolved.
 *
 * @example
 * const callOnPageLoaded = useCallOnPageLoaded()
 *
 * callOnPageLoaded(() => {
 *  console.log('Page loaded!')
 * })
 */
export function useCallOnPageLoaded() {
    const nuxtApp = useNuxtApp()

    return (callback: () => any) => {
        const unhook = nuxtApp.hook('page:finish', () => {
            callback()
            unhook()
        })
    }
}
