import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [CsobPaymentModel.ATTR_GATEWAY_URI]: string
}

export class CsobPaymentModel extends ApiModel<Attributes> {
    static key = 'CsobPaymentModel'

    static readonly ATTR_GATEWAY_URI = 'gateway_uri'

    get gatewayUri() {
        return this._getAttribute(CsobPaymentModel.ATTR_GATEWAY_URI)
    }
}
