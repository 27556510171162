import type { ApiModel } from '@composable-api/api.model'
import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'

class CustomersPasswordsApiService<M extends ApiModel> extends ApiService<M> {
    put(data: {
        password: string
        password_confirmation: string
        password_old: string
    }, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'PUT',
            body: data,
        })
    }
}

/**
 * A service used to change the password of a customer.
 */
export function getCustomersPasswordsApiService(options: {
    customerId: number | null | undefined
}) {
    return new CustomersPasswordsApiService({
        endpoint: `/customers/${options.customerId}/password`,
    }, null)
}
