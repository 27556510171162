<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg fill="currentColor" viewBox="0 0 29 22" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.5455 21.5637L0.825195 11.9804L4.4502 8.46647L10.5455 14.2165L24.5525 0.799805L28.1775 4.31369L10.5455 21.5637Z"
        />
    </svg>
)
</script>
