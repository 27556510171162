import { ApiModel } from '@composable-api/api.model'
import { FormattedCurrencyModel } from './custom/formatted-currency.model'
import type { FormattedCurrency, Monetary } from '../types/general-data'

interface Attributes {
    [PaymentMethodModel.ATTR_ID]: number
    [PaymentMethodModel.ATTR_NAME]: string | null
    [PaymentMethodModel.ATTR_DESCRIPTION]: string | null
    [PaymentMethodModel.ATTR_IMAGE_ID]: number | null
    [PaymentMethodModel.ATTR_PRICE]: FormattedCurrency
    [PaymentMethodModel.ATTR_TAXED_PRICE]: FormattedCurrency
    [PaymentMethodModel.ATTR_PAYMENT_NOTE]: string | null
    [PaymentMethodModel.ATTR_IS_ONLINE]: boolean
    [PaymentMethodModel.ATTR_POSITION]: number
    [PaymentMethodModel.ATTR_IS_ACTIVE]: boolean
    [PaymentMethodModel.ATTR_PAYMENT_SERVICE]: number
    [PaymentMethodModel.ATTR_HAS_CUSTOMER_GROUPS]: boolean
    [PaymentMethodModel.ATTR_BANK_ACCOUNT]: string | null
    [PaymentMethodModel.ATTR_TAX_GROUP_ID]: number | null
    [PaymentMethodModel.ATTR_TAX_RATE]: Monetary
}

export class PaymentMethodModel extends ApiModel<Attributes> {
    static key = 'PaymentMethodModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_PAYMENT_NOTE = 'payment_note'
    static readonly ATTR_IS_ONLINE = 'is_online'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_PAYMENT_SERVICE = 'payment_service'
    static readonly ATTR_HAS_CUSTOMER_GROUPS = 'has_customer_groups'
    static readonly ATTR_BANK_ACCOUNT = 'bank_account'
    static readonly ATTR_TAX_GROUP_ID = 'tax_group_id'
    static readonly ATTR_TAX_RATE = 'tax_rate'

    get id() {
        return this._getAttribute(PaymentMethodModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(PaymentMethodModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(PaymentMethodModel.ATTR_DESCRIPTION)
    }

    get imageId() {
        return this._getAttribute(PaymentMethodModel.ATTR_IMAGE_ID)
    }

    get price() {
        return this._getAttribute(PaymentMethodModel.ATTR_PRICE, FormattedCurrencyModel)
    }

    get taxedPrice() {
        return this._getAttribute(PaymentMethodModel.ATTR_TAXED_PRICE, FormattedCurrencyModel)
    }

    get paymentNote() {
        return this._getAttribute(PaymentMethodModel.ATTR_PAYMENT_NOTE)
    }

    get isOnline() {
        return this._getAttribute(PaymentMethodModel.ATTR_IS_ONLINE)
    }

    get position() {
        return this._getAttribute(PaymentMethodModel.ATTR_POSITION)
    }

    get isActive() {
        return this._getAttribute(PaymentMethodModel.ATTR_IS_ACTIVE)
    }

    get paymentService() {
        return this._getAttribute(PaymentMethodModel.ATTR_PAYMENT_SERVICE)
    }

    get hasCustomerGroups() {
        return this._getAttribute(PaymentMethodModel.ATTR_HAS_CUSTOMER_GROUPS)
    }

    get bankAccount() {
        return this._getAttribute(PaymentMethodModel.ATTR_BANK_ACCOUNT)
    }

    get taxGroupId() {
        return this._getAttribute(PaymentMethodModel.ATTR_TAX_GROUP_ID)
    }

    get taxRate() {
        return this._getAttribute(PaymentMethodModel.ATTR_TAX_RATE)
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

