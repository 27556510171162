import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [CompanyInfoModel.ATTR_CITY]: string
    [CompanyInfoModel.ATTR_COUNTRY]: string
    [CompanyInfoModel.ATTR_COUNTRY_ISO]: string
    [CompanyInfoModel.ATTR_IDENTIFIER]: string
    [CompanyInfoModel.ATTR_NAME]: string
    [CompanyInfoModel.ATTR_POSTAL_CODE]: string
    [CompanyInfoModel.ATTR_STREET_ADDRESS]: string
    [CompanyInfoModel.ATTR_VAT_IDENTIFIER]: string
}

export class CompanyInfoModel extends ApiModel<Attributes> {
    static key = 'CompanyInfoModel'

    static readonly ATTR_CITY = 'city'
    static readonly ATTR_COUNTRY = 'country'
    static readonly ATTR_COUNTRY_ISO = 'country_iso'
    static readonly ATTR_IDENTIFIER = 'identifier'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_POSTAL_CODE = 'postal_code'
    static readonly ATTR_STREET_ADDRESS = 'street_address'
    static readonly ATTR_VAT_IDENTIFIER = 'vat_identifier'


    get city() {
        return this._getAttribute(CompanyInfoModel.ATTR_CITY)
    }
    get country() {
        return this._getAttribute(CompanyInfoModel.ATTR_COUNTRY)
    }
    get countryIso() {
        return this._getAttribute(CompanyInfoModel.ATTR_COUNTRY_ISO)
    }
    get identifier() {
        return this._getAttribute(CompanyInfoModel.ATTR_IDENTIFIER)
    }
    get name() {
        return this._getAttribute(CompanyInfoModel.ATTR_NAME)
    }
    get postalCode() {
        return this._getAttribute(CompanyInfoModel.ATTR_POSTAL_CODE)
    }
    get streetAddress() {
        return this._getAttribute(CompanyInfoModel.ATTR_STREET_ADDRESS)
    }
    get vatIdentifier() {
        return this._getAttribute(CompanyInfoModel.ATTR_VAT_IDENTIFIER)
    }
}
