<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="1.8">
        <path d="M14.615 1.886 1.387 15.114M1.387 1.886l13.228 13.228"
            stroke="currentColor"
            stroke-linecap="round"
        />
    </svg>
)
</script>
