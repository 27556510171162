export enum ProductVariationPropertyType {
    Select = 1,
    Radio = 2,
    Color = 3,
    TextInput = 4,
}

export enum ProductFilterType {
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    COLOR = 'color',
    PRICE_SLIDER = 'price_slider',
}

export enum ProductFilterTemplatePlaceholder {
    MIN_PRICE = 'MIN_PRICE',
    MAX_PRICE = 'MAX_PRICE',
    PAGE = 'PAGE',
    FROM = 'FROM',
    TO = 'TO',
    PER_PAGE = 'PER_PAGE',
}

export enum ProductRelationType {
    ALTERNATIVE = 0, // VARIANTS
    RELATED = 1, // RELATIVE
}

export enum ProductType {
    NORMAL = 0,
    PACKAGE = 1,
}

export enum GiftRuleTypeEnum {
    VARIABLES = 1,
    CATEGORIES = 2,
    BRANDS = 3,
    TOTAL_PRICE = 4,
    TOTAL_TAXED_PRICE = 5,
    ITEMS_PRICE = 6,
    ITEMS_TAXED_PRICE = 7,
    SHIPPING_PRICE = 8,
    SHIPPING_TAXED_PRICE = 9,
    PAYMENT_PRICE = 10,
    PAYMENT_TAXED_PRICE = 11,
}

export enum GiftRuleConditionEnum {
    ONE_OF = 1,
    NONE_OF = 2,
    LT = 3,
    LTE = 4,
    GT = 5,
    GTE = 6,
}

