import type { SlotOverride } from '@core-types/components'
import type { Slot, VNode } from 'vue'

/**
 * Get the attribute with the scope id of the component
 * so that scoped styles apply on the child component's elements correctly.
 *
 * Useful for <Teleport>ed content, for example.
 */
export function getScopeIdAttr() {
    const scopeId = getCurrentInstance()?.vnode.scopeId
    return scopeId
        ? {
            [scopeId]: '',
        }
        : {}
}

/**
 * Get the attribute with the scope id of the component
 * so that scoped styles apply on the child component's elements correctly.
 *
 * Useful for <Teleport>ed content, for example.
 */
export function getParentScopeIdAttr() {
    const scopeId = getCurrentInstance()?.parent?.vnode.scopeId
    return scopeId
        ? {
            [scopeId]: '',
        }
        : {}
}

export function getSlotScopeIdAttr() {
    // @ts-ignore
    const scopeId = getCurrentInstance()?.vnode?.type?.__scopeId
    return scopeId
        ? {
            [scopeId]: '',
        }
        : {}
}

/**
 * Normalize the return type of slot override.
 * Handles the case when the slot override doesn't return anything or returns a single VNode.
 *
 * The normalized value is safe to use as children of a VNode.
 * @param slotOverride The return value of the slot override.
 */
export function normalizeSlotValue(slotOverride: ReturnType<SlotOverride<any>>) {
    if (!slotOverride) return null
    return Array.isArray(slotOverride) ? slotOverride : [slotOverride]
}

/**
 * A utility function that is meant to be used in tsx to either render a slot or its default fallback content.
 * It is also possible to override the default slot content so that it can be specified for each component
 * defined via `defineComponent`
 * @param templateSlot the slot that will be provided when using the component through `<template>`
 * @param slotOverride the override for default slot data (when defining through custom `defineComponent` call)
 * @param slotData the data for the slot (refs, etc.)
 * @param fallback the default value for the slot (to be provided in the base component)
 */
export function renderSlot<D extends Slot, T extends SlotOverride<any>>(templateSlot: D | undefined, slotOverride: T | undefined, slotData: Parameters<D>[0], fallback: VNode | VNode[] | (() => string | null) | undefined = undefined): VNode[] | VNode | (() => string | null) | null {
    // the slot provided through <template>
    if (templateSlot) {
        return templateSlot(slotData)
    }

    // the overridden slot through custom defineComponent slotOverrides
    if (slotOverride) {
        return normalizeSlotValue(slotOverride(slotData))
    }

    // return fallback content
    return fallback ?? null
}

type RuntimeProp<T> = {
    type: PropType<T>
    required?: boolean
    default?: T extends (...args: any) => any ? (() => T) : T | undefined
}

type PropsDefinition<TProps extends Record<string, any>> = {
    [K in keyof Required<TProps>]: {
        /**
         * The runtime constructor of the prop type.
         */
        type: PropType<TProps[K]>
        /**
         * The default value for the `required` attribute of the prop.
         * (can be overridden by the factory function's options, it is only used as a fallback)
         * @default false
         */
        required?: boolean
        /**
         * The default value for the prop.
         * (can be overridden by the factory function's options, it is only used as a fallback)
         * @default undefined
         */
        default?: TProps[K] | undefined
    }
}

export type RuntimeProps<TProps> = { [K in keyof TProps]: RuntimeProp<TProps[K]> }

/**
 * A helper function for defining & reusing runtime prop declarations.
 * @param props The simplified prop declarations. In case the `options` are provided,
 *              the properties (`default`, `required`) will be overridden by the provided value.
 *              Only specify the options if you need to change the defaults, which are:
 *              `default: undefined`, `required: false`
 * @param options The `ComponentOverrideOptions` from the component factory function.
 */
export function defineRuntimeProps<TProps extends Record<string, any>>(props: PropsDefinition<TProps>, options?: ComponentOverrideOptions<any, TProps>): RuntimeProps<TProps> {
    const runtimeProps: { [K in keyof TProps]: RuntimeProp<TProps[K]> } = {} as any

    for (const key in props) {
        const prop = props[key]
        const defaultValue = options?.props?.[key]?.default ?? prop.default
        runtimeProps[key] = {
            type: prop.type,
            required: options?.props?.[key]?.required ?? prop.required ?? false,
            default: typeof defaultValue === 'function' ? () => defaultValue : defaultValue,
        } as RuntimeProp<TProps[Extract<keyof TProps, string>]>
    }

    return runtimeProps
}
