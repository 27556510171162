import { CartPhase } from '@core-theme/app/assets/ts/enums/cart'

export default defineAppConfig({
    cart: {
        phases: [
            {
                id: CartPhase.DETAIL,
                labelPath: 'cart.phases.cart',
                routeName: 'cart',
            },
            {
                id: CartPhase.SHIPPING_AND_PAYMENT,
                labelPath: 'cart.phases.shipping_and_payment',
                routeName: 'cart-shipping-and-payment',
            },
            {
                id: CartPhase.DELIVERY,
                labelPath: 'cart.phases.delivery',
                routeName: 'cart-delivery',
            },
            {
                id: CartPhase.SUMMARY,
                labelPath: 'cart.phases.summary',
                routeName: 'cart-summary',
            },
        ],
    },
    userMenu: {
        links: [
            {
                labelPath: 'pages.customer.account_info',
                routeName: 'customer-account',
            },
            {
                labelPath: 'pages.customer.orders',
                routeName: 'customer-orders',
            },
            {
                labelPath: 'pages.customer.favorite_products',
                routeName: 'customer-favorite-products',
            },
            {
                labelPath: 'pages.customer.complaint',
                routeName: 'customer-complaint',
            },
        ],
    },
    links: {
        socials: {
            facebook: 'https://www.facebook.com/VUTvBrne',
            x: 'https://x.com/VUTvBrne',
            youtube: 'https://www.youtube.com/VUTvBrne',
            instagram: 'https://www.instagram.com/vutvbrne/',
            threads: 'https://www.threads.net/@vutvbrne',
            linkedin: 'https://www.linkedin.com/school/vysoké-učení-technické-v-brně',
            spotify: 'https://open.spotify.com/show/1ZEmKuf1Uk9FNFUHsN61H5',
        },
    },
    dynamicPages: {
        category: {
            meta: {
                layout: 'bare',
            },
        },
        search: {
            meta: {
                layout: 'bare',
            },
        },
    },
    features: {
        auth: {
            autoLoginAfterRegistration: false,
        },
    },
})
