import { Suspense } from 'vue'

export type ExtractComponentProps<TComponent> =
  TComponent extends new () => {
      $props: infer P
  }
      ? P
      : never

export type ModalRenderEntry = {
    /**
     * The string ID of the modal.
     * This is unique for every modal instance, even if they share the same component.
     */
    id: string
    component: Component
    isOpen: Ref<boolean>
    props: Ref<Record<string, any>>
    onClosed: Ref<() => void>
}

interface ModalDependencyEntry {
    /**
     * The string ID of the modal.
     * This is unique for every modal instance, even if they share the same component.
     */
    id: string
    /**
     * The number of `useModals()` calls that are currently using this modal.
     */
    deps: number
    __isLocal?: boolean
}

export function _useMountedModals(): Ref<ModalRenderEntry[]> {
    // @ts-ignore - This shouldn't be used on the server side
    if (import.meta.server) return
    return useState<ModalRenderEntry[]>('_modals:mounted', () => shallowRef([]))
}

interface OpenModalOptions {
    /**
     * The mode of the promise returned by the function.
     * - `import` - The promise resolves when the modal component is imported.
     * - `close` - The promise resolves when the modal is closed.
     * - `none` - The promise resolves immediately.
     * @default 'import'
     */
    promise: 'import' | 'close' | 'none'
    /**
     * Whether to create a new instance of the modal for the current `useModals()` call or use one that is shared
     * between all components, if available.
     *
     * Shared instances will stay mounted in the DOM until there are no more components using them.
     * Isolated instances will stay mounted in the DOM until the current component is unmounted.
     *
     * It is also possible to use a one-time `disposable` instance, which will be removed from the DOM once it is closed.
     * @default 'shared'
     */
    instance: 'isolated' | 'shared' | 'disposable'
    /**
     * NOTE: only supported when `instance` is set to `isolated`.
     *
     * A unique key to identify the isolated modal instance within the current `useModals()` call.
     * The key is local to the component name and the current `useModals()` call.
     *
     * (e.g. components with different names will not be shared even if they have the same key)
     */
    key: string
}

interface UseModalsReturn {
    openModal: <T extends Component>(modal: T, props?: Omit<ExtractComponentProps<T>, 'modelValue'>, options?: Partial<OpenModalOptions>) => Promise<void>
}

/**
 * A composable for controlling modals programmatically.
 * For more information, see the returned `useModals()` function's documentation.
 *
 * IMPORTANT:
 * This composable needs the `<CoreModalManager />` component to be present in the root of the application.
 */
export function useModals(): UseModalsReturn {
    // @ts-ignore - This shouldn't be used on the server side
    if (import.meta.server) return {}

    const uniqueKey = useId()
    if (!uniqueKey) {
        throw new Error('[useModals]: No unique identifier could be generated. This composable needs to be only used in the setup function of a component.')
    }

    const renderedModals = _useMountedModals()
    const modalDependencies = useState('_modals:deps', () => new Map<string, ModalDependencyEntry>())

    let modalCnt = 0

    const localDependencies: { name: string, dep: ModalDependencyEntry }[] = []

    onUnmounted(() => {
        for (const entry of localDependencies) {
            entry.dep.deps--

            if (entry.dep.deps === 0) {
                if (!entry.dep.__isLocal) modalDependencies.value.delete(entry.name)
                renderedModals.value = renderedModals.value.filter(modal => modal.id !== entry.dep.id)
            } else if (entry.dep.deps < 0 && import.meta.dev) {
                errorLog(`[useModalManager]: Dependency count for modal \`${entry.dep.id}\` is negative. This is a bug.`, entry)
            }
        }
    })

    /**
     * A function to programmatically open a modal.
     * The provided component MUST have a boolean `v-model`, which controls the visibility of the modal.
     * The modal component also MUST emit an event `closed` when the modal is closed.
     *
     * It is recommended to use this function to open only the following components:
     * - `BaseModal`
     * - `BaseSideDrawer`
     *
     * @example open a lazy-loaded delete modal
     * ```ts
     * import { LazyModalDelete } from '#components'
     *
     * const { openModal } = useModals()
     *
     * function handleButtonClick() {
     *  return openModal(LazyModalDelete, {
     *    title: 'Delete account?',
     *    onSubmit: () => {
     *      // ...
     *    },
     *  })
     *  ```
     *
     *  @todo Add support for non-root element modals. This can be achieved by injecting the state instead of passing via props.
     */
    const openModal: UseModalsReturn['openModal'] = (modal, props, options) => {
        let componentName: string | undefined

        let resolvePromise: (val?: void) => void | undefined
        const openPromise = (['import', 'close', undefined] satisfies NonNullable<typeof options>['promise'][]).includes(options?.promise as any)
            ? new Promise<void>(resolve => {
                resolvePromise = resolve
            })
            : undefined

        async function end() {
            if (options?.promise === 'none') return
            if (openPromise) await openPromise
        }

        function resolveImport() {
            if (options?.promise === undefined || options.promise === 'import') {
                resolvePromise?.()
            }
        }

        /**
         * When 2 modals of the same component name are opened in parallel (or in quick succession before
         * the other one's import is resolved), 2 components will be mounted.
         * In that case, we need to remove the duplicate modal once it's closed.
         *
         * This is also used to remove a disposable modal from the rendered modals once it's closed.
         */
        let modalIdToDelete: string | undefined

        function handleModalClose() {
            if (modalIdToDelete) {
                renderedModals.value = renderedModals.value.filter(modal => modal.id !== modalIdToDelete)
                modalIdToDelete = undefined
            }

            resolvePromise?.()
        }

        if (options?.instance === undefined || options.instance === 'shared' || options.instance === 'isolated') {
            // @ts-expect-error - Vue internal property
            componentName = modal.__asyncResolved?.__name

            // if we have a component name, that means that its import should already be resolved
            // -------- handle reusing of modal instances --------
            if (componentName) {
                resolveImport()

                if (options?.instance === 'isolated') {
                    const componentNameToLookFor = options.key ? `${componentName}:${options.key}` : componentName
                    const localDep = localDependencies.find(entry => entry.name === componentNameToLookFor && entry.dep.__isLocal)
                    if (localDep) {
                        const renderedEntry = renderedModals.value.find(modal => modal.id === localDep.dep.id)
                        if (renderedEntry) {
                            // handle already opened modal
                            if (renderedEntry.isOpen.value) {
                                errorLog(`[useModalManager/openModal]: The isolated modal instance \`${componentName}\` is already open in another place.`, modal)
                                // TODO: discuss what to do here
                                //  - wait for the modal to close and then open it?
                                //  - close it and open it with new props?
                                //  - or just return like this?
                                return Promise.resolve()
                            }

                            // update the props and open the modal
                            renderedEntry.props.value = props || {}
                            renderedEntry.isOpen.value = true
                            renderedEntry.onClosed.value = handleModalClose
                            return end()
                        } else if (import.meta.dev) {
                            errorLog(`[useModalManager/openModal]: The modal \`${componentName}\` is in local dependencies, but not present in rendered modals. This is a bug, tracking will not work correctly and memory leaks may occur.`, modal)
                        }
                    }

                } else {
                    const dep = modalDependencies.value.get(componentName)
                    if (dep) {
                        const renderedEntry = renderedModals.value.find(modal => modal.id === dep.id)
                        if (renderedEntry) {
                            // handle already opened modal
                            if (renderedEntry.isOpen.value) {
                                errorLog(`[useModalManager/openModal]: The shared modal instance \`${componentName}\` is already open in another place.`, modal)
                                // TODO: discuss what to do here
                                //  - wait for the modal to close and then open it?
                                //  - close it and open it with new props?
                                //  - or just return like this?
                                return Promise.resolve()
                            }

                            // register the local dependency, if it's not already there
                            if (!localDependencies.some(entry => entry.name === componentName)) {
                                localDependencies.push({
                                    name: componentName,
                                    dep: dep,
                                })
                                // increment the global dependency count
                                dep.deps++
                            }

                            // update the props and open the modal
                            renderedEntry.props.value = props || {}
                            renderedEntry.isOpen.value = true
                            renderedEntry.onClosed.value = handleModalClose
                            return end()
                        } else if (import.meta.dev) {
                            errorLog(`[useModalManager/openModal]: The modal \`${componentName}\` is in dependencies, but not present in rendered modals. This is a bug, tracking will not work correctly and memory leaks may occur.`, modal)
                        }
                    }
                }
            }
        }

        // -------- no modal is currently rendered, handle model creation from scratch --------
        const modalId = `${uniqueKey}#${modalCnt++}`

        const isOpen = ref<boolean>(false)
        const reactiveProps = ref<Record<string, any>>(props || {})
        const onClosed = ref<() => void>(handleModalClose)

        // TODO: optimize this with triggerRef
        renderedModals.value = [...renderedModals.value, {
            id: modalId,
            component: markRaw(
                () =>  h(Suspense, {
                    onResolve: () => {
                        // TODO: this is a workaround for https://github.com/vuejs/core/issues/12435
                        nextTick(() => {
                            isOpen.value = true
                        })

                        resolveImport()

                        // if the modal is disposable, we don't save it globally
                        if (options?.instance === 'disposable') {
                            modalIdToDelete = modalId
                            return end()
                        }

                        // save the component name once it is available for the first time
                        // after the dynamic import resolves
                        // @ts-expect-error - Vue internal property
                        componentName = modal.__asyncResolved.__name

                        if (!componentName) {
                            if (import.meta.dev) {
                                errorLog(`[useModalManager/openModal]: Wanted a component name, but found: \`${componentName}\` when registering modal dependencies. Memory leaks will occur.`, modal)
                            }
                            return end()
                        }

                        // if the modal is shared only within the current instance, we don't save it globally
                        if (options?.instance === 'isolated') {
                            localDependencies.push({
                                name: options.key ? `${componentName}:${options.key}` : componentName,
                                dep: {
                                    id: modalId,
                                    deps: 1,
                                    __isLocal: true,
                                },
                            })
                            return end()
                        }

                        // set 1 to the deps if it's the first time the modal is opened
                        if (!modalDependencies.value.has(componentName)) {
                            modalDependencies.value.set(componentName, {
                                id: modalId,
                                deps: 1,
                            })

                            localDependencies.push({
                                name: componentName,
                                dep: modalDependencies.value.get(componentName)!,
                            })
                        }
                        // increment the deps if the modal is already mounted
                        else {
                            const modalDep = modalDependencies.value.get(componentName)!
                            modalDep.deps++

                            localDependencies.push({
                                name: componentName,
                                dep: modalDep,
                            })

                            // in some cases, it can happen that the saved global modal dependency is not the same
                            // as the one that was currently opened
                            // (if 2 modals with the same name were opened before the other one's import was resolved)
                            // in that case, we need to immediately remove the duplicate modal once it's closed
                            if (modalDep.id !== modalId) {
                                modalIdToDelete = modalId
                            }
                        }
                    },
                }, h(modal, {
                    ...reactiveProps.value,
                    'modelValue': isOpen.value,
                    'onUpdate:modelValue': (val: boolean) => {
                        isOpen.value = val
                    },
                    'onClosed': onClosed.value,
                }))
            ),
            isOpen: isOpen,
            props: reactiveProps,
            onClosed: onClosed,
        }]

        return end()
    }

    return {
        openModal,
    }
}
