import { getApiModelPayloadReducer, getApiModelPayloadReviver } from '@composable-api-utils/serialization'
import { ThemeAddressPredictionModel } from 'assets/models/theme-address-prediction.model'
import { ThemeAddressPredictionResponseModel } from 'assets/models/theme-address-prediction-response.model'

export const models = createClassesMap([
    ThemeAddressPredictionResponseModel,
    ThemeAddressPredictionModel,
])

export default definePayloadPlugin(() => {
    definePayloadReducer('theme-model', getApiModelPayloadReducer({ theme: true }))
    definePayloadReviver('theme-model', getApiModelPayloadReviver(models))
})
