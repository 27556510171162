<template>
    <component
        :is="modal.component"
        v-for="modal in renderedModals"
        :key="modal.id"
    />
</template>

<script lang="ts" setup>

const renderedModals = _useMountedModals()

</script>

<style lang="scss" scoped>

</style>
