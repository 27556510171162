
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93_H5D1F8hIK4AzF6RBvTQTkeU3yecRJ3sxVKolWgE5XgMeta } from "/opt/simploshop/shared/core-theme/app/pages/[...slug].vue?macro=true";
import { default as deliveryrSByC_45H2vYAC6_45ll_IBnE7vxXzV9h1fi6vrCZ9niapYMeta } from "/opt/simploshop/themes/vut/app/pages/cart/delivery.vue?macro=true";
import { default as indexwVLMz9plxBnnFxRecB4u2rsXuJdtq5j1PL0zetowuyUMeta } from "/opt/simploshop/themes/vut/app/pages/cart/index.vue?macro=true";
import { default as shipping_45and_45paymentIe7oJcFIM2S7WlSAXIqKjiIlY8ecGzn7YcAC2wabeXcMeta } from "/opt/simploshop/themes/vut/app/pages/cart/shipping-and-payment.vue?macro=true";
import { default as summarywFbdPsq0GogSnHM_45_YcbCHJvDoh6QIVjlVRhFw7MHi0Meta } from "/opt/simploshop/themes/vut/app/pages/cart/summary.vue?macro=true";
import { default as cart8RYv49llb94uKX9VmvMfGH7vC86IhAsI_75fjan8q2IMeta } from "/opt/simploshop/themes/vut/app/pages/cart.vue?macro=true";
import { default as contactx5S1EMDZtFZ5TgdlXzXAQrGk7YzKTL0Cbha0GSuD2_45kMeta } from "/opt/simploshop/themes/vut/app/pages/contact.vue?macro=true";
import { default as accountabA3lYLH2ulzVw7L1vKnTD0dm1I8IT76mjUCsgFclPUMeta } from "/opt/simploshop/themes/vut/app/pages/customer/account.vue?macro=true";
import { default as complaintoqVIoOD89wdbW2UaQozkec2TKrFiLI9V6YdYzyhIj9YMeta } from "/opt/simploshop/themes/vut/app/pages/customer/complaint.vue?macro=true";
import { default as favorite_45productsmsZlIqWhwr5MXiyEuGSJA4WF7m1LrlcQddiOiLQO_45lYMeta } from "/opt/simploshop/themes/vut/app/pages/customer/favorite-products.vue?macro=true";
import { default as _91id_93umn1VeikchApVkvUJ9Ana9s5PWN87yfZfWBAgfxZXbsMeta } from "/opt/simploshop/themes/vut/app/pages/customer/orders/[id].vue?macro=true";
import { default as index09zx10GHz56XR2yLvCwRTrwUP9ebImOF5fxZ808omZAMeta } from "/opt/simploshop/themes/vut/app/pages/customer/orders/index.vue?macro=true";
import { default as ordersf1oTrLd2J_KfsURUTZK11TTrcejyZXnMt_45xx3dW9JwwMeta } from "/opt/simploshop/themes/vut/app/pages/customer/orders.vue?macro=true";
import { default as customerw5sud18K_Z8SSRWgXWdujxI_45AeWlJI7rCjuWIFv9vKAMeta } from "/opt/simploshop/themes/vut/app/pages/customer.vue?macro=true";
import { default as indexKmUCJO9wWhO3Ll6YzIfkFfnhurSM9zLVR7usWFhWlnEMeta } from "/opt/simploshop/themes/vut/app/pages/index.vue?macro=true";
import { default as loginbAJLUQtlyu0E46VbrlR1JPfTFQ0EGNHTJjLa6EngTWAMeta } from "/opt/simploshop/themes/vut/app/pages/login.vue?macro=true";
import { default as password_45resetR889rZ0SRfpm7D3msy0NqS4YRMmz9jx39GeBM47SCWkMeta } from "/opt/simploshop/themes/vut/app/pages/password-reset.vue?macro=true";
import { default as register8qy17U16Mi5e1DUpbXtw_49S3WUOF7f79HU_45JPpgJoYMeta } from "/opt/simploshop/themes/vut/app/pages/register.vue?macro=true";
import { default as site_45mapBe7DMXxWuxPN5dMKO1upLBe3Wpnoiq_456kG8BYSDEBikMeta } from "/opt/simploshop/themes/vut/app/pages/site-map.vue?macro=true";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue")
  },
  {
    name: cart8RYv49llb94uKX9VmvMfGH7vC86IhAsI_75fjan8q2IMeta?.name,
    path: "/cart",
    meta: cart8RYv49llb94uKX9VmvMfGH7vC86IhAsI_75fjan8q2IMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/cart.vue"),
    children: [
  {
    name: "cart-delivery___cs",
    path: "/kosik/dorucovaci-udaje",
    meta: deliveryrSByC_45H2vYAC6_45ll_IBnE7vxXzV9h1fi6vrCZ9niapYMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/cart/delivery.vue")
  },
  {
    name: "cart___cs",
    path: "/kosik",
    component: () => import("/opt/simploshop/themes/vut/app/pages/cart/index.vue")
  },
  {
    name: "cart-shipping-and-payment___cs",
    path: "/kosik/doprava-a-platba",
    meta: shipping_45and_45paymentIe7oJcFIM2S7WlSAXIqKjiIlY8ecGzn7YcAC2wabeXcMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/cart/shipping-and-payment.vue")
  },
  {
    name: "cart-summary___cs",
    path: "summary",
    component: () => import("/opt/simploshop/themes/vut/app/pages/cart/summary.vue")
  }
]
  },
  {
    name: "contact___cs",
    path: "/kontakt",
    meta: contactx5S1EMDZtFZ5TgdlXzXAQrGk7YzKTL0Cbha0GSuD2_45kMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/contact.vue")
  },
  {
    name: "customer___cs",
    path: "/customer",
    meta: customerw5sud18K_Z8SSRWgXWdujxI_45AeWlJI7rCjuWIFv9vKAMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/customer.vue"),
    children: [
  {
    name: "customer-account___cs",
    path: "/zakaznik/informace-o-uctu",
    meta: accountabA3lYLH2ulzVw7L1vKnTD0dm1I8IT76mjUCsgFclPUMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/customer/account.vue")
  },
  {
    name: "customer-complaint___cs",
    path: "/zakaznik/reklamace",
    meta: complaintoqVIoOD89wdbW2UaQozkec2TKrFiLI9V6YdYzyhIj9YMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/customer/complaint.vue")
  },
  {
    name: "customer-favorite-products___cs",
    path: "/zakaznik/oblibene-produkty",
    meta: favorite_45productsmsZlIqWhwr5MXiyEuGSJA4WF7m1LrlcQddiOiLQO_45lYMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/customer/favorite-products.vue")
  },
  {
    name: ordersf1oTrLd2J_KfsURUTZK11TTrcejyZXnMt_45xx3dW9JwwMeta?.name,
    path: "/zakaznik/objednavky",
    meta: ordersf1oTrLd2J_KfsURUTZK11TTrcejyZXnMt_45xx3dW9JwwMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/customer/orders.vue"),
    children: [
  {
    name: "customer-orders-id___cs",
    path: ":id()",
    meta: _91id_93umn1VeikchApVkvUJ9Ana9s5PWN87yfZfWBAgfxZXbsMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/customer/orders/[id].vue")
  },
  {
    name: "customer-orders___cs",
    path: "",
    component: () => import("/opt/simploshop/themes/vut/app/pages/customer/orders/index.vue")
  }
]
  }
]
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexKmUCJO9wWhO3Ll6YzIfkFfnhurSM9zLVR7usWFhWlnEMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginbAJLUQtlyu0E46VbrlR1JPfTFQ0EGNHTJjLa6EngTWAMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/login.vue")
  },
  {
    name: "password-reset___cs",
    path: "/obnova-hesla",
    meta: password_45resetR889rZ0SRfpm7D3msy0NqS4YRMmz9jx39GeBM47SCWkMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/password-reset.vue")
  },
  {
    name: "register___cs",
    path: "/registrace",
    meta: register8qy17U16Mi5e1DUpbXtw_49S3WUOF7f79HU_45JPpgJoYMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/register.vue")
  },
  {
    name: "site-map___cs",
    path: "/mapa-stranek",
    meta: site_45mapBe7DMXxWuxPN5dMKO1upLBe3Wpnoiq_456kG8BYSDEBikMeta || {},
    component: () => import("/opt/simploshop/themes/vut/app/pages/site-map.vue")
  }
]