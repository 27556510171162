export const CART_PAYMENT_RETURN_QUERY_ORDER_ID = 'oid'

export const CartOrderPaymentStatus = {
    /**
     * The payment was successful and the order was paid.
     */
    SUCCESS: 'success',
    /**
     * The payment was not successful and the order is definitely not paid.
     * This can happen either due to an error on the payment provider's page,
     * or before even being redirected to the payment provider (BE responds with
     * an error, and we do not get a payment gate url)
     */
    ERROR: 'error',
    /**
     * There was an error while checking the payment status on our BE.
     * We do not know if the order is paid or not - it could be either,
     * since the payment provider redirected the customer back to the shop.
     *
     * In this case, we do not even have the order ID, so we can only provide
     * a generic error message to the customer.
     */
    UNKNOWN: 'unknown',
} as const
export type CartOrderPaymentStatus = typeof CartOrderPaymentStatus[keyof typeof CartOrderPaymentStatus]

export const PaymentService = {
    GoPay: 'gopay',
    CSOB: 'csob',
} as const
export type PaymentService = typeof PaymentService[keyof typeof PaymentService]

export const AUTH_RETURN_URL_COOKIE_NAME = '_tmp-return-url'
export const CART_TRANSFER_SOURCE_COOKIE_NAME = '_tmp-src-cart-id'
