import type { ConstructorType } from '@composable-api-types/utils'
import { ApiModel, type CreateApiModelData } from '@composable-api/api.model'

interface Attributes {
    [ProductImageModel.ATTR_ID]: number
    [ProductImageModel.ATTR_DESCRIPTION]: string | null
    [ProductImageModel.ATTR_IMAGE_ID]: number
    [ProductImageModel.ATTR_PRODUCT_ID]: number
    [ProductImageModel.ATTR_PRODUCT_VARIATION_ID]: number | null
    [ProductImageModel.ATTR_IS_DEFAULT]: boolean
    [ProductImageModel.ATTR_POSITION]: number | null
    [ProductImageModel.ATTR_TYPE]: string
    [ProductImageModel.ATTR_WIDTH]: number | null
    [ProductImageModel.ATTR_HEIGHT]: number | null
}

interface Embeds {
    [ProductImageModel.EMBED_IMAGE_URL]?: string | null
}

export class ProductImageModel extends ApiModel<Attributes, Embeds> {
    static key = 'ProductImageModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PRODUCT_VARIATION_ID = 'product_variation_id'
    static readonly ATTR_IS_DEFAULT = 'is_default'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_WIDTH = 'width'
    static readonly ATTR_HEIGHT = 'height'

    static readonly EMBED_IMAGE_URL = 'image_url'

    get id() {
        return this._getAttribute(ProductImageModel.ATTR_ID)
    }

    get description() {
        return this._getAttribute(ProductImageModel.ATTR_DESCRIPTION)
    }

    get imageId() {
        return this._getAttribute(ProductImageModel.ATTR_IMAGE_ID)
    }

    get productId() {
        return this._getAttribute(ProductImageModel.ATTR_PRODUCT_ID)
    }

    get productVariationId() {
        return this._getAttribute(ProductImageModel.ATTR_PRODUCT_VARIATION_ID)
    }

    get isDefault() {
        return this._getAttribute(ProductImageModel.ATTR_IS_DEFAULT)
    }

    get position() {
        return this._getAttribute(ProductImageModel.ATTR_POSITION)
    }

    get type() {
        return this._getAttribute(ProductImageModel.ATTR_TYPE)
    }

    get width() {
        return this._getAttribute(ProductImageModel.ATTR_WIDTH)
    }

    get height() {
        return this._getAttribute(ProductImageModel.ATTR_HEIGHT)
    }

    get imageUrl() {
        return this._getEmbed(ProductImageModel.EMBED_IMAGE_URL)
    }

    /**
     * Create a new placeholder image instance of the model.
     * Can be useful for displaying a placeholder image in galleries, etc.
     *
     * By default, it uses the `/images/fallback.svg` image, with a width and height of 1000px.
     * There are no other properties set.
     *
     * In case you want to use a different image, provide the `data` to initialize the model with
     * custom properties. No merging will be done and only the provided data will be used to create
     * the model in this case.
     */
    static override create<T extends ApiModel>(this: ConstructorType<T>, data?: CreateApiModelData<T>): T {
        if (data) return super.create<T>(data)

        return super.create<ProductImageModel>({
            attrs: {
                [ProductImageModel.ATTR_WIDTH]: 1000,
                [ProductImageModel.ATTR_HEIGHT]: 1000,
                [ProductImageModel.ATTR_DESCRIPTION]: '',
            },
            embeds: {
                [ProductImageModel.EMBED_IMAGE_URL]: '/images/fallback.svg',
            },
        }) as unknown as T
    }
}
