import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import { CsobPaymentModel } from '../models/csob-payment.model'

class CsobPaymentApiService<M extends CsobPaymentModel> extends ApiService<M> {
    post(data: { order_id: number, return_uri: string | null, return_method: 'GET' | 'POST' }, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            body: data,
            method: 'POST',
        })
    }
}

export function getCsobPaymentApiService() {
    return new CsobPaymentApiService({
        endpoint: '/csob/payment',
    }, CsobPaymentModel)
}
