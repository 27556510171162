import { ApiModel } from '@composable-api/api.model'
import type { Monetary } from '../types/general-data'
import { ProductModel } from '@simploshop-models/product.model'
import { ProductVariationModel } from '@simploshop-models/product-variation.model'

interface Attributes {
    [CustomerProductListItemModel.ATTR_ID]: number
    [CustomerProductListItemModel.ATTR_PRODUCT_ID]: number
    [CustomerProductListItemModel.ATTR_PRODUCT_VARIATION_ID]: number | null
    [CustomerProductListItemModel.ATTR_NOTE]: string | null
    [CustomerProductListItemModel.ATTR_NAME]: string | null
    [CustomerProductListItemModel.ATTR_IMAGE_ID]: number | null
    [CustomerProductListItemModel.ATTR_PRICE]: Monetary
    [CustomerProductListItemModel.ATTR_TAXED_PRICE]: Monetary
}

interface Embeds {
    [CustomerProductListItemModel.EMBED_STOCK_STATES]?: number | null
    [CustomerProductListItemModel.EMBED_IMAGE_URL]?: string | null
    [CustomerProductListItemModel.EMBED_PRODUCT]?: ProductModel | null
    [CustomerProductListItemModel.EMBED_PRODUCT_VARIATION]?: ProductVariationModel | null
}

export class CustomerProductListItemModel extends ApiModel<Attributes, Embeds> {
    static key = 'CustomerProductListItemModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PRODUCT_VARIATION_ID = 'product_variation_id'
    static readonly ATTR_NOTE = 'note'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'

    static readonly EMBED_STOCK_STATES = 'stock_states'
    static readonly EMBED_IMAGE_URL = 'image_url'
    static readonly EMBED_PRODUCT = 'product'
    static readonly EMBED_PRODUCT_VARIATION = 'product_variation'

    get id() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_ID)
    }
    get productId() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_PRODUCT_ID)
    }
    get productVariationId() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_PRODUCT_VARIATION_ID)
    }
    get note() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_NOTE)
    }
    get name() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_NAME)
    }
    get imageId() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_IMAGE_ID)
    }
    get price() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_PRICE)
    }
    get taxedPrice() {
        return this._getAttribute(CustomerProductListItemModel.ATTR_TAXED_PRICE)
    }

    get stockStates() {
        return this._getEmbed(CustomerProductListItemModel.EMBED_STOCK_STATES)
    }
    get imageUrl() {
        return this._getEmbed(CustomerProductListItemModel.EMBED_IMAGE_URL)
    }
    get product() {
        return this._getEmbed(CustomerProductListItemModel.EMBED_PRODUCT, ProductModel)
    }
    get productVariation() {
        return this._getEmbed(CustomerProductListItemModel.EMBED_PRODUCT_VARIATION, ProductVariationModel)
    }
}
