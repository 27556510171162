import type { Monetary, TaxPerRate, ApiDateTime, MonetarySet } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { CartShippingMethodModel } from './cart-shipping-method.model'
import { CartPaymentMethodModel } from './cart-payment-method.model'
import { CartDiscountModel } from './cart-discount.model'
import type { GetPriceOptions } from '@simploshop-models/product.model'
import { getFormattedPrice } from '../utils/localization'

interface Attributes {
    [CartModel.ATTR_ID]: string
    [CartModel.ATTR_EXPIRATION_AT]: ApiDateTime
    [CartModel.ATTR_ITEMS_PRICE]: Monetary
    [CartModel.ATTR_TAXED_ITEMS_PRICE]: Monetary
    [CartModel.ATTR_DISCOUNTED_TAXED_ITEMS_PRICE]: Monetary
    [CartModel.ATTR_TOTAL_PRICE]: Monetary
    [CartModel.ATTR_TAXED_TOTAL_PRICE]: Monetary
    [CartModel.ATTR_TOTAL_PRICE_WITHOUT_CREDITS]: Monetary
    [CartModel.ATTR_TAXED_TOTAL_PRICE_WITHOUT_CREDITS]: Monetary
    [CartModel.ATTR_TAX_AMOUNT]: Monetary
    [CartModel.ATTR_TAX_PER_RATE]: TaxPerRate
    [CartModel.ATTR_DISCOUNTED_AMOUNT]: Monetary
    [CartModel.ATTR_TAXED_DISCOUNTED_AMOUNT]: Monetary
    [CartModel.ATTR_SHIPPING_METHOD]?: CartShippingMethodModel
    [CartModel.ATTR_PAYMENT_METHOD]?: CartPaymentMethodModel
    [CartModel.ATTR_TOTAL_AMOUNT]: number
    [CartModel.ATTR_UNIQUE_AMOUNT]: number
    [CartModel.ATTR_EMAIL]: string | null
    [CartModel.ATTR_HAS_ACCOUNT]: boolean
    [CartModel.ATTR_HAS_FREE_SHIPPING]: boolean
    [CartModel.ATTR_HAS_SHIPPING_ADDRESS]: boolean
    [CartModel.ATTR_DISCOUNTS]: CartDiscountModel[]
    [CartModel.ATTR_CUSTOMER_ACCOUNTS]: CustomerAccount[]
    [CartModel.ATTR_RESERVATION_SET]: boolean
    [CartModel.ATTR_DISCOUNTED_ITEMS_DETAIL]: DiscountedItemsDetail[]
    [CartModel.ATTR_TOTAL_AMOUNTS]: MonetarySet
    [CartModel.ATTR_TOTAL_ITEMS_AMOUNTS]: MonetarySet
    [CartModel.ATTR_TOTAL_ITEMS_AMOUNTS_ONLY_WITH_CUSTOMER_ACCOUNT]: MonetarySet
    [CartModel.ATTR_TOTAL_ITEMS_AMOUNTS_ONLY_WITHOUT_CUSTOMER_ACCOUNT]: MonetarySet
}

interface Embeds {
    [CartModel.EMBED_SHIPPING_OPTION]?: {
        id: string
        name: string
        country_id: string
    } | null
    [CartModel.EMBED_ADDITIONAL_DATA]?: AdditionalData
    [CartModel.EMBED_SHIPPING_COUNTRY_ID]?: string | null
    [CartModel.EMBED_CUSTOMER_ACCOUNT_AMOUNTS_OF_ITEMS]?: CustomerAccountAmountsOfItems[]
    [CartModel.EMBED_CUSTOMER_ACCOUNT_AMOUNTS_OF_ORDER]?: CustomerAccountAmountsOfOrder[]
}

export class CartModel extends ApiModel<Attributes, Embeds> {
    static key = 'CartModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_EXPIRATION_AT = 'expiration_at'
    static readonly ATTR_ITEMS_PRICE = 'items_price'
    static readonly ATTR_TAXED_ITEMS_PRICE = 'taxed_items_price'
    static readonly ATTR_DISCOUNTED_TAXED_ITEMS_PRICE = 'discounted_taxed_items_price'
    static readonly ATTR_TOTAL_PRICE = 'total_price'
    static readonly ATTR_TAXED_TOTAL_PRICE = 'taxed_total_price'
    static readonly ATTR_TOTAL_PRICE_WITHOUT_CREDITS = 'total_price_without_credits'
    static readonly ATTR_TAXED_TOTAL_PRICE_WITHOUT_CREDITS = 'taxed_total_price_without_credits'
    static readonly ATTR_TAX_AMOUNT = 'tax_amount'
    static readonly ATTR_TAX_PER_RATE = 'tax_per_rate'
    static readonly ATTR_DISCOUNTED_AMOUNT = 'discounted_amount'
    static readonly ATTR_TAXED_DISCOUNTED_AMOUNT = 'taxed_discounted_amount'
    static readonly ATTR_SHIPPING_METHOD = 'shipping_method'
    static readonly ATTR_PAYMENT_METHOD = 'payment_method'
    static readonly ATTR_TOTAL_AMOUNT = 'total_amount'
    static readonly ATTR_UNIQUE_AMOUNT = 'unique_amount'
    static readonly ATTR_EMAIL = 'email'
    static readonly ATTR_HAS_ACCOUNT = 'has_account'
    static readonly ATTR_HAS_FREE_SHIPPING = 'has_free_shipping'
    static readonly ATTR_HAS_SHIPPING_ADDRESS = 'has_shipping_address'
    static readonly ATTR_DISCOUNTS = 'discounts'
    static readonly ATTR_CUSTOMER_ACCOUNTS = 'customer_accounts'
    static readonly ATTR_RESERVATION_SET = 'reservation_set'
    static readonly ATTR_DISCOUNTED_ITEMS_DETAIL = 'discounted_items_detail'
    static readonly ATTR_TOTAL_AMOUNTS = 'total_amounts'
    static readonly ATTR_TOTAL_ITEMS_AMOUNTS = 'total_items_amounts'
    static readonly ATTR_TOTAL_ITEMS_AMOUNTS_ONLY_WITH_CUSTOMER_ACCOUNT = 'total_items_amounts_only_with_customer_account'
    static readonly ATTR_TOTAL_ITEMS_AMOUNTS_ONLY_WITHOUT_CUSTOMER_ACCOUNT = 'total_items_amounts_only_without_customer_account'

    static readonly EMBED_SHIPPING_OPTION = 'shipping_option'
    static readonly EMBED_ADDITIONAL_DATA = 'additional_data'
    static readonly EMBED_SHIPPING_COUNTRY_ID = 'shipping_country_id'
    static readonly EMBED_CUSTOMER_ACCOUNT_AMOUNTS_OF_ITEMS = 'customer_account_amounts_of_items'
    static readonly EMBED_CUSTOMER_ACCOUNT_AMOUNTS_OF_ORDER = 'customer_account_amounts_of_order'

    get id() {
        return this._getAttribute(CartModel.ATTR_ID)
    }

    /**
     * The date and time when the cart will expire.
     * This is only set for unauthenticated customers (guests).
     */
    get expirationAt() {
        return this._getAttribute(CartModel.ATTR_EXPIRATION_AT)
    }

    /**
     * The sum of the prices of all items in the cart.
     * @deprecated DO NOT USE!, will be removed soon
     */
    get itemsPrice() {
        return this._getAttribute(CartModel.ATTR_ITEMS_PRICE)
    }

    /**
     * The sum of the prices of all items in the cart, including tax.
     * @deprecated DO NOT USE!, will be removed soon
     */
    get taxedItemsPrice() {
        return this._getAttribute(CartModel.ATTR_TAXED_ITEMS_PRICE)
    }

    /**
     * @deprecated DO NOT USE, will be removed soon
     */
    get discountedTaxedItemsPrice() {
        return this._getAttribute(CartModel.ATTR_DISCOUNTED_TAXED_ITEMS_PRICE)
    }

    /**
     * **You probably want to use `taxedTotalPrice` instead.**
     *
     * The total price that the customer will have to pay.
     * This includes the sum of all items in the cart, including shipping,
     * payment and other fees.
     *
     * @see taxedTotalPrice
     * @deprecated DO NOT USE, will be removed soon
     */
    get totalPrice() {
        return this._getAttribute(CartModel.ATTR_TOTAL_PRICE)
    }

    /**
     * The total price that the customer will have to pay, **including tax**.
     * This includes the sum of all items in the cart, including shipping,
     * payment and other fees.
     * @deprecated DO NOT USE, will be removed soon
     */
    get taxedTotalPrice() {
        return this._getAttribute(CartModel.ATTR_TAXED_TOTAL_PRICE)
    }

    /**
     * @deprecated DO NOT USE, will be removed soon
     */
    get totalPriceWithoutCredits() {
        return this._getAttribute(CartModel.ATTR_TOTAL_PRICE_WITHOUT_CREDITS)
    }

    /**
     * @deprecated DO NOT USE, will be removed soon
     */
    get taxedTotalPriceWithoutCredits() {
        return this._getAttribute(CartModel.ATTR_TAXED_TOTAL_PRICE_WITHOUT_CREDITS)
    }

    /**
     * @deprecated DO NOT USE, will be removed soon
     */
    get taxAmount() {
        return this._getAttribute(CartModel.ATTR_TAX_AMOUNT)
    }

    /**
     * @deprecated DO NOT USE, will be removed soon
     */
    get taxPerRate() {
        return this._getAttribute(CartModel.ATTR_TAX_PER_RATE)
    }

    /**
     * @deprecated DO NOT USE, will be removed soon
     */
    get discountedAmount() {
        return this._getAttribute(CartModel.ATTR_DISCOUNTED_AMOUNT)
    }

    /**
     * @deprecated DO NOT USE, will be removed soon
     */
    get taxedDiscountedAmount() {
        return this._getAttribute(CartModel.ATTR_TAXED_DISCOUNTED_AMOUNT)
    }

    get shippingMethod() {
        return this._getAttribute(CartModel.ATTR_SHIPPING_METHOD, CartShippingMethodModel)
    }

    get paymentMethod() {
        return this._getAttribute(CartModel.ATTR_PAYMENT_METHOD, CartPaymentMethodModel)
    }

    get totalAmount() {
        return this._getAttribute(CartModel.ATTR_TOTAL_AMOUNT)
    }

    get uniqueAmount() {
        return this._getAttribute(CartModel.ATTR_UNIQUE_AMOUNT)
    }

    get email() {
        return this._getAttribute(CartModel.ATTR_EMAIL)
    }

    get hasAccount() {
        return this._getAttribute(CartModel.ATTR_HAS_ACCOUNT)
    }

    get hasFreeShipping() {
        return this._getAttribute(CartModel.ATTR_HAS_FREE_SHIPPING)
    }

    get hasShippingAddress() {
        return this._getAttribute(CartModel.ATTR_HAS_SHIPPING_ADDRESS)
    }

    get discounts() {
        return this._getAttribute(CartModel.ATTR_DISCOUNTS, CartDiscountModel)
    }

    get customerAccounts() {
        return this._getAttribute(CartModel.ATTR_CUSTOMER_ACCOUNTS)
    }

    get reservationSet() {
        return this._getAttribute(CartModel.ATTR_RESERVATION_SET)
    }

    get discountedItemsDetail() {
        return this._getAttribute(CartModel.ATTR_DISCOUNTED_ITEMS_DETAIL)
    }

    /**
     * The total price of the cart including all items, shipping, payment and other fees.
     * @private
     */
    private get totalAmounts() {
        return this._getAttribute(CartModel.ATTR_TOTAL_AMOUNTS)
    }

    /**
     * The total price of the cart items (excluding shipping & payment).
     * All the cart items are included (credit items + normal items)
     *
     * Use `totalItemsAmountsOnlyWithCustomerAccount` for only credit items
     * or `totalItemsAmountsOnlyWithoutCustomerAccount`for only normal items.
     *
     * @see totalItemsAmountsOnlyWithCustomerAccount
     * @see totalItemsAmountsOnlyWithoutCustomerAccount
     * @private
     */
    private get totalItemsAmounts() {
        return this._getAttribute(CartModel.ATTR_TOTAL_ITEMS_AMOUNTS)
    }

    /**
     * The total price of the credit cart items. This only includes
     * the actual ADDITIONAL COST the customer will have to pay
     * if the items cannot be fully covered by the credits.
     *
     * Use `totalItemsAmounts` for combined items cost
     * @see totalItemsAmounts
     * @private
     */
    private get totalItemsAmountsOnlyWithCustomerAccount() {
        return this._getAttribute(CartModel.ATTR_TOTAL_ITEMS_AMOUNTS_ONLY_WITH_CUSTOMER_ACCOUNT)
    }

    /**
     * The total price of the NON-CREDIT cart items.
     *
     * Use `totalItemsAmounts` for combined items cost
     * @see totalItemsAmounts
     * @private
     */
    private get totalItemsAmountsOnlyWithoutCustomerAccount() {
        return this._getAttribute(CartModel.ATTR_TOTAL_ITEMS_AMOUNTS_ONLY_WITHOUT_CUSTOMER_ACCOUNT)
    }

    get shippingOption() {
        return this._getEmbed(CartModel.EMBED_SHIPPING_OPTION)
    }

    get additionalData() {
        return this._getEmbed(CartModel.EMBED_ADDITIONAL_DATA)
    }

    /**
     * Represents either the billing country id (when the billing address is the same
     * as the shipping address - `has_shipping_address: false`) or the
     * shipping address id (`has_shipping_address: true`)
     */
    get shippingCountryId() {
        return this._getEmbed(CartModel.EMBED_SHIPPING_COUNTRY_ID)
    }

    /**
     * Shows how many credits are remaining for the customer to use in
     * this current cart for each customer credit account.
     */
    protected get customerAccountAmountsOfItems() {
        return this._getEmbed(CartModel.EMBED_CUSTOMER_ACCOUNT_AMOUNTS_OF_ITEMS)
    }

    /**
     * Represents the amount of credits the customer will gain by making the order.
     */
    protected get customerAccountAmountsOfOrder() {
        return this._getEmbed(CartModel.EMBED_CUSTOMER_ACCOUNT_AMOUNTS_OF_ORDER)
    }

    // ---------------------------------------------------------------------------------------------------------------------

    /**
     * Get the price of the cart items.
     * By default, this includes all items (normal + items bought for credits).
     */
    getItemsPrice(options?: Partial<GetItemsPriceOptions>): Monetary | null {
        if (options?.items === 'normal') {
            return (options?.tax !== false
                ? this.totalItemsAmountsOnlyWithoutCustomerAccount?.pair.taxed_price
                : this.totalItemsAmountsOnlyWithoutCustomerAccount?.pair.price) ?? null
        }

        if (options?.items === 'credits') {
            return (options?.tax !== false
                ? this.totalItemsAmountsOnlyWithCustomerAccount?.pair.taxed_price
                : this.totalItemsAmountsOnlyWithCustomerAccount?.pair.price) ?? null
        }

        return (options?.tax !== false
            ? this.totalItemsAmounts?.pair.taxed_price
            : this.totalItemsAmounts?.pair.price) ?? null
    }

    /**
     * Get the formatted price of the cart items.
     * By default, this includes all items (normal + items bought for credits).
     */
    getItemsFormattedPrice(options?: Partial<GetItemsPriceOptions>) {
        return getFormattedPrice(this.getItemsPrice(options))
    }

    /**
     * Get the total price of the cart including all the items, shipping, payment and other fees.
     */
    getTotalPrice(options?: Partial<GetPriceOptions>) {
        return (options?.tax !== false ? this.totalAmounts?.pair.taxed_price : this.totalAmounts?.pair.price) ?? null
    }

    /**
     * Get the total formatted price of the cart including all the items, shipping, payment and other fees.
     */
    getTotalFormattedPrice(options?: Partial<GetPriceOptions>) {
        return getFormattedPrice(this.getTotalPrice(options))
    }

    hasItems() {
        return (this.uniqueAmount ?? 0) > 0
    }

    /**
     * A method only meant to be used to optimistically update the cart's total amount
     * when the last item is removed from the cart.
     * Does not update the cart price etc.
     */
    setItemsToZero() {
        this._setAttribute(CartModel.ATTR_TOTAL_AMOUNT, 0)
        this._setAttribute(CartModel.ATTR_UNIQUE_AMOUNT, 0)
    }

    getAmountPerTaxRate(taxRate: number): Monetary | null {
        return this.taxPerRate?.[taxRate]?.tax_amount ?? null
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

interface Discount {
    id: number
    name: string | null
    code: string
    amount: Monetary
}

interface CustomerAccount {
    customer_account_id: number
    amount: Monetary
    conversion_value: Monetary
}

interface DiscountedItem {
    cart_item_id: string
    taxed_amount: string
    amount: string
}

interface DiscountedItemsDetail {
    discount: Discount;
    discounted_items: DiscountedItem[];
}

interface AdditionalData {} // TODO: add props

//
interface CustomerAccountAmountsOfItems {
    customer_account_id: number
    amounts_remain: number
    /**
     * 1 credit can be 10 CZK, for example
     * That's why we need to convert it.
     */
    converted_amounts_remain: Monetary
}

interface CustomerAccountAmountsOfOrder {
    customer_account_id: number
    amounts: number
    converted_amounts: Monetary
}

type GetItemsPriceOptions = {
    /**
     * Whether to get the price for all items, normal items or credits.
     * @default 'all'
     */
    items: 'all' | 'normal' | 'credits'
} & GetPriceOptions
