import { ApiModel } from '@composable-api/api.model'
import type { ApiDateTime } from '../types/general-data'

interface Attributes {
    [LoyaltyProgramModel.ATTR_ID]: number
    [LoyaltyProgramModel.ATTR_NAME]: string
    [LoyaltyProgramModel.ATTR_DESCRIPTION]: string
    [LoyaltyProgramModel.ATTR_OPERATION]: number
    [LoyaltyProgramModel.ATTR_VALUE]: number
    [LoyaltyProgramModel.ATTR_VALID_FROM]: ApiDateTime
    [LoyaltyProgramModel.ATTR_VALID_TO]: ApiDateTime
    [LoyaltyProgramModel.ATTR_USAGE_LIMIT]: number
    [LoyaltyProgramModel.ATTR_USAGE_COUNT]: number
    [LoyaltyProgramModel.ATTR_IS_ACTIVE]: boolean
    [LoyaltyProgramModel.ATTR_LIMIT_PER_CUSTOMER]: number
    [LoyaltyProgramModel.ATTR_HAS_RULES]: boolean
    [LoyaltyProgramModel.ATTR_RULE]: string
    [LoyaltyProgramModel.ATTR_IS_FOR_DEFAULT_CATEGORY]: boolean
}

interface Embeds {
    [LoyaltyProgramModel.EMBED_CUSTOMER_ACCOUNT_IDS]: number[]
}

export class LoyaltyProgramModel extends ApiModel<Attributes, Embeds> {
    static key = 'LoyaltyProgramModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_OPERATION = 'operation'
    static readonly ATTR_VALUE = 'value'
    static readonly ATTR_VALID_FROM = 'valid_from'
    static readonly ATTR_VALID_TO = 'valid_to'
    static readonly ATTR_USAGE_LIMIT = 'usage_limit'
    static readonly ATTR_USAGE_COUNT = 'usage_count'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_LIMIT_PER_CUSTOMER = 'limit_per_customer'
    static readonly ATTR_HAS_RULES = 'has_rules'
    static readonly ATTR_RULE = 'rule'
    static readonly ATTR_IS_FOR_DEFAULT_CATEGORY = 'is_for_default_category'

    static readonly EMBED_CUSTOMER_ACCOUNT_IDS = 'customer_account_ids'

    get id() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_DESCRIPTION)
    }

    get operation() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_OPERATION)
    }

    get value() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_VALUE)
    }

    get validFrom() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_VALID_FROM)
    }

    get validTo() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_VALID_TO)
    }

    get usageLimit() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_USAGE_LIMIT)
    }

    get usageCount() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_USAGE_COUNT)
    }

    get isActive() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_IS_ACTIVE)
    }

    get limitPerCustomer() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_LIMIT_PER_CUSTOMER)
    }

    get hasRules() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_HAS_RULES)
    }

    get rule() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_RULE)
    }

    get isForDefaultCategory() {
        return this._getAttribute(LoyaltyProgramModel.ATTR_IS_FOR_DEFAULT_CATEGORY)
    }

    get customerAccountIds() {
        return this._getEmbed(LoyaltyProgramModel.EMBED_CUSTOMER_ACCOUNT_IDS)
    }
}
