export enum CartItemType {
    CLASSIC = 0,
    GIFT = 1,
    NO_SEND_GIFT = 2,
}

export enum GiftTypeEnum {
    MANUAL = 0,
    AUTOMATIC = 1,
}
