import type { MaybeRefOrGetter, UnwrapRef } from 'vue'
import type { PhaseManagerRequirements, PhaseManagerPhase } from '../../types/general'

/**
 * # PhaseManager
 *
 * This composable helps to manage phases in a process flow.
 * It provides methods to switch to a certain phase and to check if a phase is active or completed.
 *
 * ## TODO document
 */
export default function usePhaseManager<T extends PhaseManagerPhase>(phases: MaybeRefOrGetter<T[]>, _options: Partial<PhaseManagerOptions<T>> | string = {}, _key?: string) {
    // TODO: cleanup using overrides
    const options = typeof _options === 'string' ? {} : _options
    const key = typeof _options === 'string' ? _options : _key

    const _phases = computed(() => toValue(phases))

    const requirements = computed<PhaseManagerRequirements<T>>(() => toValue(options.requirements) as PhaseManagerRequirements<T>)

    watch([requirements, _phases], async () => {
        await nextTick()
        // When the requirements or the phases change, we need to check, whether the current phase can
        // still be active. If not, we need to switch to the first phase that can be activated, which is before
        // the first phase that cannot.
        if (!currentInternalPhase.value) return

        if (!canSwitchTo(currentInternalPhase.value.data)) {
            let phaseToSwitchTo: InternalPhase<T> | null = null

            for (const phase of phasesArray.value) {
                // if (phase.data.id === currentInternalPhase.value.data.id) continue

                if (canSwitchTo(phase.data)) {
                    phaseToSwitchTo = phase as InternalPhase<T>
                } else {
                    if (phaseToSwitchTo) {
                        switchTo(phaseToSwitchTo.data, { wasForced: true })
                    } else {
                        // If no phase to switch to was found, an error probably occurred.
                        // In this case, let the nextInternalPhase computed property handle it
                        switchTo(nextInternalPhase.value?.data ?? null, { wasForced: true })
                    }
                    break
                }
            }
        }
    })

    function _computePhaseRequirements(phase: T | T['id'] | UnwrapRef<T>): boolean {
        const phaseRequirements = requirements.value?.[(typeof phase === 'string' ? phase : phase.id) as T['id']]
        if (phaseRequirements === undefined) return true

        return Array.isArray(phaseRequirements) ? phaseRequirements.every(Boolean) : phaseRequirements
    }

    const createPhasesMap = () => new Map(Object.values(_phases.value).map(
        phase => [phase.id, {
            completed: false,
            data: phase,
        }] as [keyof T, InternalPhase<T>])
    )
    const phasesMap = ref(createPhasesMap())
    watch(_phases, () => {
        phasesMap.value = createPhasesMap()
    })

    const phasesArray = computed(() => Array.from(phasesMap.value.values()))

    const [initialPhase] = phasesMap.value.values()

    const currentInternalPhase = useState(key, () => initialPhase ?? null)
    const nextInternalPhase = computed(() => {
        if (!phasesArray.value.length) return null

        // if the current phase is not active, use the first phase as the next phase
        if (!currentInternalPhase.value) {
            return phasesArray.value[0]!
        }

        const currentIndex = phasesArray.value.findIndex(item => item.data.id === currentInternalPhase.value?.data.id)
        if (currentIndex === -1) return null
        return phasesArray.value[currentIndex + 1] ?? null
    })

    const previousInternalPhase = computed(() => {
        if (!phasesArray.value.length) return null

        if (!currentInternalPhase.value) return null

        const currentIndex = phasesArray.value.findIndex(item => item.data.id === currentInternalPhase.value?.data.id)
        if (currentIndex === -1 || currentIndex === 0) return null
        return phasesArray.value[currentIndex - 1] ?? null
    })

    const isPhaseActive = (phase: T | T['id'] | null | undefined): boolean => (typeof phase === 'string' ? phase : phase?.id) === currentInternalPhase.value?.data.id
    const isPhaseBeforeActive = (phase: T | T['id'] | null | undefined): boolean => {
        let foundActive = false
        for (const internalPhase of phasesArray.value) {
            if (foundActive) return false

            if (internalPhase.data.id === (typeof phase === 'string' ? phase : phase?.id)) {
                return true
            }

            if (internalPhase.data.id === currentInternalPhase.value?.data.id) foundActive = true
        }
        return false
    }
    const isPhaseCompleted = (phase: T | T['id'] | null | undefined): boolean => !!(phasesMap.value.get((typeof phase === 'string' ? phase : phase?.id) as keyof T)?.completed)
    const switchTo = (phase: T | T['id'] | UnwrapRef<T> | null, additionalDetails: Partial<Parameters<PhaseManagerOptions<T>['onPhaseChange']>[2]> = {}) => {
        if (!phase) {
            currentInternalPhase.value = null
            return false
        }
        const newPhase = phasesMap.value.get((typeof phase === 'string' ? phase : phase.id) as keyof T)
        if (!newPhase) return false

        if (currentInternalPhase.value?.data.id === newPhase.data.id) return false

        options.onPhaseChange?.(newPhase.data as T, currentInternalPhase.value?.data as T, {
            wasForced: additionalDetails.wasForced ?? false,
        })
        // @ts-ignore   // TODO: fix type (should be correct but is double UnwrapRef<>)
        currentInternalPhase.value = newPhase
        return true
    }
    const canSwitchTo = (phase: UnwrapRef<T> | T | T['id'] | null | undefined): boolean => {
        if (!phase) return false
        if (!phasesArray.value.some(i => i.data.id === (typeof phase === 'string' ? phase : phase.id))) return false

        // if (isPhaseActive(phase)) return true
        // if (isPhaseCompleted(phase)) return true
        return _computePhaseRequirements(phase)
    }

    // /**
    //  * Switches to the first available phase in the process flow.
    //  * If no phase is active, the first phase will be activated.
    //  *
    //  * @throws {Error} If no phases are found.
    //  */
    // function nextPhase() {
    //     if (!phasesArray.value.length) throw new Error('[PhaseManager]: No phases found')
    //
    //     // if the current phase is not active, switch to the first phase
    //     if (!currentInternalPhase.value) {
    //         const firstPhase = phasesArray.value[0]!
    //         switchTo(firstPhase.data as T)
    //         return
    //     }
    //
    //     // mark the current phase as completed
    //     currentInternalPhase.value.completed = true
    //
    //     for (const phase of phasesArray.value) {
    //         console.log(phase.completed)
    //     }
    //
    //     // // find the next phase & switch to it
    //     // for (const phase of phasesArray) {
    //     //     // skip completed phases and the active phase
    //     //     if (phase.completed || phase.data.id === currentInternalPhase.value.data.id) continue
    //     //
    //     //     // switch to the first phase that can be switched to
    //     //     if (canSwitchTo(phase.data)) {
    //     //         switchTo(phase.data)
    //     //         return
    //     //     }
    //     // }
    // }

    return {
        /**
         * The current phase.
         */
        phase: computed(() => currentInternalPhase.value?.data ?? null),
        nextPhase: computed(() => nextInternalPhase.value?.data ?? null),
        previousPhase: computed(() => previousInternalPhase.value?.data ?? null),
        phases: computed(() => Array.from(phasesMap.value.values()).map(item => item.data)),
        isPhaseActive: isPhaseActive,
        isPhaseBeforeActive: isPhaseBeforeActive,
        useIsPhaseActive: (phase: T) => computed(() => isPhaseActive(phase)),
        isPhaseCompleted: isPhaseCompleted,
        useIsPhaseCompleted: (phase: T) => computed(() => isPhaseCompleted(phase)),
        switchTo: switchTo,
        canSwitchTo: canSwitchTo,
        useCanSwitchTo: (phase: T) => computed(() => canSwitchTo(phase)),
        requirements: requirements,
    }
}

interface InternalPhase<T> {
    completed: boolean
    data: T
}


interface PhaseManagerOptions<T extends PhaseManagerPhase> {
    /**
     * The requirements to switch to a certain phase.
     * The key is the phase id and the value can be either a boolean or an array of booleans.
     * The phase can only be switched to if all of its requirements are met.
     */
    requirements: () => PhaseManagerRequirements<T>
    onPhaseChange: (newPhase: T, oldPhase: T | undefined, additionalDetails: { wasForced: boolean }) => void
}
