<template>
    <NuxtLoadingIndicator color="#E4012C" />
    <NuxtRouteAnnouncer />
    <NuxtLayout name="c-root">
        <NuxtLayout name="default">
            <CoreContainer>
                <BaseContainerContent :padding="{ vertical: 'large' }">
                    <ErrorContent :error="error"
                                  :translation-keys="translationKeys"
                                  class="flex flex-col items-center"
                    />
                </BaseContainerContent>
            </CoreContainer>
        </NuxtLayout>
    </NuxtLayout>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const {
    error,
} = defineProps<{
    error: NuxtError
}>()

const { t } = useI18n()

const translationKeys = computed(() => {
    switch (error.statusCode) {
        case 404:
            return {
                heading: t('messages.page_404.title'),
                text: t('messages.page_404.text'),
                options: [
                    t('messages.page_404.option_1'),
                    t('messages.page_404.option_2'),
                    t('messages.page_404.option_3'),
                ],
            }
    }
    return null
})

</script>
