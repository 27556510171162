import { ApiModel } from '@composable-api/api.model'
import type { Translation, FormattedCurrency, Monetary, ApiNullableDateTimeRange } from '#imports'

interface Attributes {
    [DiscountModel.ATTR_ID]: number
    [DiscountModel.ATTR_NAME]: Translation<string>
    [DiscountModel.ATTR_DESCRIPTION]: string | null
    [DiscountModel.ATTR_METHOD]: number // TODO: add enum
    [DiscountModel.ATTR_OPERATION]: number // TODO: add enum
    [DiscountModel.ATTR_PERCENTS]: number | null
    [DiscountModel.ATTR_AMOUNT]: Monetary | null
    [DiscountModel.ATTR_VALIDITY]: ApiNullableDateTimeRange
    [DiscountModel.ATTR_PRIORITY]: number
    [DiscountModel.ATTR_LIMIT_ORDERS]: number | null
    [DiscountModel.ATTR_USED_ORDERS]: number
    [DiscountModel.ATTR_IS_ACTIVE]: boolean
    [DiscountModel.ATTR_IS_FOR_PURCHASE]: boolean
    [DiscountModel.ATTR_FREE_SHIPPING]: boolean
    [DiscountModel.ATTR_HAS_RULES]: boolean
    [DiscountModel.ATTR_LIMIT_PER_CUSTOMER]: number | null
    [DiscountModel.ATTR_CODE]: string | null
    [DiscountModel.ATTR_CUSTOMER_GROUPS_TYPE]: number // TODO: add enum
    [DiscountModel.ATTR_CUSTOMERS_TYPE]: number // TODO: add enum
    [DiscountModel.ATTR_PRODUCTS_TYPE]: number // TODO: add enum
    [DiscountModel.ATTR_PRODUCT_VARIATIONS_TYPE]: number // TODO: add enum
    [DiscountModel.ATTR_CATEGORIES_TYPE]: number // TODO: add enum
    [DiscountModel.ATTR_DISCOUNTED_TYPE]: number // TODO: add enum
    [DiscountModel.ATTR_APPLICATION_SCOPE]: number // TODO: add enum
}

interface Embeds {
    [DiscountModel.EMBED_COMPUTED_PRODUCT_DISCOUNT_AMOUNTS]: FormattedCurrency
}

export class DiscountModel extends ApiModel<Attributes, Embeds> {
    static key = 'DiscountModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_METHOD = 'method'
    static readonly ATTR_OPERATION = 'operation'
    static readonly ATTR_PERCENTS = 'percents'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_VALIDITY = 'validity'
    static readonly ATTR_PRIORITY = 'priority'
    static readonly ATTR_LIMIT_ORDERS = 'limit_orders'
    static readonly ATTR_USED_ORDERS = 'used_orders'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_IS_FOR_PURCHASE = 'is_for_purchase'
    static readonly ATTR_FREE_SHIPPING = 'free_shipping'
    static readonly ATTR_HAS_RULES = 'has_rules'
    static readonly ATTR_LIMIT_PER_CUSTOMER = 'limit_per_customer'
    static readonly ATTR_CODE = 'code'
    static readonly ATTR_CUSTOMER_GROUPS_TYPE = 'customer_groups_type'
    static readonly ATTR_CUSTOMERS_TYPE = 'customers_type'
    static readonly ATTR_PRODUCTS_TYPE = 'products_type'
    static readonly ATTR_PRODUCT_VARIATIONS_TYPE = 'product_variations_type'
    static readonly ATTR_CATEGORIES_TYPE = 'categories_type'
    static readonly ATTR_DISCOUNTED_TYPE = 'discounted_type'
    static readonly ATTR_APPLICATION_SCOPE = 'application_scope'

    // TODO: check if this embed is available everywhere, or only in "Cart Applicable Discounts of Products API"
    static readonly EMBED_COMPUTED_PRODUCT_DISCOUNT_AMOUNTS = 'computed_product_discount_amounts'


    get id() {
        return this._getAttribute(DiscountModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(DiscountModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(DiscountModel.ATTR_DESCRIPTION)
    }

    get method() {
        return this._getAttribute(DiscountModel.ATTR_METHOD)
    }

    get operation() {
        return this._getAttribute(DiscountModel.ATTR_OPERATION)
    }

    get percents() {
        return this._getAttribute(DiscountModel.ATTR_PERCENTS)
    }

    get amount() {
        return this._getAttribute(DiscountModel.ATTR_AMOUNT)
    }

    get validity() {
        return this._getAttribute(DiscountModel.ATTR_VALIDITY)
    }

    get priority() {
        return this._getAttribute(DiscountModel.ATTR_PRIORITY)
    }

    get limitOrders() {
        return this._getAttribute(DiscountModel.ATTR_LIMIT_ORDERS)
    }

    get usedOrders() {
        return this._getAttribute(DiscountModel.ATTR_USED_ORDERS)
    }

    get isActive() {
        return this._getAttribute(DiscountModel.ATTR_IS_ACTIVE)
    }

    get isForPurchase() {
        return this._getAttribute(DiscountModel.ATTR_IS_FOR_PURCHASE)
    }

    get freeShipping() {
        return this._getAttribute(DiscountModel.ATTR_FREE_SHIPPING)
    }

    get hasRules() {
        return this._getAttribute(DiscountModel.ATTR_HAS_RULES)
    }

    get limitPerCustomer() {
        return this._getAttribute(DiscountModel.ATTR_LIMIT_PER_CUSTOMER)
    }

    get code() {
        return this._getAttribute(DiscountModel.ATTR_CODE)
    }

    get customerGroupsType() {
        return this._getAttribute(DiscountModel.ATTR_CUSTOMER_GROUPS_TYPE)
    }

    get customersType() {
        return this._getAttribute(DiscountModel.ATTR_CUSTOMERS_TYPE)
    }

    get productsType() {
        return this._getAttribute(DiscountModel.ATTR_PRODUCTS_TYPE)
    }

    get productVariationsType() {
        return this._getAttribute(DiscountModel.ATTR_PRODUCT_VARIATIONS_TYPE)
    }

    get categoriesType() {
        return this._getAttribute(DiscountModel.ATTR_CATEGORIES_TYPE)
    }

    get discountedType() {
        return this._getAttribute(DiscountModel.ATTR_DISCOUNTED_TYPE)
    }

    get applicationScope() {
        return this._getAttribute(DiscountModel.ATTR_APPLICATION_SCOPE)
    }

    get computedProductDiscountAmounts() {
        return this._getEmbed(DiscountModel.EMBED_COMPUTED_PRODUCT_DISCOUNT_AMOUNTS)
    }

    getFormattedDiscountedPrice() {
        return this.computedProductDiscountAmounts?.formatted
    }

}
