import { ApiModel } from '@composable-api/api.model'
import { ThemeAddressPredictionModel } from './theme-address-prediction.model'

interface Attributes {
    [ThemeAddressPredictionResponseModel.ATTR_PAGE]: number
    [ThemeAddressPredictionResponseModel.ATTR_ITEM_COUNT]: number
    [ThemeAddressPredictionResponseModel.ATTR_ITEMS_PER_PAGE]: number
    [ThemeAddressPredictionResponseModel.ATTR_DATA]: ThemeAddressPredictionModel[]
}

export class ThemeAddressPredictionResponseModel<TAttributes = unknown> extends ApiModel<Attributes & TAttributes> {
    static key = 'ThemeAddressPredictionResponseModel'

    static readonly ATTR_PAGE = 'page'
    static readonly ATTR_ITEM_COUNT = 'item_count'
    static readonly ATTR_ITEMS_PER_PAGE = 'items_per_page'
    static readonly ATTR_DATA = 'data'

    get page() {
        return this._getAttribute(ThemeAddressPredictionResponseModel.ATTR_PAGE)
    }

    get itemCount() {
        return this._getAttribute(ThemeAddressPredictionResponseModel.ATTR_ITEM_COUNT)
    }

    get itemsPerPage() {
        return this._getAttribute(ThemeAddressPredictionResponseModel.ATTR_ITEMS_PER_PAGE)
    }

    get data() {
        return this._getAttribute(ThemeAddressPredictionResponseModel.ATTR_DATA, ThemeAddressPredictionModel) as ThemeAddressPredictionModel[] | null
    }
}
