export enum PaymentServiceType {
    NONE = 0,
    BANK_TRANSACTION = 1,
    WEBPAY = 2,
    CARRIER = 3,
    GOPAY = 4,
    PICKUP_PAYMENT = 5,
    INVOICE_PAYMENT = 6,
    PAYS = 7,
    CSOB = 8,
}

export enum QrCodeBankPaymentTypeEnum {
    CZECH = 'czech',
    SLOVAK = 'slovak',
}
