import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel, ApiModelAttributes } from '@composable-api/api.model'
import type { NewsletterType } from '@sim-api-enums/newsletter'
import { CustomerModel } from '../models/customer.model'
import type { MaybeRefOrGetter } from 'vue'

class CustomersApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    usePatch<T extends boolean>(data: MaybeRefOrGetter<UpdateCustomerPayload>, options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'PATCH',
            body: data,
        })
    }
}

export function useCustomersApiService() {
    return new CustomersApiReactiveService({
        endpoint: '/customers',
    }, CustomerModel)
}


class CustomersApiService<M extends ApiModel> extends ApiService<M> {
    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }

    patch(data: UpdateCustomerPayload, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'PATCH',
            body: data,
        })
    }

    delete(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'DELETE',
        })
    }

    registerCustomer(data: RegisterCustomerPayload, options?: ApiServiceFetchOptions<M>) {
        return this
            .addRouteParam('register')
            .fetch({
                ...options,
                method: 'POST',
                body: data,
            })
    }
}

export function getCustomersApiService() {
    return new CustomersApiService({
        endpoint: '/customers',
    }, CustomerModel)
}

export type UpdateCustomerPayload = Partial<ApiModelAttributes<CustomerModel> & CustomerNewsletters>

export interface RegisterCustomerPayload {
    first_name: string
    last_name: string
    email: string
    password: string
    password_confirmation: string
    is_subscribed: boolean
    birth_at?: string
    dial_code?: string
    phone?: string
}

export interface CustomerNewsletters {
    newsletters: Partial<Record<NewsletterType, boolean>>
}
