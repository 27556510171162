import { ApiModel } from '@composable-api/api.model'
import type { Translation } from '@sim-api-types/general-data'
import type { ShippingServiceType } from '@sim-api-enums/shipping'

interface Attributes {
    [ShippingMethodModel.ATTR_ID]: number
    [ShippingMethodModel.ATTR_NAME]: Translation<string>
    [ShippingMethodModel.ATTR_DESCRIPTION]: Translation<string> | null
    [ShippingMethodModel.ATTR_SHIPPING_SERVICE]: ShippingServiceType
    [ShippingMethodModel.ATTR_IS_ACTIVE]: boolean
}

interface Embeds {
}

export class ShippingMethodModel extends ApiModel<Attributes, Embeds> {
    static key = 'ShippingMethodModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_SHIPPING_SERVICE = 'shipping_service'
    static readonly ATTR_IS_ACTIVE = 'is_active'

    get id() {
        return this._getAttribute(ShippingMethodModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ShippingMethodModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(ShippingMethodModel.ATTR_DESCRIPTION)
    }

    get shippingService() {
        return this._getAttribute(ShippingMethodModel.ATTR_SHIPPING_SERVICE)
    }

    get isActive() {
        return this._getAttribute(ShippingMethodModel.ATTR_IS_ACTIVE)
    }
}
