import { ApiModel } from '@composable-api/api.model'
import { BannerModel } from './banner.model'

interface Attributes {
    [BrandModel.ATTR_ID]: number
    [BrandModel.ATTR_NAME]: string
    [BrandModel.ATTR_DESCRIPTION]: string | null
    [BrandModel.ATTR_IMAGE_ID]: number | null
    [BrandModel.ATTR_THUMBNAIL_IMAGE_ID]: number | null
    [BrandModel.ATTR_META_TITLE]: string | null
    [BrandModel.ATTR_META_DESCRIPTION]: string | null
    [BrandModel.ATTR_OG_TITLE]: string | null
    [BrandModel.ATTR_OG_DESCRIPTION]: string | null
    [BrandModel.ATTR_OG_IMAGE_ID]: number | null
    [BrandModel.ATTR_META_INDEX]: boolean
    [BrandModel.ATTR_META_FOLLOW]: boolean
    [BrandModel.ATTR_SHOW_ON_SITEMAP]: boolean
    [BrandModel.ATTR_HAS_AUTOMATIC_SLUG]: boolean
    [BrandModel.ATTR_SLUG]: string | null
    [BrandModel.ATTR_IS_ACTIVE]: boolean
    [BrandModel.ATTR_PARENT_ID]: number | null
}

interface Embeds {
    [BrandModel.EMBED_URLS]: string
    [BrandModel.EMBED_IMAGE_URL]: string | null
    [BrandModel.EMBED_THUMBNAIL_IMAGE_URL]: string | null
    [BrandModel.EMBED_OG_IMAGE_URL]: string | null
    [BrandModel.EMBED_BANNERS]: BannerModel[]
}

export class BrandModel<TAttributes = unknown> extends ApiModel<Attributes & TAttributes, Embeds> {
    static key = 'BrandModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_THUMBNAIL_IMAGE_ID = 'thumbnail_image_id'
    static readonly ATTR_META_TITLE = 'meta_title'
    static readonly ATTR_META_DESCRIPTION = 'meta_description'
    static readonly ATTR_OG_TITLE = 'og_title'
    static readonly ATTR_OG_DESCRIPTION = 'og_description'
    static readonly ATTR_OG_IMAGE_ID = 'og_image_id'
    static readonly ATTR_META_INDEX = 'meta_index'
    static readonly ATTR_META_FOLLOW = 'meta_follow'
    static readonly ATTR_SHOW_ON_SITEMAP = 'show_on_sitemap'
    static readonly ATTR_HAS_AUTOMATIC_SLUG = 'has_automatic_slug'
    static readonly ATTR_SLUG = 'slug'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_PARENT_ID = 'parent_id'

    static readonly EMBED_URLS = 'urls'
    static readonly EMBED_IMAGE_URL = 'image_url'
    static readonly EMBED_THUMBNAIL_IMAGE_URL = 'thumbnail_image_url'
    static readonly EMBED_OG_IMAGE_URL = 'og_image_url'
    static readonly EMBED_BANNERS = 'banners'

    get id() {
        return this._getAttribute(BrandModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(BrandModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(BrandModel.ATTR_DESCRIPTION)
    }

    get imageId() {
        return this._getAttribute(BrandModel.ATTR_IMAGE_ID)
    }

    get thumbnailImageId() {
        return this._getAttribute(BrandModel.ATTR_THUMBNAIL_IMAGE_ID)
    }

    get metaTitle() {
        return this._getAttribute(BrandModel.ATTR_META_TITLE)
    }

    get metaDescription() {
        return this._getAttribute(BrandModel.ATTR_META_DESCRIPTION)
    }

    get ogTitle() {
        return this._getAttribute(BrandModel.ATTR_OG_TITLE)
    }

    get ogDescription() {
        return this._getAttribute(BrandModel.ATTR_OG_DESCRIPTION)
    }

    get ogImageId() {
        return this._getAttribute(BrandModel.ATTR_OG_IMAGE_ID)
    }

    get metaIndex() {
        return this._getAttribute(BrandModel.ATTR_META_INDEX)
    }

    get metaFollow() {
        return this._getAttribute(BrandModel.ATTR_META_FOLLOW)
    }

    get showOnSitemap() {
        return this._getAttribute(BrandModel.ATTR_SHOW_ON_SITEMAP)
    }

    get hasAutomaticSlug() {
        return this._getAttribute(BrandModel.ATTR_HAS_AUTOMATIC_SLUG)
    }

    get slug() {
        return this._getAttribute(BrandModel.ATTR_SLUG)
    }

    get isActive() {
        return this._getAttribute(BrandModel.ATTR_IS_ACTIVE)
    }

    get parentId() {
        return this._getAttribute(BrandModel.ATTR_PARENT_ID)
    }

    get urls() {
        return this._getEmbed(BrandModel.EMBED_URLS)
    }

    /**
     * Logo
     */
    get imageUrl() {
        return this._getEmbed(BrandModel.EMBED_IMAGE_URL)
    }

    /**
     * Image
     */
    get thumbnailImageUrl() {
        return this._getEmbed(BrandModel.EMBED_THUMBNAIL_IMAGE_URL)
    }

    get ogImageUrl() {
        return this._getEmbed(BrandModel.EMBED_OG_IMAGE_URL)
    }

    get banners() {
        return this._getEmbed(BrandModel.EMBED_BANNERS, BannerModel)
    }

    getUrl() {
        return this.urls
    }
}
