import {
    CartOrderPaymentStatus
} from '../../shared/constants'

export function useCart() {
    const store = useCartStore()
    return {
        ...store,
        ...storeToRefs(store),
    }
}

interface UseCartPaymentSummaryReturn {
    order: ComputedRef<InstanceType<typeof OrderModel> | null>
    paymentStatus: ComputedRef<CartOrderPaymentStatus>
}

/**
 * A composable to handle the cart payment summary.
 */
export function useCartPaymentSummary(): UseCartPaymentSummaryReturn {
    const route = useRoute()
    const cartStore = useCartStore()

    const order = computed(() =>
        // If the latest order is a dummy one (unknown status), do not return an order
        cartStore.latestOrder?.id === -1
            ? null
            : cartStore.latestOrder ?? null
    )

    const paymentStatus = computed<CartOrderPaymentStatus>(() => {
        if (cartStore.latestOrder?.id === -1) return CartOrderPaymentStatus.UNKNOWN
        if (order.value?.isPaid() === false && !order.value?.isToBePaidLater()) return CartOrderPaymentStatus.ERROR

        return CartOrderPaymentStatus.SUCCESS
    })

    // throw an error if the order is missing and it isn't a dummy order
    if (order.value === null && cartStore.latestOrder?.id !== -1) {
        console.error(`[useCartPaymentSummary]: Order is \`null\` for route \`${route.fullPath}\`. This should never happen.`)
        throw createError({
            statusCode: 404,
            fatal: true,
        })
    }

    return {
        order: order as UseCartPaymentSummaryReturn['order'],
        paymentStatus: paymentStatus,
    }
}
