export enum AddressType {
    BILLING = 2,
    SHIPPING = 1,
}

export const CountryCode = {
    CZ: 'CZE',
    SK: 'SVK',
} as const
export type CountryCode = typeof CountryCode[keyof typeof CountryCode]
