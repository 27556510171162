import { ApiModel } from '@composable-api/api.model'
import { CustomerAccountUsageOperationTypeEnum } from '../enums/customer'
import { CustomerAccountModel } from '@simploshop-models/customer-account.model'
import { LoyaltyProgramModel } from '@simploshop-models/loyalty-program.model'
import { OrderModel } from '@simploshop-models/order.model'
import { OrderItemModel } from '@simploshop-models/order-item.model'

interface Attributes {
    [CustomerAccountUsageModel.ATTR_ID]: number
    [CustomerAccountUsageModel.ATTR_LOYALTY_PROGRAM_ID]: number | null
    [CustomerAccountUsageModel.ATTR_CUSTOMER_ACCOUNT_ID]: number
    [CustomerAccountUsageModel.ATTR_CUSTOMER_ID]: number
    [CustomerAccountUsageModel.ATTR_AMOUNT]: number
    [CustomerAccountUsageModel.ATTR_OPERATION]: CustomerAccountUsageOperationTypeEnum
    [CustomerAccountUsageModel.ATTR_NAME]: string | null
    [CustomerAccountUsageModel.ATTR_ORDER_ID]: number
    [CustomerAccountUsageModel.ATTR_ORDER_CREDIT_ID]: number
    [CustomerAccountUsageModel.ATTR_NEWSLETTER_ID]: number
    [CustomerAccountUsageModel.ATTR_PAYLOAD]: any[]
    [CustomerAccountUsageModel.ATTR_ORDER_ITEM_ID]: number
}

interface Embeds {
    [CustomerAccountUsageModel.EMBED_CUSTOMER_ACCOUNT]: CustomerAccountModel
    [CustomerAccountUsageModel.EMBED_LOYALTY_PROGRAM]: LoyaltyProgramModel
    [CustomerAccountUsageModel.EMBED_ORDER]: OrderModel
    [CustomerAccountUsageModel.EMBED_ORDER_ITEM]: OrderItemModel
}

export class CustomerAccountUsageModel extends ApiModel<Attributes, Embeds> {
    static key = 'CustomerAccountUsageModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_LOYALTY_PROGRAM_ID = 'loyalty_program_id'
    static readonly ATTR_CUSTOMER_ACCOUNT_ID = 'customer_account_id'
    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_OPERATION = 'operation'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_ORDER_ID = 'order_id'
    static readonly ATTR_ORDER_CREDIT_ID = 'order_credit_id'
    static readonly ATTR_NEWSLETTER_ID = 'newsletter_id'
    static readonly ATTR_PAYLOAD = 'payload'
    static readonly ATTR_ORDER_ITEM_ID = 'order_item_id'

    static readonly EMBED_CUSTOMER_ACCOUNT = 'customer_account'
    static readonly EMBED_LOYALTY_PROGRAM = 'loyalty_program'
    static readonly EMBED_ORDER = 'order'
    static readonly EMBED_ORDER_ITEM = 'order_item'

    get id() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_ID)
    }

    get loyaltyProgramId() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_LOYALTY_PROGRAM_ID)
    }

    get customerAccountId() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_CUSTOMER_ACCOUNT_ID)
    }

    get customerId() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_CUSTOMER_ID)
    }

    get amount() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_AMOUNT)
    }

    get operation() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_OPERATION)
    }

    get name() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_NAME)
    }

    get orderId() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_ORDER_ID)
    }

    get orderCreditId() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_ORDER_CREDIT_ID)
    }

    get newsletterId() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_NEWSLETTER_ID)
    }

    get payload() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_PAYLOAD)
    }

    get orderItemId() {
        return this._getAttribute(CustomerAccountUsageModel.ATTR_ORDER_ITEM_ID)
    }

    get customerAccount() {
        return this._getEmbed(CustomerAccountUsageModel.EMBED_CUSTOMER_ACCOUNT, CustomerAccountModel)
    }

    get loyaltyProgram() {
        return this._getEmbed(CustomerAccountUsageModel.EMBED_LOYALTY_PROGRAM, LoyaltyProgramModel)
    }

    get order() {
        return this._getEmbed(CustomerAccountUsageModel.EMBED_ORDER, OrderModel)
    }

    get orderItem() {
        return this._getEmbed(CustomerAccountUsageModel.EMBED_ORDER_ITEM, OrderItemModel)
    }
}
