<template>
    <NuxtLoadingIndicator color="#E4012C" />
    <NuxtRouteAnnouncer />
    <NuxtLayout name="c-root">
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>

        <!--  Notifications  -->
        <CoreNotifications
            :text-color="{
                success: '#00a64f',
            }"
        />
    </NuxtLayout>

    <CoreModalManager />
</template>

<script lang="ts" setup>

usePageAttrsManager()

</script>
