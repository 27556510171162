import { ApiModel } from '@composable-api/api.model'
import { ProductModel } from './product.model'
import { ProductVariationModel } from './product-variation.model'
import { GiftTypeEnum } from '../enums/cart'
import type { MonetaryPair } from '../types/general-data'
import { GiftRuleModel } from './gift-rule.model'

interface Attributes {
    [GiftModel.ATTR_ID]: number
    [GiftModel.ATTR_PRODUCT_ID]: number
    [GiftModel.ATTR_PRODUCT_VARIATION_ID]: number | null
    [GiftModel.ATTR_IS_ACTIVE]: boolean
    [GiftModel.ATTR_IS_COMBINABLE]: boolean
    [GiftModel.ATTR_PERCENTS]: number
    [GiftModel.ATTR_TYPE]: GiftTypeEnum
    [GiftModel.ATTR_LIMIT]: number
}

interface Embeds {
    [GiftModel.EMBED_PRODUCT]: ProductModel
    [GiftModel.EMBED_PRODUCT_VARIATION]: ProductVariationModel
    [GiftModel.EMBED_GIFT_PRICES]: MonetaryPair
    [GiftModel.EMBED_RULES]: GiftRuleModel
}

export class GiftModel extends ApiModel<Attributes, Embeds> {
    static key = 'GiftModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PRODUCT_VARIATION_ID = 'product_variation_id'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_IS_COMBINABLE = 'is_combinable'
    static readonly ATTR_PERCENTS = 'percents'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_LIMIT = 'limit'

    static readonly EMBED_PRODUCT = 'product'
    static readonly EMBED_PRODUCT_VARIATION = 'product_variation'
    static readonly EMBED_GIFT_PRICES = 'gift_prices'
    static readonly EMBED_RULES = 'rules'

    get id() {
        return this._getAttribute(GiftModel.ATTR_ID)
    }

    get productId() {
        return this._getAttribute(GiftModel.ATTR_PRODUCT_ID)
    }

    get productVariationId() {
        return this._getAttribute(GiftModel.ATTR_PRODUCT_VARIATION_ID)
    }

    get isActive() {
        return this._getAttribute(GiftModel.ATTR_IS_ACTIVE)
    }

    get isCombinable() {
        return this._getAttribute(GiftModel.ATTR_IS_COMBINABLE)
    }

    get percents() {
        return this._getAttribute(GiftModel.ATTR_PERCENTS)
    }

    get type() {
        return this._getAttribute(GiftModel.ATTR_TYPE)
    }

    get limit() {
        return this._getAttribute(GiftModel.ATTR_LIMIT)
    }

    get product() {
        return this._getEmbed(GiftModel.EMBED_PRODUCT, ProductModel)
    }

    get productVariation() {
        return this._getEmbed(GiftModel.EMBED_PRODUCT_VARIATION, ProductVariationModel)
    }

    get giftPrices() {
        return this._getEmbed(GiftModel.EMBED_GIFT_PRICES)
    }

    get rules() {
        return this._getEmbed(GiftModel.EMBED_RULES, GiftRuleModel)
    }
}
