import { ApiModel } from '@composable-api/api.model'
import { FormattedCurrencyModel } from './custom/formatted-currency.model'
import type { FormattedCurrency, Monetary } from '../types/general-data'
import { OrderModel } from './order.model'
import { PaymentMethodModel } from './payment-method.model'

interface Attributes {
    [OrderPaymentMethodModel.ATTR_ID]: number
    [OrderPaymentMethodModel.ATTR_NAME]: string | null
    [OrderPaymentMethodModel.ATTR_PRICE]: FormattedCurrency
    [OrderPaymentMethodModel.ATTR_TAXED_PRICE]: FormattedCurrency
    [OrderPaymentMethodModel.ATTR_PAYMENT_METHOD_ID]: number
    [OrderPaymentMethodModel.ATTR_ORDER_ID]: number
    [OrderPaymentMethodModel.ATTR_TAX_RATE]: Monetary
    [OrderPaymentMethodModel.ATTR_ORIGINAL_PRICE]: FormattedCurrency
    [OrderPaymentMethodModel.ATTR_ORIGINAL_TAXED_PRICE]: FormattedCurrency
}

interface Embeds {
    [OrderPaymentMethodModel.EMBED_ORDER]: OrderModel
    [OrderPaymentMethodModel.EMBED_PAYMENT_METHOD]: PaymentMethodModel
}

export class OrderPaymentMethodModel extends ApiModel<Attributes, Embeds> {
    static key = 'OrderPaymentMethodModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_PAYMENT_METHOD_ID = 'payment_method_id'
    static readonly ATTR_ORDER_ID = 'order_id'
    static readonly ATTR_TAX_RATE = 'tax_rate'
    static readonly ATTR_ORIGINAL_PRICE = 'original_price'
    static readonly ATTR_ORIGINAL_TAXED_PRICE = 'original_taxed_price'

    static readonly EMBED_ORDER = 'order'
    static readonly EMBED_PAYMENT_METHOD = 'payment_method'

    get id() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_NAME)
    }

    get price() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_PRICE, FormattedCurrencyModel)
    }

    get taxedPrice() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_TAXED_PRICE, FormattedCurrencyModel)
    }

    get paymentMethodId() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_PAYMENT_METHOD_ID)
    }

    get orderId() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_ORDER_ID)
    }

    get taxRate() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_TAX_RATE)
    }

    get originalPrice() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_ORIGINAL_PRICE, FormattedCurrencyModel)
    }

    get originalTaxedPrice() {
        return this._getAttribute(OrderPaymentMethodModel.ATTR_ORIGINAL_TAXED_PRICE, FormattedCurrencyModel)
    }

    get order() {
        return this._getEmbed(OrderPaymentMethodModel.EMBED_ORDER, OrderModel)
    }

    get paymentMethod() {
        return this._getEmbed(OrderPaymentMethodModel.EMBED_PAYMENT_METHOD, PaymentMethodModel)
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

