import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ThemeAddressPredictionModel.ATTR_ID]: number
    [ThemeAddressPredictionModel.ATTR_DESCRIPTION]: string | null
    [ThemeAddressPredictionModel.ATTR_STREET]: string | null
    [ThemeAddressPredictionModel.ATTR_STREET_CODE]: string | null
    [ThemeAddressPredictionModel.ATTR_STREET_NUMBER]: string | null
    [ThemeAddressPredictionModel.ATTR_BOROUGH]: string | null
    [ThemeAddressPredictionModel.ATTR_BOROUGH_CODE]: string | null
    [ThemeAddressPredictionModel.ATTR_CITY]: string | null
    [ThemeAddressPredictionModel.ATTR_CITY_CODE]: string | null
    [ThemeAddressPredictionModel.ATTR_REGION_CODE]: string | null
    [ThemeAddressPredictionModel.ATTR_POST_CODE]: string | null
}

export class ThemeAddressPredictionModel<TAttributes = unknown> extends ApiModel<Attributes & TAttributes> {
    static key = 'ThemeAddressPredictionModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_DESCRIPTION = 'popis'
    static readonly ATTR_STREET = 'ulice'
    static readonly ATTR_STREET_CODE = 'ulice_kod'
    static readonly ATTR_STREET_NUMBER = 'cislo_domu'
    static readonly ATTR_BOROUGH = 'cast_obce'
    static readonly ATTR_BOROUGH_CODE = 'cast_obce_kod'
    static readonly ATTR_CITY = 'obec'
    static readonly ATTR_CITY_CODE = 'obec_kod'
    static readonly ATTR_REGION_CODE = 'region_kod'
    static readonly ATTR_POST_CODE = 'psc'

    get id() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_ID)
    }

    get description() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_DESCRIPTION)
    }

    get street() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_STREET)
    }

    get streetCode() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_STREET_CODE)
    }

    get streetNumber() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_STREET_NUMBER)
    }

    get borough() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_BOROUGH)
    }

    get boroughCode() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_BOROUGH_CODE)
    }

    get city() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_CITY)
    }

    get cityCode() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_CITY_CODE)
    }

    get regionCode() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_REGION_CODE)
    }

    get postCode() {
        return this._getAttribute(ThemeAddressPredictionModel.ATTR_POST_CODE)
    }
}
