export default defineNuxtPlugin({
    name: 'model-customization',
    parallel: true,
    setup: () => {
        const runtimeConfig = useRuntimeConfig()


        const customerCreditsAccountId = Number(runtimeConfig.public.id.customerCreditsAccount)
        // CartModel
        CartModel.prototype.getRemainingCustomerCredits = function () {
            return this.customerAccountAmountsOfItems?.find(acc => acc.customer_account_id === customerCreditsAccountId)
                ?.amounts_remain ?? 0
        }

        CartModel.prototype.getCustomerCreditsToBeGained = function () {
            return this.customerAccountAmountsOfOrder?.find(acc => acc.customer_account_id === customerCreditsAccountId)
                ?.amounts ?? 0
        }

        CartModel.prototype.getRemainingCustomerCreditsValuation = function () {
            return this.customerAccountAmountsOfItems?.find(acc => acc.customer_account_id === customerCreditsAccountId)
                ?.converted_amounts_remain ?? null
        }

        CartModel.prototype.getCustomerCreditsToBeGainedValuation = function () {
            return this.customerAccountAmountsOfOrder?.find(acc => acc.customer_account_id === customerCreditsAccountId)
                ?.converted_amounts ?? null
        }


        // CustomerModel
        CustomerModel.prototype.getTotalCredits = function () {
            return this.customerAccountAmounts?.find(acc => acc.customerAccountId === customerCreditsAccountId)
                ?.amount ?? 0
        }

        CustomerModel.prototype.getTotalCreditsValuation = function () {
            return this.customerAccountAmounts?.find(acc => acc.customerAccountId === customerCreditsAccountId)
                ?.conversionValueWholeAccount ?? null
        }
    },
})
